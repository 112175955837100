import { BooksBlacksunPart1 } from "../components/BooksBlacksunPart1"
import { BooksBlacksunPart2 } from "../components/BooksBlacksunPart2"
import { CrumbsLev2 } from "../components/Bread_Crumbs";

export function Books() {
  document.title = "All books from Cydonian Sun series"
  return (
    <>
      <div className="row" key="crumbs">
        <CrumbsLev2 name={<span><i className="fa-solid fa-book"></i>  Books</span>} />
      </div>
      <div key="mainRow" className="row">
        <div className="col-lg-12">
          <h1 className="title-bg">All books from Cydonian Sun series</h1>
          <BooksBlacksunPart1 />
          <BooksBlacksunPart2 />
        </div>
      </div>
    </>
  )
}