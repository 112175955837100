import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ternerian_end() {
    return {
        "date": <span>-</span>,
        "type": <span>space area</span>,
        "owned": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_calliara_space"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} /> (<TextInlineLink name={"bs_spec_humans"}
            text={"Homo sapiens"} />)</span>,
        "imgs": [{ "short": "bs_wiki_tamanrasett_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Ternerian End</span> is one of the frontiers of human expansion. It is conveniently located at the end of a major galactic <TextInlineLink name={"bs_tech_hyperrun"} text={"hyperrun"} />, on the edge of the <TextInlineLink name={"bs_state_republic_of_tiara_minor"} text={"Republic"} />. Traditionally <TextInlineLink name={"bs_people_agathonians"} /> and other Calliara Space residents settle here, but natives from other places are also welcome: the main thing is the dream of conquering new frontiers. Many worlds haven't even begun to be terraformed yet, but there were more than a hundred ready to be settled after the <TextInlineLink name={"bs_timeline_galactic_war"} text={"Galactic War"} />.
                </p>
                <p className="card-text text-justify">
                    At the same time here is a rather weak <TextInlineLink name={"bs_tech_grn"} text={"InterNetwork"} /> and a lot of white spots on the maps, so loss of ships and entire fleets is not uncommon. As a fact, all the colonists sign a treaty, one of the points of which recognizes the non-zero probability of sinking without a trace in the abyss of space on the way to their new home and absolves the Agathon government of responsibility for this.
                </p>
            </>
    }
}

