import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Khal_vernon() {

    return {
        "date": <span>1480 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_comp_free_trade_league"} /></span>,
        "habitats": <span>16 billion (12 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_samborians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>3</span>,
        "g": <span>0.98 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span>Vernon</span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "imgs": [{ "short": "bs_wiki_khal_vernon_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Khal-Vernon</span> is the world famous for producing the legendary Samborian (or Vernon) whiskey, which crafty smugglers like <TextInlineLink name={"bs_char_lenisaad_victor"} text={"Lenisaad"} /> sneak across the galaxy, dodging customs. Officially, few are allowed to import it - no one wants to support the <TextInlineLink name={"bs_state_free_worlds"} text={"Free Worlds'"} /> economy, after all. Located deep in the Khal and Vernon system of the Free Worlds, the planet experiences short nights, with most of its 24-hour cycle spent in daylight or some endless sunrise-sunset glow. It's also home to the infamous Vernon cats.
                </p>
            </>
    }
}

