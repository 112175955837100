import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Sector_zero() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"cosmographic unit"} /></span>,
        "owned": <span>questionable</span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_earth"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_earthlings"} /></span>,

        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Zero Sector</span> is a relatively small part of the galaxy, where humanity was born and actively developed in the days of <TextInlineLink name={"bs_timeline_underlight_age"} text={"Underlight age"} />. Nothing particularly remarkable here: there are monuments, museums, and all sorts of art exhibitions with photographs of bygone eras.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">
                        After <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />:
                    </span>
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Sector Zero</span> is plunged into chaos and strife after the uprising and it is not entirely clear now whether it will be completely under the control of the <TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} /> or split into several independent territories.
                </p>
            </>
    }
}

