import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Agathonians() {
    return {
        "homeworld": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "state": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_esora_eve_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_ursula_adams_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_xavier_kardenos_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_callisto_kindrace_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_kato_aemilius_1_jpg_200"} /></li>
        </ul>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"Arum"} />, <TextInlineLink name={"bs_lang_earth"} text={"Earthlin"} /></span>,
        "text":
            <>
                <h3 className="title-bg">Brief</h3>
                <p className="card-text text-justify">
                    <span className="strong">Agathonians</span>, inhabitants of Agathon and the surrounding systems within Calliara Space, embody a complex blend of pragmatism, civic pride, and artistic sophistication. As citizens of the Republic of Tiara Minor, they are champions of democracy, progress, and cultural refinement. Agathonians pride themselves on balancing individual freedom with collective responsibility, making them one of the galaxy’s most respected and influential societies. However, they are not without flaws: their strong belief in their values can come across as pretentious, and their insistence on republican ideals often puts them at odds with more autocratic or monarchic nations.
                </p>
                <h4 className="title-bg">State of Mind</h4>
                <p className="card-text text-justify">
                    Agathonians are ambitious but thoughtful, guided by the belief that progress can only be achieved through compromise, debate, and perseverance. They tend to have a rational outlook on the world, preferring diplomacy over conflict but always preparing for the worst. In their eyes, governance is both an art and a science, requiring transparency, education, and participation from every citizen.
                </p>
                <p className="card-text text-justify">
                    At the heart of Agathonian thought lies the idea of constant improvement—not just for the state but for individuals. The Agathonian education system is rigorous, emphasizing civic engagement, science, and philosophy. Citizens are encouraged to stay well-informed about politics and economics, viewing democracy as both a right and a responsibility. This mindset makes them fierce advocates of debate and consensus-building, though their constant need for discussion can lead to political gridlock.
                </p>
                <h4 className="title-bg">Culture</h4>
                <p className="card-text text-justify">
                    Agathonians place immense value on cultural expression and intellectual pursuits, and this passion is reflected in their traditions. The “Arum Linga”—or the Agathonian language—carries echoes of the ancient Romance tongues of Earth, bringing warmth and elegance to their art, literature, and oratory. Music, theater, and literature are not only forms of entertainment but also tools of political commentary. The Cassian opera and Cydonian ballet, though originating elsewhere, find some of their most devoted audiences and sponsors among Agathonians, who see these forms of expression as essential to public discourse.
                </p>
                <p className="card-text text-justify">
                    This devotion to culture extends into everyday life, where art and design shape the aesthetics of Agathon’s cities. Architecture on Agathon combines minimalism with grandeur, often built to reflect both ancient Terran influences and modern efficiency. Public spaces are filled with sculptures, gardens, and murals, celebrating historical figures, philosophers, and artists who have contributed to the republic’s identity.
                </p>
                <p className="card-text text-justify">
                    This sense of responsibility also creates a certain weariness in Agathonian society, as the weight of maintaining their ideals is felt acutely by every citizen. The constant push for progress and self-improvement can lead to burnout and social tension. However, this is counterbalanced by a strong sense of community and mutual support—Agathonians know how to celebrate their achievements with grandeur, throwing festivals, concerts, and galas that bring together people from all walks of life.
                </p>
                <h4 className="title-bg">Politics</h4>
                <p className="card-text text-justify">
                    The Republic of Tiara Minor is seen as the main beacon of republican values in Calliara Space, and Agathonian influence extends far beyond their borders. The republic is governed by an elected Chancellor and a highly structured parliament, where representatives debate policies that impact not only Agathon itself but also the vast star systems under its control. The Agathonians believe that democracy should be exported and supported across the galaxy, often involving themselves in the politics of other nations. This interventionist policy can sometimes strain relationships with neighbors, especially monarchies like the Cydonian Priority or the Eilirén Domination.
                </p>
                <p className="card-text text-justify">
                    Despite these tensions, Agathonians maintain cordial relations with the Priority, united by mutual respect for knowledge, culture, and trade. Still, the two civilizations embody different ideals: where Cydonians rely on meritocracy and algorithms, Agathonians believe in the messy, human element of governance—trusting in dialogue, elections, and the ability of people to govern themselves, even when that process is slow or imperfect.
                </p>
                <h4 className="title-bg">Identity</h4>
                <p className="card-text text-justify">
                    Agathonians are not a homogeneous people. Over millennia, waves of settlers from Earth and other colonies brought diverse traditions, creating a vibrant tapestry of subcultures. Regional identities flourish—like the coastal Morandrines, known for their adventurous spirit, or the intellectual Volantians, who dominate the Republic’s academic and legal fields. Agathonians celebrate these distinctions as part of their shared heritage, considering unity in diversity one of the greatest strengths of their republic.
                </p>
                <p className="card-text text-justify">
                    Culturally, Agathonians place great importance on intellectual refinement and artistic expression. Their capital, Agathon, is home to theaters, universities, and museums that rival any in the galaxy. They consider the arts a crucial part of public life, believing that creativity fuels innovation and critical thinking. Arum, their language, is not just a means of communication but a symbol of cultural pride—an elegant bridge between ancient Earth’s Romance tongues and the modern world. Their festivals and traditions blend theatrical flair with deep philosophical undertones, embodying the unique character of a nation that treasures both reason and emotion.
                </p>
                <h4 className="title-bg">Ambition</h4>
                <p className="card-text text-justify">
                    Though Agathonians see themselves as civilized, cultured, and progressive, their vision of the future sometimes clashes with the harsh realities of a chaotic galaxy. Their passion for debate and reform often slows down decision-making, and their tendency to export their ideals abroad has made them both allies and enemies in equal measure. Yet, despite the challenges, Agathonians remain committed to their principles, believing that democracy is worth the struggle and that the arts and sciences are essential to human progress.
                </p>
                <p className="card-text text-justify">
                    At their best, Agathonians are generous, wise, and visionary leaders; at their worst, they are self-righteous, stubborn, and overly reliant on rhetoric. But above all, they believe in the power of human creativity, cooperation, and resilience—an enduring spirit they carry forward as they navigate the uncertain future of Calliara Space.
                </p>
                <p className="card-text text-justify">
                    However, the Agathonians are not just philosophers or artists—they are also one of the most formidable military powers in the galaxy. During the War of the Three Fronts, they nearly overwhelmed the combined forces of Earthlings and Eilirén, and were halted only by the intervention of the Cydonian Priority. Though the war ended without a clear victor, Agathon emerged with its military reputation intact. Even now, its armed forces remain among the strongest in the galaxy, projecting influence through well-coordinated operations and a substantial presence in the Free Worlds. The Agathonians, now allied with the Cydonians against piracy, see their military as not just a shield for their republic but as a stabilizing force for the galaxy.
                </p>
                <p className="card-text text-justify">
                    In addition to their military strength, Agathonians boast the most sophisticated intelligence network in the galaxy. The 1st Diversionary Directorate, renowned for its covert operations and strategic sabotage, operates with unparalleled precision. Their agents excel at destabilizing rival factions, gathering sensitive information, and engineering intricate political maneuvers that few other nations can detect, let alone counter. The Directorate’s influence extends deep into the Free Worlds and beyond, ensuring that the Republic remains two steps ahead of both friends and foes. It is said that if something important happens anywhere in Calliara Space, the Agathonians knew about it long before it began.
                </p>
                <p className="card-text text-justify">
                    Despite their strength, Agathonians value diplomacy and cultural exchange, often using their arts and knowledge as tools of influence. Agathon remains a beacon of intellectual refinement, where the fusion of politics, creativity, and strategy creates a unique society that thrives on balance. Their language, Arum, exemplifies this duality—elegant and poetic, yet sharp and precise. Whether through diplomacy, art, or force, Agathonians strive not just to survive but to shape the future of the galaxy according to their vision of order, freedom, and progress.
                </p>
                <h4 className="title-bg">After <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />:</h4>
                <p className="card-text text-justify">
                    Following the events of Cydonian Girl, the galaxy bore witness to the unrivaled capabilities of the Agathonians. With surgical precision, their agents dismantled the Great Monarchy—an ancient and powerful regime at the heart of the Earth Empire, the oldest country in human history. The monarchy, once a bastion of tradition and control, was transformed into a shadow of its former self, splintered into a mosaic of independent states.
                </p>
                <p className="card-text text-justify">
                    The Agathonians achieved what few believed possible. Using subtle diplomacy, espionage, and military cunning, they overthrew the Great Monarch, not with brute force but by unraveling the Empire from within. Political factions within the Earth Empire were cleverly pitted against one another, rebellions were incited at the right moment, and influential leaders swayed by promises of Agathonian support. The fall of the Great Monarchy sent shockwaves throughout the galaxy, reshaping the political landscape forever. A once-formidable threat to Agathon’s neighbors was now fragmented and humbled, no longer capable of projecting power beyond its fractured borders.
                </p>
                <p className="card-text text-justify">
                    Many of these newly independent states immediately fell under the influence of Agathon, aligning themselves with the Republic of Tiara Minor. Some willingly became client states, while others had little choice but to submit to Agathon’s guidance. The Agathonians were quick to install favorable regimes and weave their influence into the economies and militaries of these states, ensuring that even the remnants of the once-proud monarchy served Agathon’s interests.
                </p>
                <p className="card-text text-justify">
                    This masterstroke solidified the Agathonians as not only the most formidable intelligence power in the galaxy but also a force capable of remaking nations. Their victory over the Great Monarchy stood as a chilling reminder: no regime, no matter how ancient or powerful, can be an Agathon enemy and survive. And though for 20 years they stood as allies with the Cydonian Priority against piracy, the rest of the galaxy could only wonder: Who might Agathon move against next?
                </p>
            </>
    }
}
