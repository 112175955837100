import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function CalliaraSpace() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "date": <span>-</span>,
        "type": <span>galaxy region</span>,
        "capital": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} />, <TextInlineLink
            name={"bs_people_aethurians"} /> and others</span>,
        "bs_universe_currencies": <span><TextInlineLink name={"bs_currency_teros"} text={"Agathonian teros"} /></span>,

        "text":
            <>
                <h3 className="title-bg">Brief</h3>
                <p className="card-text text-justify">
                    <span className="strong">Calliara Space</span> or <span className="strong">CS</span> is an expansive region of human-inhabited systems, spanning star clusters, planets, and strategic sectors. Dominated by the Republic of Tiara Minor, with Agathon as its capital, Calliara is a hub of political power, military strength, and intelligence networks. Agathonians play a leading role, having transformed the region into a highly organized, interconnected republic.
                </p>
                <p className="card-text text-justify">
                    Home to the best-trained military and elite intelligence forces, the Agathonians conduct surgical operations across the Free Worlds and frontier regions, maintaining a fragile balance of power with the Cydonian Priority. After toppling the Great Monarchy on Earth, Calliara Space cemented itself as one of the galaxy’s leading superpowers, ensuring most of the former Earth colonies fell under Agathon’s control. Their agents operate covertly in many corners of the galaxy, monitoring threats and neutralizing piracy.
                </p>
                <p className="card-text text-justify">
                    Calliara Space is as culturally rich as it is politically potent, with multiple allied nations contributing to its art, knowledge, and customs. Some are known for their spiritual practices and philosophical schools, while others are renowned engineers or excel in biotech and medicine. However, Agathonian culture remains dominant, with Arum, the “golden language,” serving as the region’s primary tongue.
                </p>
                <p className="card-text text-justify">
                    Though Calliara Space promotes the values of democracy and progress, the Republic of Tiara Minor is driven by a competitive ethos where talent and ambition are rewarded. This mix of cooperation and quiet dominance makes Calliara Space a force to be reckoned with—and a difficult neighbor for anyone standing in its way.
                </p>
                <p className="card-text text-justify">
                    The region’s lifeblood flows along the Earth–Agathon–Ternerian End Hyper Trail, the primary trade route linking Old Space with Calliara Space. This route not only facilitates economic exchanges but also serves as a corridor for covert operations, military mobilizations, and diplomatic endeavors, making it one of the most contested and strategically valuable paths in the galaxy.
                </p>
            </>
    }
}

