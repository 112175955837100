import { CrumbsBlackSunMaps } from "../../../../components/Bread_Crumbs";
import { TextInlineLink } from "../../../../components/cms/wiki_functions";
import imgs from '../../../../components/cms/images_repo.json';

export function BlackSunWorldMapOecumene() {
    document.title = "Map of the galaxy and individual territories || Cydonian girl & Cydonian sun"
    return (
        <>
            <div className="row" key="crumbs">
                <CrumbsBlackSunMaps name={<span><i className="fa-solid fa-map-location-dot"></i>  Oecumene of Homo sapiens before Tamanrasett</span>} />
            </div>
            <div key="mainRow" className="row">
                <div className="col-lg-12 well">
                    <p className="card-text text-justify">
                        <span className="strong">The Oekumene</span>, or <span className="strong">Human Oekumene</span>, is the part of the universe that earthlings or their descendants (read: humans) have assimilated for themselves. The term was once coined by the ancient Greeks to describe a part of the world they knew, and it surprisingly fits the same purpose within the galaxy.
                    </p>
                    <p className="card-text text-justify">
                        The most important states of <TextInlineLink name={"bs_timeline"} text={"this period of history"} />:
                        <ul>
                            <li><TextInlineLink name={"bs_state_ajliraenian_domination"} /></li>
                            <li><TextInlineLink name={"bs_state_cydonian_priority"} /></li>
                            <li><TextInlineLink name={"bs_state_free_worlds"} /></li>
                            <li><TextInlineLink name={"bs_state_the_great_monarchy"} text={<s>Great Monarchy</s>} /> and its descendants formed after the <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Shark Revolt"} /></li>
                            <li><TextInlineLink name={"bs_state_tallesian_principality"} /></li>
                            <li><TextInlineLink name={"bs_state_aethurian_republic"} /></li>
                            <li><TextInlineLink name={"bs_space_breeze_sector"} text={"Republican Protectorate of the Breeze"} /></li>
                            <li>Empire of Elat</li>
                        </ul>

                    </p>
                </div>
            </div>
            <div className="row">
                <div style={{ "width": "100%", "backgroundImage": "url(" + imgs["bs_wiki_oecumene_map_en"].link + ")" }}></div>
                <a data-caption="Ойкумена Homo sapiens до Таманрасетт" data-fancybox="true" href={imgs["bs_wiki_oecumene_map_en"].link}>
                    <img className="img-thumbnail-v2" alt="" src={imgs["bs_wiki_oecumene_map_en"].link} />
                </a>
            </div>
        </>
    )
}