import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function Nomad_syndrome() {
    return {
        "top": <>
            <div className="row">
                <div className="col-lg-6">
                    <ul>
                        <li><i className="fas fa-chevron-right"></i> <strong>Class: </strong> nervous system disease
                        </li>
                    </ul>
                </div>
                <div className="col-lg-6">
                    <ul>
                        <li><i className="fas fa-chevron-right"></i> <strong>Risk group:</strong>
                            <span>people whose nervous system developed in a confined environment</span></li>
                    </ul>
                </div>
            </div>
        </>,
        "text":
            <>
                <h4 className="title-bg">About the disease</h4>
                <p className="card-text text-justify">
                    <span className="strong">Nomad Syndrome</span> is a developmental abnormality caused by lack of
                    access to large open spaces (in most
                    cases due to living on a ship) and crowds of people during the development of the human nervous
                    system. It is most often diagnosed among <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} />, although it also occurs among <TextPopup name={"bs_popups_plantewalkers"} text={"planetwalkers"} /> who, due to circumstances, spent their childhood in narrow spaces.
                </p>
                <p className="card-text text-justify">
                    There are three ways to <span className="strong">prevent development</span> of the disease: you can
                    regularly visit the surface
                    of planets (in particular cities with large populations) with your child, or transport them to a
                    ship with large recreational and observation decks, preferably with a minimum of overlaps and pieces
                    of furniture. You can also take them out into open space on a regular basis, but make sure there is
                    artificial gravity. The brain has to get used to the fact that open spaces are normal.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Treatment</span> of the syndrome is strictly with the help of
                    psychotherapy. Medication healing is possible, but there is a risk of relapse or addiction/other
                    phobias. Regular visits to planetary surfaces with a gradual increase in time spent in open and
                    crowded spaces show the greatest effectiveness. Therapy usually takes up to several years with
                    occasional mild relapses.
                </p>
                <p className="card-text text-justify">
                    There is also extreme treatment, where the patient is brought to crowded squares or large university
                    classrooms, where they need to last as long as possible. This method has been shown to be very
                    effective, but is fraught with psychological trauma, depression and nightmares. The side effects can
                    last for years and cause severe distress.
                </p>
                <h4 className="title-bg">Excerpts from the report of <TextInlineLink name={"bs_char_ursula_adams"} text={"Ursula Adams'"} /> physical examination by the robot doctor on duty, 47 <TextInlineLink name={"bs_timeline"} text={"BT"} />
                </h4>
                <p className="card-text text-justify">
                    Symptoms: agoraphobia, breathing and coordination problems while outdoors and in university classrooms, loss of concentration in presence of large numbers of people. Depression, nightmares, obesessions and compulsions (also diagnosed with OCD).
                </p>
                <p className="card-text text-justify">
                    Patient was reluctant to go to the hospital and tried by willpower and self-belief to relieve symptoms. She was 36% successful. Brain activity analysis shows the effectiveness of self-treatment. However there are signs of nervous breakdown: further self-treatment is likely to lead to psychological problems.
                </p>
                <p className="card-text text-justify">
                    A recommendation has been issued to take class D neurostimulants and parasitic nerve activity blockers. However, the patient does not want to spend money on magic pills. In view of the lack of possibility of other types of treatment, the patient was given a warning of the potential problems and an offer to voluntarily withdraw from the university until the symptoms became less severe. Refusal was received (rudely).
                </p>
            </>
    }
}











