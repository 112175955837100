import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Bacarte() {
    return {
        "date": <span>638 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "type": <span>yellow dwarf</span>,
        "stars": <span>1</span>,
        "planets": <span>16</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_morihei"} text={"Moriseo"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "population": <span>-</span>,
        "imgs": [{ "short": "bs_wiki_bacarte_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <div className="alert alert-danger">
                    <p>Will be there later</p>
                </div>
            </>
    }
}
