import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ajliraenian_domination() {
    return {
        "capital": <span><TextInlineLink name={"bs_planet_ajliraen"} /></span>,
        "form": <span>constitutional monarchy</span>,
        "currency": <span><TextInlineLink name={"bs_currency_dalmatia"}
            text={"dalmatia (Eilirén denarius)"} /></span>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"Arum"} />, <TextInlineLink name={"bs_lang_telpimary"}
            text={"Telpimari"} /></span>,
        "leader": <span>Emperor <TextInlineLink name={"bs_char_javet_bejsenaer"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        // "sectors": <span>26</span>,
        // "worlds": <span>3 695 (278 metropolises)</span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} />, <TextInlineLink
            name={"bs_people_cydonians"} /></span>,
        "imgs": [{ "short": "bs_wiki_ajliraenian_domination_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Eilirén Domination</span> – absolute monarchy with a touch of sub-democracy in <TextInlineLink name={"bs_space_brienne_cluster"} text={"Brianne"} />.
                    Domination has some sort of class
                    system that is legislated: people are divided into full citizens (Eilireé) and
                    Quirites (quirites). The first have all the rights that citizens of other developed countries have,
                    while the second have
                    only
                    some of them. For example, quirites cannot hold positions of power or run a business. To become
                    citizens,
                    they must either work fifty years in some unattractive industry (a normal
                    jobs
                    they are seldom hired) or to serve twenty-five years in the army. There are no other legal avenues,
                    except
                    getting
                    citizenship personally from the Emperor for specific achievements.
                </p>
                <p className="card-text text-justify">
                    Borders on <TextInlineLink name={"bs_state_cydonian_priority"}
                        text={"Cydonian Priority"} /> along the
                    Perseus arm, with <TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /> by
                    Perseus and Sagittarius arms, and with <TextInlineLink name={"bs_state_the_great_monarchy"}
                        text={"Monarchy"} /> on the
                    Orion arm.
                </p>
                <h3 id={"currency"} className="title-bg">Currency</h3>
                <p className="card-text text-justify">
                    <span
                        className="strong">Dalmatia (Eilirén denarius)</span> –
                    currency of <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Domination"} />, a very
                    volatile and
                    unreliable: periodically collapsing due to regular repression and the rise of militarism in the
                    country.
                    Even a strong industry and a strong economy do not make this country as attractive to
                    investors,
                    as <TextInlineLink name={"bs_state_cydonian_priority"} text={"Priority"} /> or <TextInlineLink
                        name={"bs_state_republic_of_tiara_minor"} text={"Tiara Minor republic"} />.
                </p>
            </>
    }
}

