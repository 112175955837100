import { TextInlineLinkCustomClass } from './cms/wiki_functions';

export function WikiFavourites() {
    return (
        <>
            <h5 className='footer-h5'>FAVOURITES</h5>
            <ul className="post-list">
                <li><TextInlineLinkCustomClass class={"ordinary"} name={"book_cydonian_autumn"}
                    text={"Cydonian autumn"} popup={""} /></li>
                <li><TextInlineLinkCustomClass class={"ordinary"} name={"bs_timeline"}
                    text={"Cydonian sun world history"} popup={""} /></li>
                <li><TextInlineLinkCustomClass class={"ordinary"} name={"bs_planet_cydonia"} text={"Cydonia"} popup={""} /></li>
                <li><TextInlineLinkCustomClass class={"ordinary"} name={"bs_tech_grn"}
                    text={"Galactic relay network"} popup={""} /></li>
                <li><TextInlineLinkCustomClass class={"ordinary"} name={"bs_creature_hydra"} text={"Steel Hydra"} popup={""} />
                </li>
            </ul>
        </>
    )

}