import { Appearance } from "../../../../../components/appearance";
import { TextPopup, TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Hartrey_kellays() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_hyperbeacons"} />,
        "name": "Kellays Hartrey",
        "fullname": <span>Kellays Hartrey</span>,
        "born": <span>68 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>67</span>,
        "homeworld": <span><TextPopup name={"bs_popups_planet_noabia"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"Cydonian"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "parents": <span>deceased</span>,
        "relatives": <span>doesn't communicate</span>,
        "work": <span><TextInlineLink name={"bs_comp_esquirete"} /> of <TextInlineLink
            name={"bs_state_cydonian_priority"} /></span>,
        "imgs": [
            { "short": "bs_char_hartrey_kellays_1_png_500", "big": "bs_char_hartrey_kellays_1_jpg_full" },
            { "short": "bs_char_hartrey_kellays_2_jpg_200", "big": "bs_char_hartrey_kellays_2_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Youth and work at the COC</h3>
                <p className="card-text text-justify">
                    <span className="strong">Kellays</span> is a native of Noebiah, a small agrarian world in the <TextInlineLink name={"bs_space_fiorella_sector"} text={"Fiorella sector"} />. Kellays' parents were hydroponic engineers, and the Hartrey family owned a decent-sized business in their homeworld. From a very young age he learned to take machinery apart: robots, wheeled cars, and all sorts of farm equipment. And despite his parents' attempts to instill in the boy a love of farming, after graduation he left the planet and went to <TextInlineLink name={"bs_planet_cassini"} text={"Cassini"} />, to study the workings of the <TextInlineLink name={"bs_tech_grn"} text={"InterNetwork"} />.
                </p>
                <p className="card-text text-justify">
                    His specialty was <span className='code'>Information Security of InterNetwork Communications</span> a highly respected profession in Priority. Nevertheless, it was not so easy to find a dream job after university. And Kellays dreamt of the COC: all IT enthusiasts in <TextInlineLink name={"bs_state_cydonian_priority"} text={"Priority"} /> and <TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /> want to get there, so the huge competition left little chance for a guy with no experience.

                </p>
                <p className="card-text text-justify">
                    But Kellays wasn't discouraged and went to work for one of the COC contracting firms in the <TextInlineLink name={"bs_space_grace_sector"} text={"Grazia sector"} />. There he had to operate a welding machine and watch over the cable-laying robots, not monitor the security of the IT infrastructure as he had dreamed.
                </p>
                <p className="card-text text-justify">
                    It took a few years of hard work to move into the ranks of full-fledged engineers and join the COC staff. Over the years, Kellays found a true friend, Lumar, with whom he worked shoulder-to-shoulder for more than three decades in the future. They worked their way up from welders to engineers and then InterNetwork segment administrators, a highly responsible and very well-paid position.
                </p>
                <h3 className="title-bg">Service in <TextInlineLink name={"bs_comp_esquirete"} text={"Esquirete"} /> (<TextInlineLink name={"book_hyperbeacons"} />)</h3>
                <p className="card-text text-justify">
                    About a year <TextInlineLink name={"bs_timeline"} text={"before Tamanrasett"} />, Kellays and Lumar witnessed the disappearance of the inhabitants of an entire planet, <TextInlineLink name={"bs_planet_exile_1"} text={"Exodus-1"} />, in the <TextInlineLink name={"bs_star_last_exodus"} text={"Last Exodus"} /> system. Hartrey became furious that everyone ignored the insanity of the situation, confining themselves to the dry reports of the military. Who could have kidnapped so many people at once?!
                </p>
                <p className="card-text text-justify">
                    Eager to find the answer, Kellays went to <TextInlineLink name={"bs_planet_cydonia"} text={"Cydonia"} />, straight to Her Majesty's Esquirete. There <TextInlineLink name={"bs_char_colbert_simone"} text={"Simone Colbert"} /> was already waiting for him, praised Hartree's skills and offered him a job with the <TextInlineLink name={"bs_state_cydonian_priority"} text={"Priority"} /> government. He was to solve a task so far beyond human capabilities - to figure out how to disarm and steal a hyperlink beacon that was better protected than the royal palace.
                </p>
                <p className="card-text text-justify">
                    While working on this task, Kellays witnessed the first contact between humans and <TextInlineLink name={"bs_spec_vitruvianus"} text={"Vitruvians"} />, made by <TextInlineLink name={"bs_char_skarsgard_oliver"} text={"Oliver Skarsgård"} /> on <TextInlineLink name={"bs_planet_tajbennae"} text={"Taibennae"} />.
                </p>
            </>
    }
}
