import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function Serpentara_emplumara() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Type: </strong>
                        <span>criminal organization</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Head: </strong>
                        <span>baron <TextInlineLink name={"bs_char_hector_salinger"} /></span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Numbers: </strong> <span>unknown</span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Activities:</strong>
                        <span>piracy, assassinations, private security</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Headquarters:</strong>
                        <span><TextInlineLink name={"bs_planet_samborae"} /></span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <img style={{ "maxWidth": "300px", "float": "left" }} alt="Feathered Serpent Emblem"
                    src={imgs["bs_wiki_serpentara_emplumara"].link}
                    fetchpriority="low" />
                <p className="card-text text-justify">
                    <span className="strong">Feathered Serpent</span> (Samborian: Serpentara emplumara) is a syndicate involved in the slave trade, piracy, drug trafficking, and arms smuggling. A member of the Free Trade League, considered its most radical wing and main strike force.
                </p>
                <p className="card-text text-justify">
                    The exact date of its founding is unknown. The history of the Serpent begins with a small gang of racketeers who were operating in the outlying territories of the Domination. For a time it operated unnoticed by the <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Eilirén government"} />, standing out in no way from its peers. The first official mention of the Serpent can be found in the police records of Daydaris, some 130 years before the <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />: the <TextInlineLink name={"bs_people_ajliree"} text={"Eilireé"} /> caught several very opinionated young men with Ouroboros tattoos on their chests.
                </p>
                <p className="card-text text-justify">
                    They were all executed a few months later and the trail is lost, until about 40 years ago when Hector Selinger showed up on <TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} />, in the Free Worlds. The ambitious young pirate has created a veritable street war in the planet's capital, and in fierce battles forced the Free Trade League not only to accept his gang, but also to provide it with generous funding.
                </p>
                <p className="card-text text-justify">
                    About 20 years ago Snake suffered heavy losses (including reputational) in battles with the Cydonian Navy over the <TextInlineLink name={"bs_space_samborian_traverse"} text={"Samborian Traverse"} />: the Cydonians took the entire traverse territory from pirates and wiped out more than half of Syndicate's fighters. Nevertheless, he quickly recovered and has been hatching plans for revenge ever since, increasing pressure on the Priority's border territories.
                </p>
                <div className="bg-image" style={{
                    "padding": "1em",
                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                }}>
                </div>
                <p className="card-text text-justify">
                    Below is a portrait of Baron Salinger himself.
                </p>
                <img style={{ "maxWidth": "300px", "float": "left", "margin": "0.3em 0.3em 0 0" }} alt="Baron Hector Salinger" src={imgs["bs_char_hector_salinger_1_jpg_500"].link} fetchpriority="low" />
                <p className="card-text text-justify">
                    As a self-respecting criminal organization, all of its members wear a tattoo: an image of Ouroboros (a snake eating its tail). Some take the name literally and try to add wings (or, save me Hydra, feathers all over the body), but such clowns do not stay long in the Syndicate.
                </p>
                <p className="card-text text-justify">
                    The Syndicate's core credo can be summed up as Together Forever: its fighters consider themselves a true family, even undergoing a sort of brotherhood rite, the essence of which is kept secret. Many fall into it in early childhood and grow up in an atmosphere of not only unconditional obedience to older comrades, but also the complete trust in the other soldiers of the Snake. From their earliest days in the organization its members are divided into squads, within which cooperation, unselfish assistance, and mutual help are cultivated.
                </p>
                <p className="card-text text-justify">
                    Salinger achieved more than just the loyalty of his subordinates - he actually became a father figure to them. His semi-religious loyalty allowed him not only to survive a heavy defeat at the hands of the Cydonian army, but also to learn from his mistakes. In a situation where any other criminal leader loses control and his gang scatters across the galaxy, Serpent was able to regain its strength and numbers in a matter of years. It also did not lose positions in the Free Trade League - no one dared to challenge the crazy syndicate.
                </p>
            </>
    }
}
