import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ship_classes() {
    return {
        "is_an_article": true,
        "text":
            <>
                <h3 className="title-bg">Civilian</h3>
                <p className="card-text text-justify">
                    An <span className="strong">atmospheric boat</span> is an atmospheric propulsion vehicle not capable
                    of going into space or not designed to stay outside the magnetosphere for long periods of time. As a
                    rule it consists of fragile materials, which are easily damaged by interstellar radiation. This
                    includes many types of ships, from robot cabs to flying scooters.
                </p>
                <p className="card-text text-justify">
                    A <span className="strong">bulk carrier</span> or <span className="strong">bulker</span> is an
                    extremely capacious cargo ship designed primarily to carry natural resources. In most cases, a bulk
                    carrier has no hangars or holds: it is simply a huge cargo platform on which ore or timber is
                    unloaded and then roofed over with some sort of cover. The ease of loading is offset by problems
                    during unloading: a specialized terminal is required for each type of goods. Besides, a bulk carrier
                    has to maintain special storage conditions for some cargoes, which in case of a usual truck is taken
                    care of by an autonomous container placed in the hold.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Civilian gunboat</span> - a vehicle with guns on board, owned by a
                    civilian. A gunboat can be either a liner or a yacht - it does not matter, as such vehicles,
                    regardless of size and route, are specially certified and registered.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Engineering vessel</span> - a ship with equipment for technical work in
                    space or in atmosphere. It can be an advanced scanner to search for missing people or devices to
                    repair other machines. An engineering vessel is subject to special treatment and the rules for
                    flying it through certain areas or even staying in orbit may differ in different parts of the
                    galaxy. The reason is that advanced equipment can serve both law-abiding purposes and assist
                    criminals or spies. So if your ship has an untypical radar or special mineral scanner, you'd better
                    study the laws of the country you're flying to.
                </p>
                <p
                    className="card-text text-justify">
                    Examples: <TextInlineLink name={"bs_ships_redeyed"} />, <TextInlineLink name={"bs_ships_plato"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Freighter</span> or <span className="strong">Freightliner</span> - A classic cargo ship carrying goods in a hold or hangar. The name comes from the word freight - cargo. It differs from bulk carrier not only in the type of loading, but also in the cost of transportation: a freighter has standard container mounts and docking modules, which will allow easy unloading of goods at any port, without the need to build special terminals for specific cargoes.
                </p>
                <p className="card-text text-justify">
                    A <span className="strong">hiveship</span> is a vessel to keep a large number of people alive
                    (hundreds of thousands or even millions). Read more in a <TextInlineLink name={"bs_tech_hiveship"} text={"separate article"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Liner</span> - a ship for carrying passengers. Any ship that flies between
                    planets and has a predetermined route is considered a liner (regardless of the number of seats).
                    However, such a route is always coordinated with local authorities to avoid potentially dangerous
                    areas or pirate raids.
                </p>
                <p className="card-text text-justify">
                    A <span className="strong">yacht</span> is a private passenger ship. It differs from a liner in the flight format: a yacht does not have a predetermined route and flies at its own risk. The owner is not bound to coordinate his route with the authorities and is therefore fully responsible for possible damage from pirates or dangerous space objects. If the yacht carries a weapon on board, it is reclassified as a civilian gunboat, without losing the right to travel freely.
                </p>
                <h3 className="title-bg">Military</h3>
                <p className="card-text text-justify">
                    A <span className="strong">battleship</span> is a clumsy, heavily armored and heavily armed war machine. It is comparable or superior to a cruiser in terms of weaponry, but has less maneuverability and speed.
                </p>
                <p className="card-text text-justify">
                    A <span className="strong">brander</span> is a suicide ship. Most often an old or damaged ship that is loaded with explosives and the reactor is put into overload mode. Such a machine can be sent into the middle of enemy ships or pushed against the planet, but in the first case you need good armor or very maneuverable engines.
                </p>
                <p className="card-text text-justify">
                    A <span className="strong">brig</span> - a light combat vehicle, by design carrying a small amount of weapons. It is used for convoys and guarding trade routes in the least dangerous places. Usually they do not take part in major battles, and cannot withstand a battle with a superior opponent.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Corvette</span> (a short-range patrol ship) - a relatively large machine designed primarily to destroy targets of its own or lower class. Often used for watch and convoy duty.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Cruiser</span> - a machine designed primarily to perform tasks separately from the fleet. Able to stand up to a large number of opponents on its own, it is relatively fast to maneuver and is quick to go light. Cruisers are well armored and carry every conceivable weaponry on board: a confrontation between a ship of any other class and a cruiser is considered suicide.
                </p>
                <p
                    className="card-text text-justify">
                    Examples include the <TextInlineLink name={"bs_ships_nyorun_dreams"} text={"Njörun's Dreams"} /> and other <TextInlineLink name={"bs_ships_grime"} text={"Grime"} />-type ships.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Destroyer</span> - a heavy warship with an impressive arsenal on board. Not a cruiser, but also a formidable opponent: a simultaneous salvo of all its batteries is enough to turn Elbrus into a hill.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Dropship</span> - a small, often unarmed transport consisting of a cockpit and a troop compartment. It is used to transport people or cargo to a planet or between other ships.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Fighter</span> - a small maneuverable ship designed primarily to destroy light enemy vehicles and strike vulnerable parts of larger vehicles. Divided into:
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Frontline</span>: gain dominance in space and the atmosphere, suppressing enemy forces and providing cover for heavy ships or landing craft.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Multi-role</span>: destroy both enemy ships and ground targets. Can be equipped with stealth systems to avoid detection by long-range sensors (at short range no stealth system can fool a military scanner - it only buys time for a first strike).
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Interceptor fighters</span>: protect space or ground targets from small ships, missiles, and seismic weapons (accelerated asteroids).
                </p>
                <p className="card-text text-justify">
                    A <span className="strong">frigate</span> (a long-range patrol ship) is a machine designed to support the main fleet or for independent operations separately from it. It carries decent armament and is usually well armored.
                </p>
                <p className="card-text text-justify">
                    A <span className="strong">gunship</span> or <span className="strong">gunboat</span> is a ship larger than a fighter and designed both to operate as part of the fleet and to fight on its own against vehicles of its class or ground formations.
                </p>
                <p className="card-text text-justify">
                    Gunboats is often used as a dropship.
                </p>
                <p
                    className="card-text text-justify">
                    Examples: <TextInlineLink name={"bs_ships_fidget"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Raider</span> - a ship of indeterminate class, usually pirate. Made without regard to standards or templates. Named from the word raid, meaning a raider unit is a bunch of different looking ships with guns and bad intentions.
                </p>
                <p className="card-text text-justify">
                    A <span className="strong">Stormvessel</span> is a small multi-purpose ship that acts primarily against enemy ground forces and structures, or in the atmosphere. Outside the atmosphere it can act as a multi-role fighter. Can carry rail, magnetron, sonic, anti-missile, missile, and bomb weapons.
                </p>
            </>
    }
}
