import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function Shark_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"cosmographic unit"} /></span>,
        "owned": <span><TextPopup name={"bs_popups_state_shark_republic"} text={"Shark Republic"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_michelm"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_earthlings"} />, Mihjelmians</span>,

        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Shark Sector</span> is the outskirts of the <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Monarchy"} />, right next to the Rift. Always one of the poorest
                    regions, even in the boom years. A third-world country a hundred light-years across.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">
                        After <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />:
                    </span>
                </p>
                <p className='card-text text-justify'>
                    The Shark Sector has separated from the Monarchy and is now considered the territory of the <TextInlineLink name={"bs_popups_state_shark_republic"} text={"Shark Republic"} />, though it is not yet fully recognized by the galaxy.
                </p>
            </>
    }
}

