import wiki from '../components/cms/wiki.json';

export function NotFound() {
    document.title = "Not found || Cydonian sun"
    return (
        <div className="alert alert-danger">
            <p>No such page 🤕</p>
            <p>You can start with <a className="ordinary" href="/">Home</a> or <a className="ordinary"
                href={wiki["all_books"].link}>List of
                books</a>.
                <br />
                And you can immediately flip through the series of <a className="ordinary"
                    href={wiki["bs_series"].link}><i>Cydonian sun</i></a>: <a
                        className="ordinary" href={wiki["book_cydonian_girl"].link}><i>Cydonian girl</i></a> / <a
                            className="ordinary" href={wiki["book_hyperbeacons"].link}><i>Beacon thieves</i></a> / <a
                                className="ordinary" href={wiki["book_cydonian_autumn"].link}><i>Cydonian autumn</i></a>
            </p>
        </div>
    )
}
