import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Colbert_simone() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_hyperbeacons"} />,
        "name": "Simone Colbert",
        "fullname": <span>Simone Colbert</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_hela"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_hellesians"} text={"Hellesian"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "born": <span>66 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>65</span>,
        "dad": <span><TextInlineLink name={"bs_char_colbert_michel"} /></span>,
        "work": <span><TextInlineLink name={"bs_comp_esquirete"} /> of <TextInlineLink
            name={"bs_state_cydonian_priority"} /></span>,
        "imgs": [
            { "short": "bs_char_colbert_simone_1_jpg_500", "big": "bs_char_colbert_simone_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Youth</h3>
                <p className="card-text text-justify">
                    <span className="strong">Simone</span> was born on Hela, like the notorious <TextInlineLink name={"bs_char_skarsgard_oliver"} text={"Oliver Skarsgård"} />. Her father, Michel Colbert, was of higher social status than her mother, so Simone took his last name in the old tradition. It was the right decision, because the year she came of age, her father received the position of governor of the <TextInlineLink name={"bs_star_hela_gemini"} text={"Hela Gemini"} /> system.
                </p>
                <p className="card-text text-justify">
                    After high school, the girl spent several years at <TextInlineLink name={"bs_planet_bassian"} text={"Bassian"} />, taking advanced courses in <TextInlineLink name={"bs_science_astromechanics"} text={"Astromechanics"} /> and spaceflight physics. The courses were paid, so Simone, refusing to be funded by her family, worked the night shift at the local experimental design bureau. Without an education, she had no chance of getting a good job and had to do petty administrative work, currying favor with experienced engineers. However, this gave her unlimited access to many of the drawings and theoretical calculations of the Bassian craftsmen - a great source of information for someone who wants to get involved in spacecraft building.
                </p>
                <p className="card-text text-justify">
                    After the course, with not only the certificate but also the extensive knowledge acquired at the bureau, Simone went straight to <TextInlineLink name={"bs_popups_brienque"} text={"Brienque"} />, where she easily obtained a scholarship and even a part-time job at the university. There she spent nearly 10 years, learning to build propulsion systems for all classes of ship. Her undergraduate paper, <span className='code'>Theoretical Applications of Exomatter in the Propulsion Systems of Super-Heavy Combat Ships</span>, attracted the attention of Esquirete recruiters. Few defense agencies can get past someone pondering how to create something cool and military.
                </p>
                <p className="card-text text-justify">
                    At first Simone wanted to refuse, and even took a month to reconsider. The main doubt was that Esquirete worked for the queen, and <TextInlineLink name={"bs_char_van_gloria_adel"} text={"the queen"} /> was always politics. Simone hated her father's job, his friends, and the eternal hypocrisy with which he ingratiated himself with people and even the AI. But when the recruiters assured her that the rank-and-file Esquires had nothing to do with politics and received gigantic budgets to create breakthrough technologies, her doubts were instantly dispelled.
                </p>
                <p className="card-text text-justify">
                    To become a full-fledged esquire, Simone had to undergo combat training there, at Brienque. For a time, the girl changed from her lab coat to her military-style armor. She liked wearing the armor so much that even years later Colbert preferred to work in the workshop in combat attire rather than a civilian protective suit.
                </p>
                <p className="card-text text-justify">
                    Her father's catastrophic dislike of the Queen, Simone's boss, was the trigger for many family fights. And then there's the fact that Colbert Jr. likes to call her father's colleagues in the <TextInlineLink name={"bs_comp_priumviratus"} text={"Priumvirate"} /> useless parasites and make up insulting nicknames for them.
                </p>
                <h3 className="title-bg">Service in Esquirete (<TextInlineLink name={"book_hyperbeacons"} />)</h3>

                <p className="card-text text-justify">
                    Simone had met <TextInlineLink name={"bs_char_skarsgard_oliver"} text={"Oliver"} /> right after she started working for Esquirete, and the boogeyman had always given her mixed feelings about him. His aggression and impertinence in decision making often led to serious problems for the whole department and Colbert, even though she was not a combat officer, suffered from his actions more than once.
                </p>
                <p className="card-text text-justify">
                    When Queen Van Gloria unexpectedly put her in charge of the 3rd Research Institute of the Priority Defense Ministry, these problems only increased - it was Oliver who tested in combat the new developments of her subordinates. The 3rd Research Institute itself (aka Object #33 Palladas under the direction of Her Majesty's Corps of Engineering and Research) is the main and largest research center of the Esquirete, so by becoming its head, Simone actually headed the entire scientific wing of the unit.
                </p>
                <p className="card-text text-justify">
                    About a year before <TextInlineLink name={"bs_planet_tamanrasett"} text={"Tamanrasett"} />, Simone learned of mass disappearances throughout the galaxy. First, a battle fleet disappeared in Priority, leaving no trace. Then the population of an entire planet, <TextInlineLink name={"bs_planet_exile_1"} text={"Exodus-1"} />. The girl used all the resources of the Esquirete to figure out the source of the threat, but <TextInlineLink name={"bs_char_hartrey_kellays"} text={"Kellays Hartrey"} />, an ordinary IT guy from the <TextInlineLink name={"bs_org_cydonian_adimensional_communications"} text={"COC"} />, helped her in the end. With his work, the government AI was able to figure out the details of the kidnapping from the Exodus and Simone once again sent Oliver out to perform combat reconnaissance.
                </p>
                <p className="card-text text-justify">
                    There, on the surface of <TextInlineLink name={"bs_planet_tajbennae"} text={"Taibennae"} />, the first contact between humans and <TextInlineLink name={"bs_spec_vitruvianus"} text={"Vitruvians"} /> happened, which turned the history of the galaxy upside down.
                </p>
            </>
    }
}
