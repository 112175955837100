import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Ajliree() {
    return {
        "homeworld": <span><TextInlineLink name={"bs_planet_ajliraen"} /></span>,
        "state": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Eilirén Domination"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_javet_bejsenaer_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_javet_eijenora_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_javet_erbees_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_javet_pauline_1_jpg_200"} /></li>
        </ul>,
        "langs": <span><TextInlineLink name={"bs_lang_telpimary"} text={"Telpimari"} />, <TextInlineLink name={"bs_lang_earth"} text={"Earthlin"} /></span>,
        "text": <><h3 className="title-bg">History</h3>
            <p className="card-text text-justify">
                <span className="strong">Eilireé</span> (don't call them Eiliréns!) are broadly speaking residents of the Eilirén Domination, and
                narrowly speaking, full citizens of the Domination. They strongly dislike being called Eilirén, because
                in the Telpimari language such generalizations are considered offensive. There is a planet, there is a
                people on that planet, and there is a people in an entire state. The latter two concepts have merged
                over the centuries into the word Eilireé though legally, Domination society is still divided into
                unequal groups, one of which, as direct descendants of the first inhabitants of Eilirén, has many more
                rights and freedoms.
            </p>
            <p className="card-text text-justify">
                Intriguingly, they are not offended by such terms as Eilirénian. Rather, they even encourage it, since
                on <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> the term Eilireén sounds more complicated and pretentious.
            </p>
            <h3 id={"language"} className="title-bg">Language</h3>
            <p className="card-text text-justify">
                The Eilireé language is called Telpimari (or Telpimarian, if you want to sound like a rural goon), which
                literally means the language of those who have flown away. Flown away they called themselves a few
                centuries after they left Earth: at some point, the differences in pronunciation between their
                contemporaries and the videotapes of their ancestors became so strong that new generations no longer had
                much understanding of their earthly relatives. The language mutated rapidly, and it was only with the
                introduction of relatively rapid communication with other inhabited worlds that the Telpimari slowly
                began to return to their roots. Nevertheless, there are still many people in Domination who have
                difficulty speaking the galaxy's common Earth language, and some do not seek to learn it at all.
            </p></>
    }
}