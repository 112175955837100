import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ardemar_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"cosmographic unit"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_helena"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,

        "text":
            <>
                <h3 className="title-bg">Brief</h3>
                <p className='card-text text-justify'>
                    The <span className='strong'>Ardimare Sector</span> (name usually translates to Earth language as Ardemar) is one of the oldest and most culturally rich regions of the Cydonian Priority. It occupies a strategic position around the Auralis Nebula, a luminous expanse of gold and lavender dust clouds that lend some planets of the sector an ethereal, dreamlike sky. Known for its heritage, Ardimare is sometimes called the “Cradle of Ambitions”, where traditions of governance and artistry run deep. It is a place where influence is gained not with force, but with elegance, and progress must always be accompanied by respect for the past.
                </p>
                <p className='card-text text-justify'>
                    It borders <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Domination"} /> toward the galactic core and <TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /> toward Sambora itself.
                </p>
                <p className='card-text text-justify'>
                    While other sectors have modernized, Ardimare remains deeply tied to tradition, with grand cities, ancient universities, and carefully cultivated landscapes that evoke the golden age of exploration. The region’s worlds often attract artists, scholars, and visionaries—those seeking to create, learn, and contemplate the mysteries of both the past and future. Yet, beneath its refined exterior lies a current of quiet competition: old noble families and ambitious newcomers alike vie for prestige, each move concealed by layers of etiquette.
                </p>
                <p className='card-text text-justify'>
                    While not a political or military power, Ardimare’s influence lies in culture, diplomacy, and tradition. The sector has become a place where philosophy, arts, and history flourish, making it a quiet but essential player in the galactic landscape. Many of the Priority’s most distinguished diplomats, artists, and scholars trace their origins to Helena or the broader Ardimare Sector. It is a place where ideas are exchanged freely, but always with a sense of decorum—a land where intellectual debates can carry as much weight as trade negotiations.
                </p>
                <p className='card-text text-justify'>
                    Helena-at-Tellause embodies the spirit of continuity cherished by the sector. Its ancient festivals, academic traditions, and rituals preserve the atmosphere of the Dawn of Light Age, long gone from most other parts of the galaxy. Some visitors claim that stepping onto Helena is like stepping back in time, while others call it a living museum of the Priority’s earliest days.
                </p>
                <p className='card-text text-justify'>
                    The Auralis Nebula adds another layer of mystique to the sector. Its shimmering veils of gas and dust are not only a visual marvel but have also given rise to legends of lost starships and forgotten gods. Explorers venturing into its depths often report strange occurrences, from flickering signals that defy explanation to unexpected gravitational shifts, as if the nebula itself were alive. Despite—or perhaps because of—these mysteries, the Auralis Nebula has become a source of fascination for scientists, poets, and dreamers alike.
                </p>
                <p className='card-text text-justify'>
                    The Ardimare Sector is a beacon of refinement and heritage in the vastness of the galaxy. Though its star may no longer shine as brightly as that of Cydonia, its influence is far from diminished. As the galaxy shifts and evolves, Ardimare continues to stand as a testament to the enduring spirit of the Priority—a reminder that progress can be achieved not only through innovation but also through preserving the best of the past.
                </p>
                <p className='card-text text-justify'>
                    In a universe constantly hurtling toward the future, Helena-at-Tellause offers a place where one can pause, reflect, and savor the beauty of what once was—and what could still be.
                </p>
            </>
    }
}

