import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Hellesian_raid() {
    return {
        "date": <span>~4800 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "type": <span>space area</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_ariadne_sector"} text={"Ariadne"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} />, <TextInlineLink name={"bs_people_hellesians"} /> (<TextInlineLink
            name={"bs_spec_humans"}
            text={"Homo sapiens"} />)</span>,
        "imgs": [{ "short": "bs_wiki_hellesian_raid_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Hellesian Run</span> is the area between <TextInlineLink name={"bs_star_mirage"} text={"Mirage"} /> and <TextInlineLink name={"bs_star_hela_gemini"} text={"Hela Gemini"} /> systems, the most populated area in the
                    country. In fact, it can be called one large metropolis, existing in full self-sufficiency mode:
                    there are not only residential and recreational stations, but also entire artificial moons, dotted
                    with hydroponics facilities and meat farms. In addition, there are short hyper-routes through the
                    region, designed for small private boats.
                </p>
                <p className="card-text text-justify">
                    The Raid is fortified by numerous defensive structures, military shipyards and weapons factories: in
                    the event of an attack on Priority, a full military-industrial complex can be deployed here in a
                    matter of minutes, capable of defending the capital and several neighboring systems.
                </p>
                <p className="card-text text-justify">
                    The most important defensive facilities here are the headquarters of the <TextInlineLink name={"bs_comp_esquirete"} text={"Esquirete"} /> (Object #33
                    Palladas) in the Mirage system and the Brianne De Estrada Hellesian Academy of Commercial and
                    Military Navy in the Hela Gemini system.
                </p>
            </>
    }
}

