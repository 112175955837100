import { CrumbsBlackSunBook } from "../../../../components/Bread_Crumbs";
import {
    TextInlineLink, TextInlineLinkCustomClass
} from '../../../../components/cms/wiki_functions';
import wiki from "../../../../components/cms/wiki.json";
import imgs from "../../../../components/cms/images_repo.json";
import parse from 'html-react-parser';

export function GlossaryPictureItem(props) {
    let descr = parse(wiki[props.repo].popup)
    if (props.descr !== undefined) {
        descr = props.descr
    }

    let img = imgs["general_empty_500_500"].link
    if (props.img !== undefined) {
        img = props.img
    }

    return <div className="card mb-3">
        <div className="row g-0">
            <div className="col-md-4">
                <a href={wiki[props.repo].link}>
                    <div className=" card-img-top" style={{ "width": "100%", "height": "100%", "margin": "auto" }}>
                        <div className="bg-image" style={{
                            "maxWidth": "100%",
                            "height": "100%",
                            "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                        }}>
                            <img style={{ "width": "100%" }} src={img} alt={props.name} />
                        </div>
                    </div>
                </a>
            </div>
            <div className="col-md-8">
                <div className="card-body">
                    <a href={wiki[props.repo].link} className="a-black"><h5
                        className="card-title">{wiki[props.repo].name}</h5></a>
                    <p className="card-text">{descr}</p>
                </div>
            </div>
        </div>
    </div>
}

export function Glossary() {
    document.title = "Wiki || Cydonian girl & Cydonian sun"
    return (
        <div>
            <CrumbsBlackSunBook name={<span><i className="fa-solid fa-w" />  Wiki</span>} />

            <div key="mainRow" className="row ">
                <div className="col-lg-12 well" style={{ "paddingBottom": "1em" }}>
                    <p className="card-text text-justify">Here is collected all the canonical information about the
                        world of the <TextInlineLink name={"bs_series"} popup={""} text={"Cydonian Sun"} />.
                    </p>
                </div>
            </div>
            <hr />
            <div key="mainRow" className="row ">
                <div className={"col-lg-12"}>
                    <p className="card-text text-justify">
                        Pages are organized into simple categories:
                    </p>
                </div>
            </div>
            <div key="mainRow" className="row">
                <div className={"col-lg-4"}>
                    <ul className="book-info" style={{ "marginBottom": "0" }}>
                        <li><a href={wiki["bs_timeline"].link}><i className="fa-solid fa-clock-rotate-left" /> History</a>
                        </li>
                        <li><a href={wiki["bs_characters"].link}><i className="fa-solid fa-user-tie" /> Characters</a>
                        </li>
                        <li><a href={wiki["bs_places"].link}><i className="fa-solid fa-earth-asia" /> Places</a></li>
                        <li><a href={wiki["bs_organizations"].link}><i
                            className="fa-regular fa-building" /> Organizations</a></li>
                    </ul>
                </div>
                <div className={"col-lg-4"}>
                    <ul className="book-info" style={{ "marginBottom": "0" }}>
                        <li><a href={wiki["bs_maps"].link}><i className="fa-solid fa-map-location-dot" /> Maps</a>
                        </li>
                        <li><a href={wiki["bs_universe"].link}><i className="fa-solid fa-scroll" /> World</a>
                        </li>
                        <li><a href={wiki["bs_technologies"].link}><i className="fa-solid fa-flask" /> Science and
                            tech</a></li>
                        <li><a href={wiki["bs_machinery"].link}><i className="fa-solid fa-satellite" /> Technics</a>
                        </li>
                    </ul>
                </div>
                <div className={"col-lg-4"}>
                    <ul className="book-info" style={{ "marginBottom": "0" }}>
                        <li><a href={wiki["bs_gallery"].link}><i className="fas fa-palette" /> Gallery</a></li>
                        <li><a href={wiki["bs_people"].link}><i className="fa-solid fa-user-group" /> Nations</a></li>
                        <li><a href={wiki["bs_creatures"].link}><i className="fa-solid fa-dragon" /> Creatures</a>
                        </li>
                        <li><a href={wiki["bs_weapons_and_tech"].link}><i className="fa-solid fa-gun" /> Weapons</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row" style={{ "paddingTop": "1em" }}>
                <div className="d-grid gap-2">
                    <a className="btn btn-main" href={wiki["bs_wiki_all"].link}>COMPLETE ALPHABETIC LIST</a>
                    <a className="btn btn-main" href={"/search"}>SEARCH</a>
                </div>
            </div>
            <h2 className="title-bg text-center" style={{ "marginTop": "1em" }}>MOST POPULAR</h2>

            <div key="mainRow" className="row well">
                <h4 style={{ "marginTop": "auto" }} className='text-center'><a className="a-black"
                    href={wiki["bs_characters"].link}>CHARACTERS</a>
                </h4>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["bs_char_de_levine_rosalie_1_png_500_t"].link}
                        repo={"bs_char_de_levine_rosalie"}
                        descr={<span><span className="strong">Rosalie De Levine</span> - daughter of <TextInlineLink name={"bs_char_the_whip"} text={"The Whip"} /> and <TextInlineLink name={"bs_char_de_levine_clara"} text={"Clara De Levine"} />, the very same Cydonian girl from the <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} /></span>}
                    />
                    <GlossaryPictureItem img={imgs["bs_char_ursula_adams_1_jpg_500"].link} repo={"bs_char_ursula_adams"}
                        descr={<span><strong>Ursula Adams</strong> - author of <TextInlineLink name={"bs_universe_bird_of_passage_guide_to_milky_way"} text={"Birds of passage guide to Milky way"} />, character of <TextInlineLink name={"book_bonecollectors"} text={"Bonecollectors"} /> & <TextInlineLink name={"book_the_free_traders"} text={"Free traders"} /></span>}
                    />
                </div>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["bs_char_esora_eve_1_jpg_500"].link} repo={"bs_char_esora_eve"}
                        descr={<span><strong>Eve Esora</strong> - grumpy and extremely dangerous Agathon woman, character of Cydonian girl, Cydonian autumn, and other novels</span>}
                    />
                    <GlossaryPictureItem img={imgs["bs_char_the_whip_1_png_500"].link} repo={"bs_char_the_whip"}
                        descr={<span><strong>The Whip</strong> - a nudist alien, puny but very aggressive, character of all parts of Cydonian Sun</span>}
                    />
                </div>
                <div className="d-grid gap-2">
                    <TextInlineLinkCustomClass class={"btn btn-main"} name={"bs_characters"} text={"See all"} />
                </div>
            </div>

            <div key="mainRow" className="row well">
                <h4 style={{ "marginTop": "auto" }} className='text-center'><a className="a-black"
                    href={wiki["bs_planets"].link}>WORLDS</a>
                </h4>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["arville_500"].link} repo={"bs_planet_arville"}
                        descr={<span><strong>Arville</strong> - a mythical world somewhere in The Rift, presumably inhabited several thousand years ago by humans who have had no contact with other peoples since</span>}
                    />
                    <GlossaryPictureItem img={imgs["cydonia_500"].link} repo={"bs_planet_cydonia"}
                        descr={<span><strong>Cydonia</strong> is a metropolis in the Ariadne sector, the capital of the Cydonian Priority</span>}
                    />
                </div>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["new_havana_500"].link} repo={"bs_planet_new_havana"}
                        descr={<span><strong>New Havana</strong>, one of the unofficial capitals of the Free Worlds and home to the powerful barons of Samborae</span>}
                    />
                    <GlossaryPictureItem img={imgs["tamanrasett_500"].link} repo={"bs_planet_tamanrasett"}
                        descr={<span><strong>Tamanrasett</strong> - a world on the very edge of the Ternerian end, in the Deaconier system, at the very periphery of the settled territories</span>}
                    />
                </div>
                <div className="d-grid gap-2">
                    <TextInlineLinkCustomClass class={"btn btn-main"} name={"bs_planets"} text={"See all"} />
                </div>
            </div>

            <div key="mainRow" className="row well">
                <h4 style={{ "marginTop": "auto" }} className='text-center'><a className="a-black"
                    href={wiki["bs_people"].link}>PEOPLES</a>
                </h4>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["bs_char_de_karma_ivar_1_png_500"].link}
                        repo={"bs_people_cydonians"}
                        descr={<span><strong>Cydonians</strong> - the collective name of inhabitants of Cydonian Priority, includes many peoples on the other side of The Rift from Earth</span>}
                    />
                </div>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["bs_char_browdy_adam_1_png_500"].link}
                        repo={"bs_people_alamarsie"}
                        descr={<span><strong>Alamarsie</strong> - nomadic people, rarely descending from their hugehive ships to the surface of the planets</span>}
                    />
                </div>
                <div className="d-grid gap-2">
                    <TextInlineLinkCustomClass class={"btn btn-main"} name={"bs_people"} text={"See all"} />
                </div>
            </div>

            <div key="mainRow" className="row well">
                <h4 style={{ "marginTop": "auto" }} className='text-center'>OTHER</h4>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["bs_wiki_cydonians_map_en"].link}
                        repo={"bs_state_cydonian_priority"}
                        descr={<span><strong>Cydonian Priority</strong> - a meritocracy in <TextInlineLink name={"bs_space_brienne_cluster"} text={"Brianne Cluster"} /> with its capital in Cydonia</span>}
                    />
                    <GlossaryPictureItem repo={"bs_weaponry_terminology"}
                        descr={<span><strong>Military terminology</strong> - terms and abbreviations common to the military fleets of most countries in the galaxy</span>}
                    />
                    <GlossaryPictureItem img={imgs["bs_wiki_serpentara_emplumara"].link} repo={"bs_comp_feathered_serpent"}
                        descr={<span><strong>Feathered Serpent</strong> - crime syndicate from the <TextInlineLink name={"bs_state_free_worlds"} text={"Free worlds"} /></span>}
                    />
                </div>

                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["bs_religion_arthur_1"].link}
                        repo={"bs_universe_religion_arthurians"}
                        descr={<span><strong>Arthurianism</strong> - an <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /> cult deeply rooted in the culture of both nomads and planetsmen</span>}
                    />
                    <GlossaryPictureItem img={imgs["bs_char_ursula_adams_2_jpg_500"].link}
                        repo={"bs_universe_bird_of_passage_guide_to_milky_way"}
                        descr={<span><strong>Birds of passage guide to Milky way</strong> - a book about life in the Milky Way a few years before <TextInlineLink name={"bs_timeline"} text={"Tamanrasett"} /></span>}
                    />
                    <GlossaryPictureItem img={imgs["bs_wiki_northern_star_of_niradanhae"].link}
                        repo={"bs_tech_northern_star_of_niradanhae"}
                        descr={<span><strong>Northern Star of Niradanha</strong> - an <TextInlineLink name={"bs_tech_inter_branal_positioning_beacon"} text={"interdimensional beacon"} /> that creates a stable wormhole to any (theoretically) point in the universe</span>}
                    />
                </div>
            </div>
        </div>
    )
}