import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Shield_kinetic() {
    return {
        "science": <span>physics</span>,
        "use": <span>occupational safety</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Kinetic shield</span> or <span className="strong">kinetic regression field (KRF)</span> – a thing that looks like an atmospheric shield, but it doesn't let anything through except light. Requires too much energy, so for the duration of the <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} /> there are only low-powered prototypes. But the potential is enormous: if one were to equip a spaceship or an atmospheric transport, or even space marines, the number of casualties from war and civilian accidents would be dramatically reduced.
                </p>
            </>
    }
}
