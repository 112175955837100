import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Javet_erbees() {
    return {
        "name": "Erbesar Yaveth",
        "born": <span>44 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>43</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_ajliraen"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_ajliree"} /></span>,
        "fullname": <span>Erbesar Yaveth</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "dad": <span><TextInlineLink name={"bs_char_javet_bejsenaer"} /></span>,
        "cousins": <span><TextInlineLink name={"bs_char_javet_pauline"} /></span>,
        "sister": <span><TextInlineLink name={"bs_char_javet_eijenora"} /></span>,
        "work": <span>The Second Crown Prince of <TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,
        "imgs": [
            { "short": "bs_char_javet_erbees_1_jpg_500", "big": "bs_char_javet_erbees_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify">
                    <span className="strong">Erbesar</span> is the youngest of Beisarion Yaveth's heirs to the throne of the Eiliren Domination. All his life he has watched his older sister, Elsinora, and tried to follow her example. Since childhood, the prince was trained in management, economics, and many other disciplines that help run the empire, but he understood: he is unlikely to become emperor. So Erbesar, in order not to lose the family's favor, decided to help his older sister in everything and become her loyal protégé.
                </p>
                <p className="card-text text-justify">
                    Officially, he had no position at court, and few people expected the younger prince to accomplish anything of note. Erbesar was mostly engaged in the emperor's and princess's requests, doing various administrative work for them. He was not recognized as fit for the throne even after Elsinora had twice fallen into disgrace and, according to their father, openly betrayed the interests of the throne and the whole Domination.
                </p>
            </>
    }
}
