import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function Skarsgard_vivien() {
    return {
        "name": "Vivien Skarsgård",
        "fullname": <span>Vivien <small>Splinter-1</small> Skarsgård</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span>588th Space Assault Regiment <TextInlineLink
            name={"bs_comp_esquirete"} text={"Gothenburg Witches"} /> of <TextInlineLink name={"bs_state_cydonian_priority"} /> <TextPopup
                name={"bs_org_cydonian_sof"}
                text={"SOF"} /></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_hela"} /></span>,
        "born": <span>60 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "parents": <span>Xavier and Camille Skarsgård</span>,
        "age": <span>59</span>,
        "nation": <span><TextInlineLink name={"bs_people_hellesians"} text={"Hellesian"} /></span>,
        "parents_adopt": <span>Davron De Karma</span>,
        "relatives": <span><TextInlineLink name={"bs_char_skarsgard_oliver"} />, <TextInlineLink
            name={"bs_char_de_karma_ivar"} text={"Ivar"} />,
            Ravenna and Carlyle De Karma</span>,
        "imgs": [
            { "short": "bs_char_skarsgard_vivien_1_jpg_500", "big": "bs_char_skarsgard_vivien_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Youth</h3>
                <p className="card-text text-justify">
                    <span className="strong">Vivien</span> was born on Hela and spent her entire childhood there. The high gravity of her homeland gave her strong muscles and great stamina, but her combative nature seems to have somehow developed on its own.
                </p>
                <p className="card-text text-justify">
                    She lost her father as a child: like many Cydonian officers, he died in the <TextInlineLink name={"bs_state_free_worlds"} text={"Free worlds"} />, in one of the many skirmishes with the pirates. Unlike her brother, Oliver, who became depressed over it, Vivien's loss only hardened her. She dreamed of joining the navy to avenge the <TextInlineLink name={"bs_people_samborians"} text={"Samborians"} /> and was moving steadily toward that dream.
                </p>
                <p className="card-text text-justify">
                    After her mother married another officer, Davron De Karma, the family moved to <TextInlineLink name={"bs_planet_cydonia"} text={"Cydonia"} />. There, Vivien grew a head taller than she should have with her build: the growth stimulants given to young Hellesians to keep them toned for increased gravity played a cruel trick on her and her brother. However, it only made Vivien more self-confident. Few people dared to make a joke of this young person and not loose their teeth.
                </p>
                <p className="card-text text-justify">
                    After high school, she enrolled at the Brianne De Estrada Hellesian Academy of Commercial and Military Navy. There she studied for about a year before realizing that she didn't want to get involved with cumbersome clumsy heavy ships - she wanted speed and maneuverability. So she changed direction and became an assault pilot. It took her a long time to prepare and read all the relevant literature on light ship piloting. Eventually Vivien passed the tests and, after the preparatory courses, was accepted into a special program of the <TextInlineLink name={"bs_comp_esquirete"} text={"Esquirete"} /> for the recruitment of promising young pilots.
                </p>
                <h3 className="title-bg">Cydonian Military Navy</h3>
                <p className="card-text text-justify">
                    After only two years, Vivien had already flown her first combat mission and proved to herself and others that she really had a talent for flying light machinery.
                </p>
                <p className="card-text text-justify">
                    After six years in a borderfleet, where she was a trainee (the Esquirete does not accept newcomers, even if they have passed Esquirete's own training program), Vivien finally joined the very real expeditionary fleet, sent to the <TextInlineLink name={"bs_state_free_worlds"} text={"Free worlds"} /> to restore order in the Priority Border systems. There she gained enormous experience in storming fortified pirate bases and maneuvering space combat.
                </p>
                <p className="card-text text-justify">
                    Service in the Free Worlds completely cut her off from her family, with whom Vivien saw no more than once every three months. So no one was surprised when she transferred to the elite unit directly Esquirete and finally disappeared from the radar of loved ones. In 15 BT she joined the 588th SOF Space Assault Regiment, where she received the nickname Splinter because of her harmful and mocking disposition. When 10 years later she was put in charge of a platoon, the entire unit was named Splinter as a tribute to the experienced pilot.
                </p>
            </>
    }
}
