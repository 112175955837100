import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ajliraen() {

    return {
        "date": <span>~4750 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,
        "habitats": <span>255 billion (57 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>none</span>,
        "g": <span>1.01 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>dead</span>,
        "system": <span><TextInlineLink name={"bs_star_raasal_ajlyrien_dominion"} text={"Dominion Raasal"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_travalsar"} text={"Travalsar"} /></span>,
        "type": <span><span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_ackron_nebulae"} /></span>,
        "imgs": [{ "short": "bs_wiki_ajliraen_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">
                    Excerpts from <TextInlineLink name={"bs_universe_bird_of_passage_guide_to_milky_way"} /> by Ursula
                    Adams <small>(3 <TextInlineLink name={"bs_timeline"}
                        text={"BT"} />)</small>
                </h3>
                <p className="card-text text-justify">
                    <span className="strong">Eilirén</span> – is a world of grandeur and contrast, born of three suns. The dense light of the yellow dwarfs
                    of Raasal and Eilirén is softened by the purple rays of the red giant Dominion, creating eternal white nights,
                    leaving little room for darkness on the surface. It's a world without starry skies -
                    only endless light from which there is no escape.
                </p>

                <p className="card-text text-justify">
                    Eilirén was once home to a lush flora: forests of twisted trees,
                    covered in glowing leaves, and endless fields of plants that wriggled like animals.
                    But of this ancient nature, only memories remain.
                    Urban development has swallowed up almost the entire landscape, and today the planet is covered with monumental megacities.
                    Concrete, marble and metal have replaced greenery,
                    and airways connect urban clusters that stretch upwards into the clouds.
                    In such large-scale buildings, people feel like grains of sand in an ocean of architectural grandeur.
                </p>
                <p className="card-text text-justify">
                    The Eilireé strive for perfection of form and content - every house, palace or administrative
                    building embodies the ideal of symmetry and austerity.
                </p>
                <p className="card-text text-justify">
                    Tall towers reach for the sky, their facades decorated with bas-reliefs depicting the deeds of
                    great ancestors and symbols of imperial glory. The streets are wide, paved with polished stone,
                    and the avenues are lined with endless rows of statues celebrating the deeds of the citizens of the Ailiran Dominion.
                </p>
                <p className="card-text text-justify">
                    Everything here is imbued with pathos and triumph - even the patterns on the balustrades tell
                    of glorious victories.
                </p>
                <p className="card-text text-justify">
                    Despite the cold austerity of the urban style, there is something monumental and eternal about this world.
                </p>
                <p className="card-text text-justify">
                    From fortress walls to palaces and monuments, everything on
                    Eilirén seems built to last through the ages. But behind this grandeur lies
                    a stifling atmosphere of control and class inequality. Eilireé residents (full citizens) live in
                    spacious apartments with luxurious views, while the
                    Quirites dwell in dingy working-class neighborhoods barely discernible in the architectural shadows.
                    In such places, the white light of the three suns is no longer so bright, and the horizon is drowned
                    in a haze of fumes and machine smoke.
                </p>
                <p className="card-text text-justify">
                    There is almost no wildlife left on the surface of Eilirén, but in the gardens of palaces and temples there
                    are still artificial oases, places where flowers grow under the control of botanists, as if to symbolize that
                    nature can only exist under the rule of human order. Even here, beauty is subject to law, where every leaf must
                    grow in its place and every waterfall must flow along a set route.
                </p>
                <p className="card-text text-justify">
                    Eilirén is a majestic marble temple of civilization, where progress has become the new religion and the empire is compared to the eternal light of three suns,
                    from which there is no hiding and no escape.
                </p>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Eilirén</span> colony was founded
                    almost at the same time as <TextInlineLink name={"bs_planet_cydonia"} text={"Cydonia"} />. At that time, the path to the Dominion system was one of the most
                    difficult and long,
                    but the journey was worth it: Eilirén had its own biosphere, which had to be only slightly
                    adjusted for Homo sapiens.
                </p>
                <p className="card-text text-justify">
                    The first colonists had a hard time, but the landing and settling in went much better,
                    than during the
                    colonization of <TextInlineLink name={"bs_planet_cydonia"} />.
                    The newly-formed Eilireé quickly broke all ties with <TextInlineLink name={"bs_planet_earth"} />and other
                    colonies - began to build up
                    their own society and tried not to repeat the mistakes of their ancestors. If you read the history of Eilirén Domination, it becomes clear - things didn't work out.
                </p>
                <p className="card-text text-justify">
                    It was easy to disengage from former compatriots: the journey to <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> and to Cydonia
                    took decades, some ships never made it at all. There was no communication either, and therefore
                    only <TextInlineLink name={"bs_people_alamarsie"} text={"alamarsie"} /> were companions of Eilirén civilization in the early centuries of planetary exploration.
                </p>
                <p className="card-text text-justify">
                    Eilirén has three suns: <TextInlineLink name={"bs_star_raasal_ajlyrien_dominion"} text={"Raasal, Eilirén and Dominion"} />. The first two are yellow dwarfs, just like Terrestrial, and the third is a red giant. White nights always reign here and there was once a very lush flora, bizarre and beautiful. But Eilireé, like many other human societies, chose the path of urbanization and concreted the hell out of it.
                </p>
            </>
    }
}
