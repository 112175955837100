import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Engine_overlight() {
    return {
        "science": <span>quantum mechanics, <
            TextInlineLink name={"bs_science_astromechanics"} text={"astromechanics"} /></span>,
        "use": <span>space flights</span>,
        "text": <>
            <p className="card-text text-justify">
                <span className="strong">FTL engine</span> or <span className="strong">Overlight engine</span> or <span className="strong">alcubierre</span> – aka the Alcubierre generator, aka the Einstein engine. It's basically a fusion reactor that synthesizes exotic matter within its core. It helps to warp space so that it winds up in front of the ship and unwinds behind it, thereby moving the machine through the universe faster than the speed of light, while the ship itself technically stands in place.
            </p>
        </>
    }
}
