import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function Skarsgard_oliver() {
    return {
        "name": "Oliver Skarsgård",
        "fullname": <span>Oliver <small>Ocelot</small> Skarsgård</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span><TextInlineLink name={"bs_comp_esquirete"} /> of <TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "imgs": [
            { "short": "bs_char_skarsgard_oliver_1_png_500", "big": "bs_char_skarsgard_oliver_1_jpg_full" },
            { "short": "bs_char_skarsgard_oliver_2_jpg_500", "big": "bs_char_skarsgard_oliver_2_jpg_full" },
        ],
        "born": <span>62 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>61</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_hela"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_hellesians"} text={"Hellesian"} /></span>,
        "parents": <span>Xavier and Camille Skarsgård</span>,
        "parents_adopt": <span>Davron De Karma</span>,
        "relatives": <span><TextInlineLink
            name={"bs_char_skarsgard_vivien"} />, <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar"} />,
            Ravenna and Carlyle De Karma</span>,

        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"} />
                </div>
            ],
        "text":
            <>
                <h4 className="title-bg">Childhood and Youth</h4>
                <p className="card-text text-justify">
                    <span className="strong">Oliver</span> was born on Hela, 62 years before <TextInlineLink name={"bs_timeline"} text={"Contact on Tamanrasett"} />. He spent his early childhood there, under the ruby skies of Dafa-Haru. Because of the very high gravity, he and his younger sister Vivien were given regular injections of stimulants to make their bodies function better in the unfriendly conditions of the Ruby Fox. Because of this, Oliver is noticeably stronger and taller than the average Homo sapiens.
                </p>
                <p className="card-text text-justify">
                    From an early age, Oliver dreamed of following in the footsteps of his father, a combat officer in the Cydonian navy. But his death in <TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} />, when Skarsgård was still a child, hurt his psyche: the boy gave up the dream of the navy. He would not have returned to thoughts of service had not his mother, Camille Skarsgård, married Davron De Karma, another Cydonian officer. The family moved to <TextInlineLink name={"bs_planet_cydonia"} text={"Cydonia"} />, the family nest of De Karma, and there occurred Oliver's fateful introduction to <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar"} />, who became not only a half-brother but also a role model for him.
                </p>
                <p className="card-text text-justify">
                    Ivar's determination, boldness, and ingenuity changed Oliver's life priorities. He grew up watching his older brother and eventually began to follow his example.
                </p>
                <p className="card-text text-justify">
                    Nevertheless, the first years after the move were difficult: among his peers Oliver had a reputation as a quiet fellow, sometimes even as a whipping boy. Skarsgård was sent to an expensive private school, because the De Karma family was very wealthy and did not want its new member to vegetate in a budget nuthouse. There Oliver, lacking proper manners and social skills, often became the object of ridicule and mockery.
                </p>
                <p className="card-text text-justify">
                    Seeing this, Ivar gradually taught his younger brother to fight back, and over time he turned the modest quiet boy into the menace of school bullies. By the last year of his studies Oliver had become a real big man, and he knew that he would connect his life exclusively with the service in the navy.
                </p>
                <p className="card-text text-justify">
                    He got his higher education in <TextInlineLink name={"bs_state_cydonian_priority_science"} text={"Brienque"} popup={"Brianne De Estrada Hellesian Academy of Commercial and Military Navy"} /> which he could enter only on the second attempt. The reason for his rejection was an almost complete ignorance of mathematics, which only a year of advanced study at <TextInlineLink name={"bs_planet_bassian"} text={"Bassian"} /> (just when <TextInlineLink name={"bs_char_van_gloria_adel"} text={"Adele Van Gloria"} />, the future Queen of Priority, was writing her  <TextPopup name={"bs_popups_van_gloria_bachelor_work"} text={"BA"} /> there) could make up for.
                </p>
                <h4 className="title-bg">Service in the Navy and <TextInlineLink name={"bs_timeline_tajbennae_incident"} text={"Contact on Taibennae"} /></h4>
                <p className="card-text text-justify">
                    At the academy Oliver seemed to finally be able to reach his potential: despite the problems with his performance in the exact sciences, his tactical skills, leadership qualities and physical fitness were at their best. So even before his graduation, he managed to visit several hot spots in Sambora, on practice as it is commonly called in the Cydonian military fleet.
                </p>
                <p className="card-text text-justify">
                    The new Skarsgård returned from his deployments: brave, tenacious, and knowing how to conduct himself on the battlefield. From then on, his career went from strength to strength and it was not until the 13th BT, during the <TextInlineLink name={"bs_planet_tiora"} text={"Tiorian Incident"} />, that he received his first penalty. His half-brother had paid for the incident by being fired and stripped of all ranks and decorations, so Oliver found the consequences for himself even too lenient.
                </p>
                <p className="card-text text-justify">
                    He was partly right: in a situation where other officers were permanently deprived of career advancement, Oliver began to rise through the naval hierarchy suspiciously quickly. This was not so much due to his abilities as to the prestige he had earned in Army circles after the Incident. With the ever-increasing threat from the <TextInlineLink name={"bs_state_free_worlds"} text={"Free worlds"} />, the Cydonian admirals wanted people who would do anything for their country, not those who thought primarily of their new rank.
                </p>
                <p className="card-text text-justify">
                    This helped Skarsgård get the most interesting as he called them, assignments. The officer spent several years on the craziest missions through the Free Worlds, <TextInlineLink name={"bs_space_the_rift"} text={"The Rift"} /> to other parts of the galaxy, and then was promoted to Her Majesty's Esquire by Adele Van Gloria herself. And as early as the 3rd BT, he was head of the <TextInlineLink name={"bs_comp_esquirete"} text={"Esquirete"} /> Strike Wing based at Object #111 Polygon in the <TextInlineLink name={"bs_star_schiaparelli"} text={"Schiaparelli system"} />.
                </p>
                <p className="card-text text-justify">
                    Two years after this assignment, Contact on Taibennae happened (<TextInlineLink name={"book_hyperbeacons"} text={"Beacon thieves"} />): Oliver was the first to make contact with another intelligent life and even managed to kill one of its representatives. The <TextInlineLink name={"bs_spec_vitruvianus"} text={"Vitruvians"} /> did not appreciate this approach and destroyed the entire landing party except for Skarsgård  himself.
                </p>
                <p className="card-text text-justify">
                    There he met <TextInlineLink name={"bs_char_the_whip"} text={"The Whip"} />, whom he jokingly nicknamed Martin Sue. The alien told him about the impending invasion and promised to help people, but he demanded from Oliver not to tell his fellows about what was happening, lest he provoke Vitruvians to more aggressive actions. This was the end of the Thieves.
                </p>
            </>
    }
}
