import { TextInlineLink } from "../../../../../components/cms/wiki_functions";

export function Languages() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Alamarsie</span> – the language of
                    nomads, who never set foot on a planet. Can be found <TextInlineLink name={"bs_lang_alamarsie"} text={"here"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Arum (arum linga – golden language)</span> or just <span className="strong">agathonian</span> is described in the history of <TextInlineLink name={"bs_lang_arum"} text={"agathonians"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Earth language</span> is now in the article about <TextInlineLink name={"bs_lang_earth"} text={"earthlings"} />.</p>
                <p className="card-text text-justify">
                    <span className="strong">Cydonian</span> has moved
                    to <TextInlineLink name={"bs_lang_cydonian"} text={"article about Priority"} />.
                </p>
                <p className="card-text text-justify">
                    You can read about <span className="strong">samborian lang</span> in <TextInlineLink name={"bs_lang_samborian"} text={"this article"} />.
                </p>
            </>
    }
}
