import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Helena() {
    return {
        "date": <span>4730 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "governor": <span>lord <TextInlineLink name={"bs_char_scalligan_lloyd"} /></span>,
        "habitats": <span>167 billion (27 billion on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>Aegra and Selmira</span>,
        "g": <span>1.07 terrestrial</span>,
        "atmosphere": <span><span className="text-success">suitable for humans</span></span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span>Tellause</span>,
        "sector": <span><TextInlineLink name={"bs_space_ardimare_sector"} text={"Ardimare"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "area": <span>Auralis Nebula</span>,
        "imgs": [{ "short": "bs_wiki_helena_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Brief</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Helena-at-Tellause</span> is the largest and most influential world in the Ardimare Sector. Originally a contender for the capital of the Cydonian Priority, it lost out to Cydonia by a narrow margin. Despite this, Helena has flourished, becoming one of the most important cultural and academic centers in the galaxy. Its reputation as “Cydonia’s Twin” persists not only because of its physical resemblance to the capital but also because of the planet’s distinctive blend of elegance, history, and innovation.
                </p>
                <p className='card-text text-justify'>
                    The planet’s two moons, Aegra and Selmira, fill the sky with shifting silver and azure light, inspiring Helena’s many festivals tied to their cycles. These celestial rhythms have woven themselves into the cultural fabric of the planet, giving rise to countless poems, songs, and legends. The terraformed surface is a mirror of Earth’s serene landscapes — wheat fields, vineyards, and lush forests — all deliberately shaped to create an idyllic environment for its inhabitants. It is said that the air on Helena “tastes like nostalgia”, invoking distant memories of a simpler, ancient world.
                </p>
                <p className='card-text text-justify'>
                    The capital city, also named Helena, sprawls across the fertile plains. Its architecture blends white marble towers, domed cathedrals, and verdant parks, creating an atmosphere of tranquility and refinement. Here, public spaces serve not just as places of gathering, but as stages for the arts — opera houses, theaters, and concert halls line the city’s avenues, making it one of the galaxy’s most celebrated cultural hubs. The universities and research institutions of Helena attract some of the brightest minds, rivaling even those on Cydonia itself.
                </p>
                <h4 className="title-bg">Life on Helena and the Sector Beyond</h4>
                <p className='card-text text-justify'>
                    The people of Helena-at-Tellause are deeply proud of their heritage and see themselves as custodians of a bygone era, tasked with preserving the ideals that shaped the early Priority. Life here is characterized by a quiet dignity — manners, etiquette, and artistic expression are valued above all else. Public discussions are often subtle battles of wit, where every word and gesture holds meaning. Festivals celebrating the arts, the cycles of the moons, and historical anniversaries dominate the local calendar, drawing visitors from all over the Priority.
                </p>
                <p className='card-text text-justify'>
                    Beyond the capital, Ardimare’s smaller worlds continue to thrive, each offering a glimpse into a unique cultural niche. Some of these planets specialize in agriculture, cultivating rare wines and delicacies. Others are known for their artisan crafts — everything from glasswork to textiles, which are coveted throughout the galaxy. Helena’s influence extends across the sector, where its customs and traditions shape the way of life on even the most distant outposts.
                </p>
                <p className='card-text text-justify'>
                    Though the sector is peaceful, Ardimare’s distance from the galactic core gives it an air of detachment. Some see it as a place to escape the chaos of larger, more politically charged regions; others describe it as isolated and slow-moving, stuck in its own rhythms while the galaxy races forward. But for those who embrace its charm, Ardimare offers a refuge — an oasis of culture, tradition, and beauty, where life flows with the grace of old rituals.
                </p>
            </>
    }
}

