import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Majbory() {
    return {
        "date": <span>-</span>,
        "type": <span>yellow dwarf</span>,
        "stars": <span>1</span>,
        "planets": <span>12</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /></span>,
        "owned": <span><TextInlineLink name={"bs_comp_free_trade_league"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_samborians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "population": <span>25 billion</span>,
        "imgs": [{ "short": "bs_wiki_majbory_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Maybori</span> – star in the depths Sambora. A small yellow dwarf (sounds tautological, eh), long ago discovered by <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} />, who used it as a standard candle: the star's luminosity is very stable, it exhibits much less of the usual activity of stars in this class. Plus, Maybori was at the intersection of several routes along which nomads brought to <TextInlineLink name={"bs_planet_cydonia"} text={"Cydonia"} /> looted curiosities and new star maps for sale. So the choice of the star as a beacon was obvious.
                </p>
                <p className="card-text text-justify">
                    A few millennia later, when the Alamarsie left this part of Sambora in search of unexplored territories, the system was occupied first by peaceful farmer colonists and then by the pirates who took them under their guardianship. Since then Maybori is one of Free Trade League's most fortified bases.
                </p>
            </>
    }
}

