import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Alamarsie() {
    return {
        "homeworld": <span>none</span>,
        "state": <span>none</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "langs": <span><TextInlineLink name={"bs_lang_alamarsie"} text={"Alamarsie"} /></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_browdy_adam_1_png_200"} /></li>
            <li><ImgRound3EM img={"bs_char_kaory_nazil_1_jpg_200"} /></li>
        </ul>,
        "text":
            <>
                <h3 className="title-bg">Brief</h3>
                <p className="card-text text-justify">
                    <span className="strong">Alamarsie</span> are nomads who do not recognize life on planets and live in giant hive ships. The first Alamarsie were ordinary <TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} />, who left home in search of new planets, but were forced to grow
                    old and die on the ships. Sometimes entire generations would change before the machine reached a new
                    home, and cryo-sleep technology came much later than the first colonists departed.
                </p>
                <p className="card-text text-justify">
                    So often the new arrivals, after spending decades in space and never having seen a planet, simply
                    refused to go down there. They left the ship in orbit and used the world below as a source of raw
                    materials. Gradually new colonists were drawn there and brought advanced technology with them,
                    including
                    light engines. Thus the Alamarsie were able to travel relatively quickly from planet to planet if
                    the
                    previous one was resource-poor.
                </p>
                <p className="card-text text-justify">
                    Now they are traditionally regarded as bandits and avoided. Few nomads have managed to root among
                    the planetwalkers, even if they wanted to. Because of superstition, they remain outcasts for the
                    rest of their lives and are forced to break the law in order to survive - a vicious circle.
                </p>
                <h4 className="title-bg">Wayfarer’s Code</h4>
                <p className="card-text text-justify">
                    Alamarsie live by a loose but respected moral framework known as the Wayfarer’s Code, emphasizing independence, solidarity, and survival. The Code teaches that every individual is both captain and crew, responsible for their own choices but duty-bound to help fellow travelers in times of need. Their trust lies not in laws or governments, but in the reliability of a shipmate—or a ship’s hull. Loyalty is earned by deeds, not titles.
                </p>
                <p className="card-text text-justify">
                    They see planetary civilizations as stifling and corrupt, joking that “gravity makes people lazy,” while believing the harshness of space builds character. Even when interacting with outsiders, Alamarsie maintain a level of reserved caution. They do not lie easily, but they speak in riddles, hints, and half-truths, knowing that trust must be earned.
                </p>
                <h4 className="title-bg">Religion - Arthurianism</h4>
                <p className="card-text text-justify">
                    <TextInlineLink name={"bs_universe_religion_arthurians"} /> shapes not only their religious views but also their philosophy of life. Alamarsie interpret their struggles as part of an endless cosmic trial, reflecting Arthur Caliburn’s own mythic journey. Many rituals mimic the captain’s voyages—such as The Passing of the Beacon, a symbolic lighting of small flares when leaving a system, representing Arthur’s eternal quest for the unknown. They also celebrate “The Drift,” a holiday marking moments of personal or collective transition, honoring the fluidity of life in space.
                </p>
                <p className="card-text text-justify">
                    Arthur’s diaries remain a source of wisdom, and important decisions—whether personal or communal—are often justified by referencing passages from the ancient texts, sometimes cryptically:
                </p>
                <blockquote>
                    <p className="card-text text-justify">
                        Only a fool returns to port with an empty ship.
                    </p>
                </blockquote>
                <blockquote>
                    <p className="card-text text-justify">
                        Listen for whispers in the silence, for only small-minded shout in the void.
                    </p>
                </blockquote>
                <h4 className="title-bg">Hive Ship Traditions and Beliefs</h4>
                <p className="card-text text-justify">
                    Each hive ship operates as an independent microcosm with its own quirks and practices, though certain traditions are shared across all vessels.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">The Rite of Metal and Stars</span>: When someone dies aboard a hive, their body is not ejected into space until it has been “honored.” Friends and family sing ancient songs while parts of the deceased’s equipment—tools, gadgets, or even pieces of their armor—are dismantled and shared among the living, believing that their spirit will guide future journeys.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Singing the Hull</span>: Mechanics and engineers hum or sing to the machinery as they work. They believe this keeps the ship’s “spirit” at peace, preventing malfunctions. If a machine fails repeatedly, they assume it is insulted and needs an apology ritual—typically a simple offering of liquid nitrogen or lubricants to the engine room.
                </p>
                <p className="card-text text-justify">
                    Even though Arthurianism is rooted in semi-historical documents, Alamarsie folklore teems with mythological creatures. Many believe that these ancient space monsters — <TextInlineLink name={"bs_creature_cyllopus"} />, the <TextInlineLink name={"bs_creature_hydra"} text={"Steel Hydra"} />, and <TextInlineLink name={"bs_creature_hartrhon"} /> — still exist, lurking in the depths of unexplored space. Some captains refuse to jump into certain systems if their ionic trails resemble the “marks of a Hydra.”
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Hydra-Warding Sigils</span>: Alamarsie carve intricate hydra symbols on ships’ doors or control panels to ward off bad luck and cosmic predators. Even the most skeptical engineers will grumble if someone forgets to trace the protective pattern before a jump.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">The “Sun Fish” Ritual</span>: Before an important flight, a captain might extinguish all lights on the bridge for ten seconds—representing Cyllopus hunting in the dark. Afterward, they light a flare or beacon, symbolizing survival and a clear path ahead.
                </p>
                <p className="card-text text-justify">
                    Alamarsie ships often fly flags or paint symbolic murals on their hulls, reflecting their allegiance to Arthur’s ideals or marking personal achievements. Some vessels display “stars of exile”—patterns of dots representing the number of systems the ship has visited—while others sport the Hydra as a badge of defiance. These symbols are a subtle way of asserting pride in their independence and hinting at the many places they’ve traveled.
                </p>
                <h4 className="title-bg">Isolation and Relations with Outsiders</h4>
                <p className="card-text text-justify">
                    Alamarsie maintain a deep-rooted distrust toward “planetwalkers” and avoid prolonged contact with outsiders. Their long history of being misunderstood and marginalized reinforces their sense of superiority over stationary societies. When necessary, they trade with planets only for essentials—often negotiating from orbit—and prefer barter over currency. Attempts to integrate into planetary cultures usually end poorly, as Alamarsie find the restrictions and rules of planet life oppressive and ridiculous.
                </p>
                <p className="card-text text-justify">
                    Though outsiders often see the Alamarsie as pirates, they view their actions differently. In their eyes, raiding is not criminal—it is survival in a universe that has repeatedly cast them aside. They target supply convoys and abandoned vessels, but rarely engage in violence unless provoked. For this reason, they have become experts in hit-and-run tactics, using speed and cunning rather than brute force.
                </p>
                <h4 className="title-bg">Identity</h4>
                <p className="card-text text-justify">
                    The Alamarsie have carved out a unique place in galactic history: they are the first humans to fully embrace the void of space as their home. This choice, made millennia ago, set them apart from all other human civilizations, leaving them in a state of constant movement and isolation. To the Alamarsie, freedom is found in drifting among the stars, unbound by planetary borders or political allegiances. They view themselves as custodians of the galaxy’s forgotten corners, explorers of the unknown, and survivors in a hostile universe.
                </p>
                <p className="card-text text-justify">
                    Though labeled as bandits or outcasts, they see themselves as something more profound—torchbearers of humanity’s first true journey into the cosmos. To live among the stars is to embrace both risk and reward, and the Alamarsie navigate this balance with an almost religious devotion to their ancestors’ ideals. Their hive ships are both home and temple, their journeys both pilgrimage and conquest. And wherever the Alamarsie go, they carry with them the enduring belief that the stars hold not just dangers, but endless possibility.
                </p>
                <p className="card-text text-justify">
                    These nuances give the Alamarsie a rich, multi-dimensional culture rooted in survival, mysticism, and an unbreakable connection to the void. They’re proud but wary, pragmatic yet deeply superstitious—a people forever shaped by the choices their ancestors made when they first left Earth behind.
                </p>
                <h4 className="title-bg">The Ritual of Exile</h4>
                <p className="card-text text-justify">
                    Exile is one of the gravest punishments in Alamarsie society. While most issues are resolved through mediation or personal duels, exile is reserved for acts that threaten the survival or unity of the fleet—such as betrayal, sabotage, theft of essential resources, or cowardice during a crisis. The Wayfarer’s Code demands that all members of a hive contribute to its wellbeing; those who break this code are seen as both dangerous and dishonorable.
                </p>
                <p className="card-text text-justify">
                    The Ritual of Exile, known as “The Severing”, is a somber and symbolic ceremony. The condemned is stripped of their insignias and tools—objects symbolizing their role on the ship—and forced to walk across the ship’s “cold corridor”, an unlit passage leading to the airlock or docking bay. Elders read a passage from Arthur’s diaries:
                </p>
                <blockquote>
                    <p className="card-text text-justify">
                        A ship is only as strong as its weakest link. A rusted bolt can kill a vessel.
                    </p>
                </blockquote>
                <p className="card-text text-justify">
                    Once they pass the cold corridor, the exiled individual is placed in a small shuttle or escape pod, left with only minimal supplies. They are given a choice: either make their way to a nearby planet or system, or drift into the void. No one from the hive is allowed to speak to them again—their name is “cut from the stars”, meaning it is removed from the hive’s official records.
                </p>
                <p className="card-text text-justify">
                    For many Alamarsie, exile is a fate worse than death. Stripped of the community’s protection and cut off from the sacred journey of the fleet, they are considered spiritually adrift. Even if they survive and integrate into planetwalker societies, they carry a stigma that marks them as traitors. It’s believed that exiles are cursed to wander eternally, never finding peace in life or death, and those who encounter them are warned to avoid them lest the curse spread.
                </p>
                <p className="card-text text-justify">
                    Some exiles become vengeful pirates, leading attacks on their former fleets. Others try to prove their worth in the hope of being remembered—though once cut, no one has ever been reintegrated into the hive. This, for example, happened to Aniraadha hive ship, which was destroyed by the <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Feathered Serpent Syndicate"} /> with the help of exiled Alamarsie.
                </p>
                <h4 className="title-bg">Alamarsie Language</h4>
                <p className="card-text text-justify">
                    The Alamarsie language is as unique as the people who speak it—a fluid, evolving tongue shaped by centuries of isolation and interstellar travel. It is a creole that developed from early Earth languages, with English, Arabic and Mandarin as its foundation, though it has drifted so far from those roots that only trained linguists can recognize the original influences. Its syntax is flexible, making it well-suited for piloting commands and technical jargon, but it is also poetic, filled with idioms drawn from space travel.
                </p>
                <p className="card-text text-justify">
                    For example, the phrase “to chase a fading star” refers to futile efforts, while “silence between jumps” is used to describe uneasy peace between conflicts. Another common phrase, “turn the air,” means to prepare for a sudden change, referencing the need to shift life support systems mid-flight.
                </p>
                <p className="card-text text-justify">
                    Alamarsie speech also incorporates clicks, whistles, and non-verbal cues—a legacy from generations of life aboard noisy ships, where verbal communication is sometimes difficult. This gives the language a rhythmic, almost musical quality. Outsiders struggle to learn Alamarsie, especially since pronunciation and meaning can change based on tone, hand gestures, or the speaker’s expression.
                </p>
                <p className="card-text text-justify">
                    The Alamarsie value linguistic creativity. New words and idioms are coined regularly, and storytellers, engineers, and pilots take pride in inventing clever phrases or jokes. However, the most sacred words are reserved for their ships and the stars—never spoken lightly and always with reverence. Each hive also maintains its own set of secret phrases, used only among the crew, further deepening the language’s layers and reinforcing their sense of unity and identity.
                </p>
                <p className="card-text text-justify">
                    These additions paint the Alamarsie as a culture deeply shaped by isolation, unity, and ritual. Their practices—especially exile—reflect their harsh environment, where survival depends on loyalty and trust, and their language reflects both the challenges and poetry of life in the stars.
                </p>
                <h4 className="title-bg">Key Words and Phrases in Alamarsie</h4>
                <p className="card-text text-justify">
                    <span className="strong">“To chase a fading star”</span> (“Yarel’ytha” (ya-REHL-ee-thah)):
                    <br />
                    Literal meaning: “Chasing what dims in the distance.”
                    <br />
                    Used to describe futile efforts, unattainable dreams, or wasted pursuits. If spoken with a wistful tone, it can imply melancholy or nostalgia for a lost opportunity. If said with a sharp, dismissive click, it means someone is being foolish or wasting time.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">“Silence between jumps”</span> (“Navar’sin” (nah-VAHR-seen)):
                    <br />
                    Literal meaning: “The quiet before the next breach.”
                    <br />
                    This phrase suggests tension or unease before action—whether a physical jump through space or an impending conflict. When whispered or spoken slowly, it hints at dread. In a casual or sarcastic tone, it may also refer to temporary peace that won’t last.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">“Turn the air”</span> (“Siva’tal” (SEE-vah-tahl)):
                    <br />
                    Literal meaning: “Flip the air currents.”
                    <br />
                    Refers to sudden shifts or preparations for change, often used when warning someone to adapt quickly. If spoken sharply, it can be a command to brace for the unexpected. When used lightly, it hints at playful unpredictability, as if saying, “Keep up, or you’ll be left behind.”
                </p>
                <p className="card-text text-justify">
                    <span className="strong">“Friend”</span> (“Kiraal” (KEE-rahl):):
                    <br />
                    An affectionate term, used only among trusted companions. Its meaning shifts depending on tone: said softly, it conveys warmth and loyalty; spoken louder, it can suggest camaraderie or playful teasing. A quick gesture with the hand can signal whether it’s meant as genuine affection or a challenge, like saying “friend, for now.”
                </p>
                <p className="card-text text-justify">
                    <span className="strong">“Outsider”</span> (“Zaya” (ZAH-yah):):
                    <br />
                    Carries undertones of exclusion and distance. The meaning can shift with subtle changes in inflection—when said with a neutral or flat tone, it simply describes someone outside the group. However, spoken with an edge or accompanied by a certain flick of the hand, it becomes an insult, implying someone unwelcome or dangerous.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">“Warning”</span> (“Reth’kaa” (RETH-kah):):
                    <br />
                    A sharp, direct word that grabs attention. Traditionally followed by a sharp gesture (like a clap or a snap of fingers) to reinforce urgency. If spoken slowly, it suggests deep caution, like a serious but calm alert. When shouted, it signals immediate danger. Pilots sometimes use it casually among themselves as shorthand for an upcoming problem.
                </p>
            </>
    }
}
