import { TextInlineLink } from "../../../../../components/cms/wiki_functions";

export function Brienne_de_estrada() {
    return {
        "name": "Brianna De Estrada",
        "fullname": <span>Brianna De Estrada</span>,
        "born": <span>~5500 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>93</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_earth"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_earthlings"} text={"Earthling"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span>ship engineer</span>,
        "status": <span className={"text-danger"}>deceased</span>,
        "imgs": [
            { "short": "bs_char_brienne_de_estrada_1_jpg_500", "big": "bs_char_brienne_de_estrada_1_jpg_full" },
        ],
        "pint": "",
        "text":
            <>
                <h3 className="title-bg">Historical persona</h3>
                <p className="card-text text-justify">
                    <span className="strong">Brianna</span> was born and raised on <TextInlineLink name={"bs_planet_earth"} text={"Earth"} />, some 5,500 years before the events of the <TextInlineLink name={"bs_series"} text={"Cydonian Sun"} />. According to her diaries, she always dreamed of flying away from home and seeing other worlds. So she trained as a navigational systems engineer and left the planet at the age of 23 on the colonial ship Jupiter-12.
                </p>
                <p className="card-text text-justify">
                    The machine flew from Earth to <TextInlineLink name={"bs_planet_cydonia"} text={"Cydonia"} /> and was one of the first to use the full-cycle cryo-sleep method: the crew and colonists were able to spend most of the journey in anabiosis. However, seven years before arrival, part of the crew was awakened by the onboard computer to repair damaged systems. When the malfunctions were repaired, it was discovered that the power surges had burned out important nodes responsible for the cryo-capsule automatics. There was no guarantee that even after repairs it would be possible to wake people up at the right moment - the ship could be stuck at its destination for thousands of years without bringing the population back to life.
                </p>
                <p className="card-text text-justify">
                    It was decided to leave two engineers awake, capable of manually triggering the awakening process upon arrival on Cydonia. Brianna and one other person, who remained anonymous, volunteered for the mission and spent years in the company of each other and the artificial intelligence. The machine lectured people and taught them how to pilot on simulators, just in case.
                </p>
                <p className="card-text text-justify">
                    In gratitude for the engineers giving their whole 7 years of their lives for the entire crew, the captain allowed them to bring the ship themselves to the right point in the orbit of the new colony. This violates the basic rules of space travel, but people were willing to risk it for a nice gesture.
                </p>
                <p className="card-text text-justify">
                    The moment when Brianna gave the ship the commands Stop and Hold Orbit over the right part of the Cydonian surface is considered the founding of the colony on Cydonia. It is a pity that her colleague is not remembered by history.
                </p>
                <p className="card-text text-justify">
                    The <TextInlineLink name={"bs_space_brienne_cluster"} text={"Brianne Cluster"} /> is named after Brianna.
                </p>
            </>
    }

}