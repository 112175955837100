import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Nyorun_dreams() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "is_a_ship": true,
        "is_a_class": false,
        "is_an_article": false,
        "class": <span>heavy <TextInlineLink name={"bs_ships_ship_classes"} text={"battleship"} /></span>,
        "type": <span><TextInlineLink name={"bs_ships_grime"} /></span>,
        "owned": <span><TextInlineLink
            name={"bs_state_the_great_monarchy"} />, <TextInlineLink name={"bs_popups_army_echelon_of_the_fives"} text={"Echelon of The Fives"} />, 1st <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> Defense Fleet</span>,
        "size": <span>4800x1200x1200m</span>,
        "captain": <>-</>,
        "conflicts": <span><TextInlineLink name={"bs_timeline_galactic_war"} text={"First Galactic War"} />, <TextInlineLink
            name={"bs_timeline_the_shark_revolt"} text={"Shark Rebellion"} /></span>,
        "status": <span>destroyed during the <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Second Spider Demarche"} /></span>,
        "weapons": <span><TextInlineLink name={"bs_weaponry_railgun"} text={"railgun"} /> batteries (60 to 500 caliber), guided and unguided missile batteries, <TextInlineLink name={"bs_weaponry_magnetic_cannon"} text={"magnetrons"} /> of all caliber, electronic warfare systems</span>,
        "defence": <span>multi-layer <TextInlineLink name={"bs_tech_space_steel"} text={"Space steel"} /> armor with 32% <TextInlineLink name={"bs_tech_anzuritum"} text={"Anzurite"} />, emissive anti-missile barriers, EW systems (against guidance and navigation), active missile and rail protection</span>,
        "text":
            <>
                <h3 className="title-bg">About the ship</h3>
                <p className="card-text text-justify">
                    <span className="strong">SSM HC-F 2212-08 Njörun’s dreams</span> is the flagship of the 1st Earth Defense Fleet and the mobile headquarters of the Echelon of The Fives. The machine entered service at the same time <TextInlineLink name={"bs_char_mereglis_sonya"} text={"Sonya Mergelis"} /> was appointed Grand Admiral of the Fives (read: Commander of the Echelon) and went through the entire Galactic under her command. During this time, the Dreams managed to commit quite a few war crimes, such as the genocide on <TextInlineLink name={"bs_planet_esghariot_en_nuvnish"} text={"Esghariot"} /> - Mergelis was known for her extreme cruelty and stunning devotion to the Earth regime.

                </p>
                <p className="card-text text-justify">
                    Dreams was destroyed during the Battle of Coal, in the final days of the Mihjelm Rebellion. The Grand Admiral ordered the AI to self-destruct when the crew left the ship to avoid capture by the rebels.
                </p>
            </>
    }
}
