import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function CanBeucanGaereas() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "is_not_ready": false,
        "name": "Gary Baukan",
        "fullname": <span>Gary <small>'Shut up already', 'All-Terrain Professional'</small> Baukan</span>,
        "born": <span>54 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>53</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_earth"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_earthlings"} text={"Earthling"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "relatives": <span>maybe there are</span>,
        "work": <span>All-Terrain Professional</span>,
        "imgs": [
            { "short": "bs_char_can_beucan_gaereas_1_png_500", "big": "bs_char_can_beucan_gaereas_1_jpg_full" },
            { "short": "wide_profile_specialist_jpg_200", "big": "wide_profile_specialist_jpg" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
            ],
        "text":
            <>
                <img style={{ "maxWidth": "300px", "float": "right" }} alt="Gary Baukan"
                    src={imgs["wide_profile_specialist_png_300"].link} fetchpriority="low" />

                <h3 className="title-bg">Brief</h3>
                <p className="card-text text-justify">
                    <span className="strong">Gaereas Baukan</span>, better known as <span className="strong">Gary</span>, is an Earth-born mercenary whose life journey took him from brutal underground fights to the Bonecollectors - a group of elite mercenaries specializing in extracting wealthy clients from life-threatening situations. Now, <span className="strong">Gary</span> works as a reclaimer, recovering stolen possessions from pirates and other criminals for the galactic elite under the employ of the eccentric former-admiral, Ivar de Karma.
                </p>
                <h4 className="title-bg">Early Life & Origins</h4>
                <p className="card-text text-justify">
                    <span className="strong">Gary’s</span> beginnings were marked by hardship and survival. Born during Earth’s chaotic pre-collapse era, he grew up in the underground world of illegal bare-knuckle fights. Relying on brute force and grit, <span className="strong">Gary</span> made a name for himself in these circuits, carrying the scars — both literal and emotional — to this day.
                </p>
                <h4 className="title-bg">Bonecollectors & Shift to Reclaimer Work</h4>
                <p className="card-text text-justify">
                    After years of pit fighting, <span className="strong">Gary</span> left the ring and joined the Bonecollectors, using his survival skills to rescue wealthy clients from pirate ambushes and hostile locals on forgotten worlds. Though skilled mostly in rail combat and close-contact fights, <span className="strong">Gary</span> eventually grew weary of mercenary life. Seeking a change, he left the Bonecollectors and took up work with the infamous Ivar De Karma — a brilliant but unhinged former admiral with a talent for wild, convoluted schemes.
                </p>
                <p className="card-text text-justify">
                    As a reclaimer, <span className="strong">Gary</span> specialized in retrieving high-value items stolen by pirates, often through dangerous and morally gray methods. Although he didn’t trust <span className="strong">Ivar</span> at first, the two gradually formed an unlikely friendship. <span className="strong">Gary</span> sees Ivar as something of a genius, marveling at his ability to craft outrageous plans that somehow always succeed against all odds.
                </p>
                <h4 className="title-bg">Personality</h4>
                <p className="card-text text-justify">
                    <span className="strong">Gary’s</span> dry humor and laid-back demeanor conceal the instincts of a survivor hardened by a lifetime of fighting. While he often plays the fool with his offbeat jokes, he is an extremely competent operative. His approach to problems is pragmatic and flexible; if brute force doesn’t work, he’s perfectly ready to try it again. And again.
                </p>
                <p className="card-text text-justify">
                    Baukan isn’t quick to trust and keeps his cards close to his chest, but those who earn his loyalty find him to be a dependable ally. For <span className="strong">Gary</span>, work isn’t just a paycheck—it’s about surviving one more day, ideally with a good story and a drink at the end of it. His relationships are few but genuine, with <span className="strong">Ivar de Karma</span> being one of the rare individuals who’ve cracked his armor.
                </p>
                <h4 className="title-bg">Notable facts</h4>
                <p className="card-text text-justify">
                    Unapologetically blunt, often making people uncomfortable with his humor.
                </p>
                <p className="card-text text-justify">
                    Holds a deep appreciation for people with insane ideas and plans that actually work—like <span className="strong">Ivar</span>.
                </p>
                <p className="card-text text-justify">
                    Despite his jaded outlook, he’s fiercely protective of those he considers friends. Even if regular conversation with him is like pulling teeth.
                </p>
                <div className="bg-image"
                    style={{ "padding": "1em", "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                </div>
                <h4 className="title-bg">
                    <span className="strong"><TextInlineLink name={"book_cydonian_girl"} />:</span>
                </h4>
                <p className="card-text text-justify">
                    Somehow, <span className="strong">Gary</span> found himself tangled up in a full-blown revolution against the Monarchy—and, just for good measure, the deaths of two Agathon agents. Life suddenly got a lot more interesting, though it comes with a catch: he’s going to need eyes in the back of his head from now on.
                </p>
            </>
    }
}
