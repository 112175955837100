export function Antimatter() {
    return {
        "science": <span>quantum physics</span>,
        "use": <span>power generation, weapons</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Antimatter</span> is matter's evil twin. When the two collide, they annihilate each other, unleashing a burst of radioactive light. Simple, deadly, and not to be messed with
                </p>
            </>
    }
}
