import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Exile_1() {

    return {
        "date": <span>125 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>3 million</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>3</span>,
        "g": <span>1.02 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span><TextInlineLink name={"bs_star_last_exodus"} text={"Last Exodus"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_fiorella_sector"} text={"Fiorella"} /></span>,
        "type": <span>earth-like, colonizers' logistics center</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "imgs": [{ "short": "bs_wiki_exile_1_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Exodus-1</span> is a transshipment base for colonists sent by the Priority to Sambora and beyond (sometimes). The Vitruvians kidnapped about 3 million people here and so far this is the last such incident documented.
                </p>
            </>
    }
}

