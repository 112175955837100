import wiki from "../cms/wiki.json";
import imgs from "../cms/images_repo.json";
import { TextInlineLink } from "../cms/wiki_functions";
import parse from 'html-react-parser';

let planets = [
    {
        "link": wiki["bs_planet_tamanrasett"].link,
        "picture": imgs["tamanrasett_500"].link,
        "text": <span>{wiki["bs_planet_tamanrasett"].name}</span>,
        "text_big": <span>{parse(wiki["bs_planet_tamanrasett"].popup)}</span>
    },
    {
        "link": wiki["bs_planet_arville"].link,
        "picture": imgs["arville_500"].link,
        "text": <span>{wiki["bs_planet_arville"].name}</span>,
        "text_big": <span>{parse(wiki["bs_planet_arville"].popup)}</span>
    },
    {
        "link": wiki["bs_planet_avvaline"].link,
        "picture": imgs["avvaline_500"].link,
        "text": <span>{wiki["bs_planet_avvaline"].name}</span>,
        "text_big": <span>{parse(wiki["bs_planet_avvaline"].popup)}</span>
    },
    {
        "link": wiki["bs_planet_cydonia"].link,
        "picture": imgs["cydonia_500"].link,
        "text": <span>{wiki["bs_planet_cydonia"].name}</span>,
        "text_big": <span>{parse(wiki["bs_planet_cydonia"].popup)}</span>
    },
    {
        "link": wiki["bs_planet_new_havana"].link,
        "picture": imgs["new_havana_500"].link,
        "text": <span>{wiki["bs_planet_new_havana"].name}</span>,
        "text_big": <span>{parse(wiki["bs_planet_new_havana"].popup)}</span>
    },
    {
        "link": wiki["bs_planet_valoria"].link,
        "picture": imgs["valoria_500"].link,
        "text": <span>{wiki["bs_planet_valoria"].name}</span>,
        "text_big": <span>{parse(wiki["bs_planet_valoria"].popup)}</span>
    }
]

export function BSRandomWikiPicturePlanet() {
    let pict = {}
    let pict_num = Math.floor(Math.random() * planets.length)
    pict = planets[pict_num]

    return (<>
        <div className="card">
            <a href={pict.link}>
                <div className=" card-img-top" style={{ "width": "100%", "height": "100%", "margin": "auto" }}>
                    <div className="bg-image" style={{ "maxWidth": "100%", "height": "100%", "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                        <img style={{ "width": "100%" }} src={pict.picture} alt={pict.text} />
                    </div>
                </div>
            </a>
            <div className="card-body">
                <p className="card-text">{pict.text_big}</p>
            </div>
        </div>
    </>
    )
}


let characters = [
    {
        "link": wiki["bs_char_de_levine_rosalie"].link,
        "picture": imgs["bs_char_de_levine_rosalie_1_png_500_t"].link,
        "text": <span>{wiki["bs_char_de_levine_rosalie"].name}</span>,
        "text_big": <span>{parse(wiki["bs_char_de_levine_rosalie"].popup)}</span>
    },
    {
        "link": wiki["bs_char_ursula_adams"].link,
        "picture": imgs["bs_char_ursula_adams_1_jpg_500"].link,
        "text": <span>{wiki["bs_char_ursula_adams"].name}</span>,
        "text_big": <span>{parse(wiki["bs_char_ursula_adams"].popup)}</span>
    },
    {
        "link": wiki["bs_char_browdy_adam"].link,
        "picture": imgs["bs_char_browdy_adam_1_png_500"].link,
        "text": <span>{wiki["bs_char_browdy_adam"].name}</span>,
        "text_big": <span>{parse(wiki["bs_char_browdy_adam"].popup)}</span>
    },
    {
        "link": wiki["bs_char_can_beucan_gaereas"].link,
        "picture": imgs["bs_char_can_beucan_gaereas_1_png_500"].link,
        "text": <span>{wiki["bs_char_can_beucan_gaereas"].name}</span>,
        "text_big": <span>{parse(wiki["bs_char_can_beucan_gaereas"].popup)}</span>
    },
    {
        "link": wiki["bs_char_de_karma_ivar"].link,
        "picture": imgs["bs_char_de_karma_ivar_1_png_500"].link,
        "text": <span>{wiki["bs_char_de_karma_ivar"].name}</span>,
        "text_big": <span>{parse(wiki["bs_char_de_karma_ivar"].popup)}</span>
    },
    {
        "link": wiki["bs_char_esora_eve"].link,
        "picture": imgs["bs_char_esora_eve_1_png_500"].link,
        "text": <span>{wiki["bs_char_esora_eve"].name}</span>,
        "text_big": <span>{parse(wiki["bs_char_esora_eve"].popup)}</span>
    },
    {
        "link": wiki["bs_char_hartrey_kellays"].link,
        "picture": imgs["bs_char_hartrey_kellays_1_png_500"].link,
        "text": <span>{wiki["bs_char_hartrey_kellays"].name}</span>,
        "text_big": <span>{parse(wiki["bs_char_hartrey_kellays"].popup)}</span>
    },
    {
        "link": wiki["bs_char_skarsgard_oliver"].link,
        "picture": imgs["bs_char_skarsgard_oliver_1_png_500"].link,
        "text": <span>{wiki["bs_char_skarsgard_oliver"].name}</span>,
        "text_big": <span><strong>Oliver Skarsgård</strong> - head of the Strike Wing of Her Majesty Queen <TextInlineLink name={"bs_char_van_gloria_adel"} text={"Adele Van Gloria"} />'s <TextInlineLink name={"bs_comp_esquirete"} text={"Esquirete"} /> at the time of <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} /></span>
    },
    {
        "link": wiki["bs_char_the_whip"].link,
        "picture": imgs["bs_char_the_whip_1_png_500"].link,
        "text": <span>{wiki["bs_char_the_whip"].name}</span>,
        "text_big": <span>{parse(wiki["bs_char_the_whip"].popup)}</span>
    }
]

export function BSRandomWikiPictureCharacter() {
    let pict = {}
    let pict_num = Math.floor(Math.random() * characters.length)
    pict = characters[pict_num]

    return (<>
        <div className="card">
            <a href={pict.link}>
                <div className=" card-img-top" style={{ "width": "100%", "height": "100%", "margin": "auto" }}>
                    <div className="bg-image" style={{ "maxWidth": "100%", "height": "100%", "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                        <img style={{ "width": "100%" }} src={pict.picture} alt={pict.text} />
                    </div>
                </div>
            </a>
            <div className="card-body">
                <p className="card-text">{pict.text_big}</p>
            </div>
        </div>
    </>
    )
}
