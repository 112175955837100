import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function The_night_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"cosmographic unit"} /></span>,
        "owned": <span>questionable</span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_the_night"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_earthlings"} /></span>,

        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Night Sector</span> is a piece of Old Space, next to the <TextInlineLink name={"bs_space_sector_zero"} text={"Sector Zero"} />. There are a lot of worlds that were inhabited at the beginning of the light age. They are full of traces of the first settlers and unskilled attempts at terraforming, most of which failed and the settlers died, but that's another story.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">
                        After <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />:
                    </span>
                </p>
                <p className="card-text text-justify">
                    The Spider Rebellion resulted in the administration of the sector on the Night (<TextInlineLink name={"bs_planet_the_night"} text={"Coal Njörun"} />) being paralyzed. Most of the planets declared their desire for a referendum on independence from <TextInlineLink name={"bs_planet_earth"} text={"Earth"} />, and since then almost all of the Night Worlds have been in legal limbo.
                </p>
            </>
    }
}

