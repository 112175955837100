import React, { useEffect } from 'react';
import { SearchForm } from "../components/cms/wiki_functions";
import wiki from '../components/cms/wiki.json';

export const Search = () => {
    useEffect(() => {
        document.title = "Search || Cydonian Sun";
    }, []);

    return (
        <>
            <div className="row" key="crumbs">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item" aria-current="page"><a href={wiki["page_main"].link}>Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Saerch</li>
                    </ol>
                </nav>
            </div>
            <div key="mainRow" className="row">
                <div className="col-lg-8">
                    <h1>Search the site<small>(books, characters, world of <i>Cydonian sun</i>)</small></h1>
                    <SearchForm />
                </div>
                <div className='col-lg-4'>
                    <div className="well">
                        <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_glossary"].link}>Wiki</a></div>
                        <hr />
                        <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_timeline"].link}>History</a></div>
                        <hr />
                        <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_gallery"].link}>Gallery</a></div>
                    </div>
                </div>
            </div>
        </>
    );
};