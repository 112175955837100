import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function Nazil() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "name": "Nazil Kaori",
        "fullname": <span>Nazil <small>Nazil(yea)</small> Kaori</span>,
        "born": <span>59 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>58</span>,
        "homeworld": <span><TextInlineLink name={"bs_tech_hiveship"} text={"Tomirida hive"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_alamarsie"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "status": <><span className={"text-danger"}>deceased</span></>,
        "work": <span><TextInlineLink name={"bs_comp_cms"}
            text={"1st Diversionary Directorate"} /> of TMS</span>,
        "imgs": [
            { "short": "bs_char_kaory_nazil_1_jpg_500", "big": "bs_char_kaory_nazil_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify">
                    <span className="strong">Nazil</span> was born and raised on a hiveship. His parents were pilots by birthright and Nazil inherited the necessary genes - only Alamarsie with the right combinations  are taken as pilots. He was educated in engineering, <TextInlineLink name={"bs_science_astromechanics"} text={"Astromechanics"} />, and other exact sciences, which made him a brilliant ‘flyer’ and technician.
                </p>
                <p className="card-text text-justify">
                    However, the lad did not want to live as a smuggler or even more so as a pirate in a semi-legal migratory fleet. Even before he was of age, he asked to join a merchant expedition to <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} />, and there he escaped from his comrades. The poor guy spent several weeks on the streets of the metropolis: confused, unprepared for life on a planet in an unfamiliar environment. Then he stumbled upon an advertisement for the Agathon fleet.
                </p>
                <p className="card-text text-justify">
                    Ragged and hungry, Nazil found a recruiting station and told of his situation. The officers had seldom met an Alamarsie and did not immediately believe that this nomad had really come willingly, and with good intentions. But after the first test on the simulator he was admitted to the flying academy without examination, ignoring the fact that they were supposed to recruit sailors, not pilots.
                </p>
                <div className="iteresting-fact">
                    <p className="card-text text-justify">
                        Interesting fact: Nazil himself says that he was supposedly expelled from the clan. Few people actually know that the Alamarsie escaped on his own. The reason is that for an Alamarsie to voluntarily leave the fleet is the worst disgrace. One who has been kicked out for misdeeds can return if he proves that he has corrected himself. Those who run away on their own will never set foot aboard a hive again. Any Alamarsie hive in the galaxy.
                    </p>
                </div>
                <p className="card-text text-justify">
                    Nazil's <TextPopup
                        name={"bs_popups_tech_nootrop"} text={"nootropic-pumped"} /> brain easily overcame most of the training and showed excellent results. Even before graduating from the academy he was recruited to the TMS, where he was assigned to the 1st Diversionary Directorate. There, on one of his many deployments, he met <TextInlineLink name={"bs_char_esora_eve"} text={"Eve Esora"} /> and was her partner for several years.
                </p>
                <p className="card-text text-justify">
                    The end of Nazil's career came with the events of the <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />: he and Eve were sent to <TextInlineLink name={"bs_planet_michelm"} text={"Mihjelm"} /> to help the rebels. Nasil was barely involved in the mission, working as a back-up pilot and assistant to <TextInlineLink name={"bs_char_salibar_kardenos"} text={"Colonel Cardenos"} />. And 13 months <TextInlineLink name={"bs_timeline"} text={"before Tamanrasett"} />, he was shot and killed by <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar De Karma"} /> on <TextInlineLink name={"bs_planet_earth"} text={"Earth"} />, in the last days of the <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Great Monarchy"} />, whose disintegration he had a hand in part.
                </p>
            </>
    }
}
