import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function SectorFiorella() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"cosmographic unit"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "capital": <span>Caerula</span>,
        "known_worlds": <span>Lunavira, Fiorenzia, Amalthea</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} />, <TextPopup name={"bs_popups_people_vereni"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,

        "text":
            <>
                <h3 className="title-bg">Brief look</h3>
                <p className="card-text text-justify">
                    The <span className="strong">Fiorella Sector</span> is one of the most picturesque and culturally rich areas of the Cydonian Priority, but it sits at a delicate crossroads - where the vast borders of the Priority brush against the unpredictable expanse of the Free Worlds. As a frontier region, Fiorella embodies both elegance and tension, where artful cities thrive amid the ever-present threat of political upheaval.
                </p>
                <p className="card-text text-justify">
                    The capital of the Fiorella Sector is Caerula(from the word “caeruleo,” meaning sky - blue or serene), a planet famed for its sapphire oceans, lush archipelagos, and elegant floating cities. Its urban centers were designed to harmonize with nature, blending marble - like architecture with parks, canals, and monumental public squares. Caerula is known as a place where diplomacy blooms as richly as its gardens, often hosting critical negotiations with Free World envoys.
                </p>
                <h3 className="title-bg">Most famous places</h3>
                <p className="card-text text-justify">
                    <span className="strong">Lunavira</span> is a moon-like planet with pale landscapes and deep ocean trenches. Known for its tranquil beauty, Lunavira has become a retreat for artists and aristocrats seeking isolation. However, it also harbors hidden pirate enclaves in its remote archipelagos.
                </p>
                <p className="card-text text-justify">
                    Its eflective crystal forests that shimmer in the light of nearby stars, and the moon’s eerie, silent beauty, have inspired countless poets and painters.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Fiorenzia</span> is the industrial heart of the Fiorella Sector. Fiorenzia’s shipyards produce both luxury yachts for the wealthy and warships for the Priority’s navy. The planet’s mix of industry and culture gives it a gritty, avant-garde energy, with street performers and poets mingling among factory workers. The Docks of Oriano, a sprawling orbital station serving as a hub for trade and defense, is a common sight in Fiorenzia’s skies.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Amalthea</span> is a vast water world with floating cities tethered to colossal kelp forests. Its population lives in harmony with the ocean ecosystem, making it a leader in marine biotechnology. Amalthea’s aquanauts serve as both researchers and naval scouts.
                </p>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    Fiorella was initially a collection of small colonies struggling to survive in a politically volatile part of space. However, the strategic value of its location soon became clear, as it offered a trade corridor between the Free Worlds and the interior territories of the Priority. To stabilize the region, the Priority invested heavily in infrastructure, transforming what had been backwater colonies into wealthy, well - defended planets.
                </p>
                <p className="card-text text-justify">
                    Over time, Fiorella earned a reputation for being a region where high culture and politics entwined. Art schools, opera houses, and diplomatic academies flourished here. This cultural blossoming helped forge a unique identity, setting Fiorella apart from other sectors by emphasizing diplomacy, aesthetics, and soft power.
                </p>
                <p className="card-text text-justify">
                    However, the sector’s proximity to the <TextInlineLink name={"bs_state_free_worlds"} text={"Free Worlds"} /> has not always been peaceful. Though the Cydonians maintain strong defenses along the border, skirmishes, pirate raids, and ideological clashes remain frequent. The diplomatic elite of Caerula play a delicate game, balancing loyalty to the central government with pragmatic compromises to keep trade flowing and conflicts minimal.
                </p>
                <h3 className="title-bg">Aesthetic and Atmosphere</h3>
                <p className="card-text text-justify">
                    The sector feels like a blend of a futuristic Venice and Renaissance Florence, but in space. Planets such as Caerula boast floating cities of glass and stone, where orbital chandeliers provide warm lighting in eternal twilight. On clear nights, the twin star clusters of the Fiorella Nebula can be seen shimmering in the sky, giving the entire sector a romantic, otherworldly feel.
                </p>
                <p className="card-text text-justify">
                    However, this beauty is tempered by tension. The <TextInlineLink name={"bs_state_free_worlds"} text={"Free Worlds"} /> pirates and dissident factions constantly probe the sector’s defenses, knowing that Fiorella’s wealth makes it a tempting target. The sector’s aristocrats and merchants must walk a fine line between luxury and vigilance, ever mindful that the sector’s delicate peace could crumble if they misjudge either their allies or their enemies.
                </p>
                <p className="card-text text-justify">
                    Fiorella is a sector of contrasts: a place of beauty at the edge of chaos, where diplomacy, culture, and cunning weave together to maintain peace—at least, for now.
                </p>
            </>
    }
}





