import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Verner_rita() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "name": "Rita Werner",
        "fullname": <span>Rita <small>'Poisonous'</small> Werner</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_helena"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"Cydonian"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span>bodyguard of <TextInlineLink name={"bs_char_lenisaad_victor"}
            text={"Lenisaad"} />, <strike><TextInlineLink
                name={"bs_comp_bonecollectors"} /></strike></span>,
        "imgs": [{ "short": "bs_char_werner_rita_1_jpg_500", "big": "bs_char_werner_rita_1_jpg_full" },],
        "pint": "",
        "appears": [<div className="col-lg-2 col-md-6 text-center">
            <Appearance name={"book_cydonian_girl"} />
        </div>, <div className="col-lg-2 col-md-6 text-center">
            <Appearance name={"book_bonecollectors"} />
        </div>,],
        "text": <>
            <h3 className="title-bg">About the character</h3>
            <p className="card-text text-justify">
                <span className="strong">Rita</span> is a Cydonian from Helena. Her backstory won't be revealed until the release of <TextInlineLink name={"book_bonecollectors"} text={"Bonecollectors"} />, or maybe even longer. The character's fate is not easy: she was intended to be one of the main characters in <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />, but due to the overload of the plot and dialogues it was decided to cut her out. Together with a guy named Aaron Thorn, who was a minor character.
            </p>
            <p className="card-text text-justify">
                Instead of a full-blown backstory and storyline, Rita only got a small appearance in <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />: in a scene on <TextInlineLink name={"bs_planet_kyonmar"} text={"Gyeongmar"} />, where she has short conversation with <TextInlineLink name={"bs_char_lenisaad_victor"} text={"Victor Lenisaad"} />.
            </p>
            <p className="card-text text-justify">
                It's ironic that many of <TextInlineLink name={"bs_char_esora_eve"} text={"Esora's"} /> aggressive lines came precisely from Rita, as a legacy of a cut-out character.
            </p>
            <p className="card-text text-justify">
                Rita is likely to appear in <TextInlineLink name={"book_bonecollectors"} text={"Bonecollectors"} /> and <TextInlineLink name={"book_the_free_traders"} text={"Free traders"} />, but this is only a rough plan for now. Reality may prove harsh for the character...
            </p>
        </>
    }
}
