import { CrumbsBlackSunGallery } from "../../../../components/Bread_Crumbs";
import { GalleryCard } from "../Gallery";
import wiki from "../../../../components/cms/wiki.json";


export function Gallery_characters() {
    document.title = "Character gallery of Cydonian girl & Cydonian sun"
    return (<>
        <div className="row" key="crumbs">
            <CrumbsBlackSunGallery name={"Character gallery"} />
        </div>
        <h3 className="title-bg">Characters of Cydonian girl & Cydonian sun</h3>
        <div className="row">
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_ursula_adams_1_jpg_500"} big={"bs_char_ursula_adams_1_jpg"}
                    link={wiki["bs_char_ursula_adams"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_ursula_adams"].link}>Ursula Adams</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_the_whip_1_jpg_500"} big={"bs_char_the_whip_1_jpg_full"}
                    link={wiki["bs_char_the_whip"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_the_whip"].link}>The Whip</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_the_whip_3_jpg_500"} big={"bs_char_the_whip_3_jpg_full"}
                    link={wiki["bs_char_the_whip"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_the_whip"].link}>The Whip</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_esora_eve_1_jpg_500"} big={"bs_char_esora_eve_1_jpg_full"}
                    link={wiki["bs_char_esora_eve"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_esora_eve"].link}>Eve Esora</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_esora_eve_2_jpg_500"} big={"bs_char_esora_eve_2_jpg_full"}
                    link={wiki["bs_char_esora_eve"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_esora_eve"].link}>Eve Esora</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_esora_eve_3_jpg_500"} big={"bs_char_esora_eve_3_jpg_full"}
                    link={wiki["bs_char_esora_eve"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_esora_eve"].link}>Eve Esora</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_esora_eve_4_jpg_500"} big={"bs_char_esora_eve_4_jpg_full"}
                    link={wiki["bs_char_esora_eve"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_esora_eve"].link}>Eve Esora</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_de_levine_rosalie_1_png_500"} big={"bs_char_de_levine_rosalie_1_jpg_full"}
                    link={wiki["bs_char_de_levine_rosalie"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_de_levine_rosalie"].link}>Rosalie De Levine</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_de_levine_rosalie_2_jpg_500"} big={"bs_char_de_levine_rosalie_2_jpg_full"}
                    link={wiki["bs_char_de_levine_rosalie"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_de_levine_rosalie"].link}>Rosalie De Levine</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_de_levine_rosalie_3_jpg_500"} big={"bs_char_de_levine_rosalie_3_jpg_full"}
                    link={wiki["bs_char_de_levine_rosalie"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_de_levine_rosalie"].link}>Rosalie De Levine</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_browdy_adam_1_jpg_500"} big={"bs_char_browdy_adam_1_jpg_full"}
                    link={wiki["bs_char_browdy_adam"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_browdy_adam"].link}>Adam Browdy</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_can_beucan_gaereas_1_jpg_500"} big={"bs_char_can_beucan_gaereas_1_jpg_full"}
                    link={wiki["bs_char_can_beucan_gaereas"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_can_beucan_gaereas"].link}>Gary Can Baukan</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_de_karma_ivar_1_jpg_500"} big={"bs_char_de_karma_ivar_1_jpg_full"}
                    link={wiki["bs_char_de_karma_ivar"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_de_karma_ivar"].link}>Ivar De Karma</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_de_karma_ivar_2_jpg_500"} big={"bs_char_de_karma_ivar_2_jpg_full"}
                    link={wiki["bs_char_de_karma_ivar"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_de_karma_ivar"].link}>Ivar De Karma</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_skarsgard_oliver_1_jpg_500"} big={"bs_char_skarsgard_oliver_1_jpg_full"}
                    link={wiki["bs_char_skarsgard_oliver"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_skarsgard_oliver"].link}>Oliver Skarsgård</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_hartrey_kellays_1_jpg_500"} big={"bs_char_hartrey_kellays_1_jpg_full"}
                    link={wiki["bs_char_hartrey_kellays"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_hartrey_kellays"].link}>Kellays Hartrey</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_colbert_simone_1_jpg_500"} big={"bs_char_colbert_simone_1_jpg_full"}
                    link={wiki["bs_char_colbert_simone"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_colbert_simone"].link}>Simone Colbert</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_van_gloria_adel_1_jpg_500"} big={"bs_char_van_gloria_adel_1_jpg_full"}
                    link={wiki["bs_char_van_gloria_adel"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_van_gloria_adel"].link}>Adele Van Gloria</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_jasmine_haervany_1_jpg_500"} big={"bs_char_jasmine_haervany_1_jpg_full"}
                    link={wiki["bs_char_jasmine_haervany"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_jasmine_haervany"].link}>Yasmine Harvani</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_skarsgard_vivien_1_jpg_500"} big={"bs_char_skarsgard_vivien_1_jpg_full"}
                    link={wiki["bs_char_skarsgard_vivien"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_skarsgard_vivien"].link}>Vivien Skarsgård</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_de_levine_clara_1_jpg_500"} big={"bs_char_de_levine_clara_1_jpg_full"}
                    link={wiki["bs_char_de_levine_clara"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_de_levine_clara"].link}>Clara De Levine</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_javet_bejsenaer_1_jpg_500"} big={"bs_char_javet_bejsenaer_1_jpg_full"}
                    link={wiki["bs_char_javet_bejsenaer"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_javet_bejsenaer"].link}>Beisarion Yaveth</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_javet_eijenora_1_jpg_500"} big={"bs_char_javet_eijenora_1_jpg_full"}
                    link={wiki["bs_char_javet_eijenora"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_javet_eijenora"].link}>Elsinora Yaveth</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_javet_erbees_1_jpg_500"} big={"bs_char_javet_erbees_1_jpg_full"}
                    link={wiki["bs_char_javet_erbees"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_javet_erbees"].link}>Erbesar Yaveth</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_javet_pauline_1_jpg_500"} big={"bs_char_javet_pauline_1_jpg_full"}
                    link={wiki["bs_char_javet_pauline"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_javet_pauline"].link}>Paulinaer Yaveth</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_kato_aemilius_1_jpg_500"} big={"bs_char_kato_aemilius_1_jpg_full"}
                    link={wiki["bs_char_kato_aemilius"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_kato_aemilius"].link}>Aemilius Kato</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_lenisaad_victor_1_jpg_500"} big={"bs_char_lenisaad_victor_1_jpg_full"}
                    link={wiki["bs_char_lenisaad_victor"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_lenisaad_victor"].link}>Victor Lenisaad</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"bs_char_shimmering_devil_1_jpg_500"} big={"bs_char_shimmering_devil_1_jpg_full"}
                    link={wiki["bs_char_shimmering_devil"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_char_shimmering_devil"].link}>Shimmering Devil</a>
                    </h5>} />
            </div>
        </div>
    </>)
}
