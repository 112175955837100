import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Earthlings() {
    return {
        "homeworld": <TextInlineLink name={"bs_planet_earth"} />,
        "state": <span><s><TextInlineLink name={"bs_state_the_great_monarchy"} /></s></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_can_beucan_gaereas_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_alkanarra_siel_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_mereglis_sonya_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_brienne_de_estrada_1_jpg_200"} /></li>
        </ul>,
        "langs": <TextInlineLink name={"bs_lang_earth"} text={"Earthlin"} />,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Earthlings</span> are natives not only of humanity's homeland, but also of <TextInlineLink name={"bs_space_the_old_space"} text={"neighboring territories"} />. Before
                    the <TextInlineLink name={"bs_timeline_galactic_war"} text={"Galactic War"} />, many inhabitants of the Great Monarchy believed that Earth culture, being the oldest
                    of the human cultures, should dominate the galaxy. Earth has always claimed leadership among
                    monarchical countries and even attempted to fund coups d'état in the <TextInlineLink name={"bs_state_republic_of_tiara_minor"} text={"Republic"} />. This eventually led
                    to a global conflict that showed the true situation in the galaxy: no one cares about the Earthlings
                    and their claims to leadership over the former colonies. What's more: many worlds are willing to
                    take up arms, just to avoid being part of Earth's sphere of influence.
                </p>
                <h3 id={"language"} className="title-bg">Language</h3>
                <p className="card-text text-justify">
                    Earth language (usually called Earthlin) is a traditional Homo sapiens language, carried by humans
                    through all the millennia
                    of settlement. At the <TextInlineLink name={"bs_timeline_underlight_age"} text={"time of pre-light travel"} /> it was popular mainly on Old Space worlds and young
                    Earth colonies, but due to poor communication between the planets it inevitably mutated and each
                    world developed its own unique dialect. After the spread of FTL travel and the mixing of cultures,
                    the need to communicate in one language grew so much that the original Earth language replaced
                    almost all others. The roots of the modern Galactic language go back to English and Korean,
                    combining features of both at once: both phonetic and grammatical. For example, many words and terms
                    Earthlings can write in the form of a character, or they can write in words.
                </p>
            </>
    }
}
