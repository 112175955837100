import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Bs_science_cosmography() {
    return {
        "knowledge_area": <span>space sciences</span>,
        "use": <span>galaxy zoning</span>,
        "text": <>
            <h4 className="title-bg">
                About the science
            </h4>
            <p className="card-text text-justify">
                <span className="strong">Cosmography</span> – a complex of natural and social sciences studying
                the structure of space, its zoning, and the distribution of disputed territories. It was the consensus
                of cosmological laws and methods has succeeded in stopping large-scale conflicts between major powers over
                frontier territories.
            </p>
            <p className="card-text text-justify">
                The most important branches of cosmography are considered to be:
            </p>
            <p className="card-text text-justify">
                <span className="strong">Graphic cosmography</span> as a science formulating the laws of division of space into sectors, zones of priority development (controlled but not included territories) and areas of influence of specific planets, star systems and states. The main document on which graphic cosmology is based is the Sectoral Congress, signed by the political and scientific leaders of the galaxy in 1242 <TextInlineLink name={"bs_timeline"} text={"BT"} />. The document consists of rules that define the clear boundaries of sectors and their constituent territories and is considered the basis of international relations. It is regularly updated, and every century or so it is substantially modified to reflect the current vision of the galactic order. A state that refuses to sign the document or revises its rules one-sidedly, in fact, drops out of international politics. Such are most of the planet-states in <TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} />.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Economic cosmology</span> as the science that studies the economic resources of the galaxy, their distribution and rational use. Resources here refer not to minerals, but to star systems, planets, and territories. Economic cosmology helps to form stable economic relations with a view to the future, since it studies the laws of population distribution and predicts the formation of < TextInlineLink name={"bs_universe_planet_types"} text={"metropolies and industrial agglomerations"} /> in specific parts of space. Without its accumulated knowledge, it is extremely difficult to create a long-term investment plan covering periods longer than a century.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Physical cosmology</span> - the science that studies the properties and evolution of physical objects in the galaxy: spiral arms, star systems, planets, etc.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Cosmostatistics</span> - the science that collects and processes statistical data on all events, phenomena and properties in the galaxy. Cosmostatisticians strive to accumulate as much big data as possible about any, even the smallest things in the universe. Bureaux of cosmostatistics are usually independent of the state and exist at the expense of commercial projects, providing their knowledge to anyone who wants it for a moderate fee. They also keep the largest public encyclopedias and databases up to date.
            </p>
        </>
    }
}
