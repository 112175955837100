import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Humans() {
    return {
        "homeworld": <TextInlineLink name={"bs_planet_earth"} />,
        "state": <span>many there are</span>,
        "langs": <span>many there are</span>,
        "size": <span>-</span>,
        "type": <span>intelligent</span>,
        "bs_universe_biom": <span>Earth-like</span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Humans</span> are creatures who've come close to conquering the galaxy, yet somehow still haven't mastered the art of making decent melted cheese or non-disgusting instant coffee. A universal disappointment, really.
                </p>
            </>
    }
}
