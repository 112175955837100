import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Bs_science_metadimensional_mechanics() {
    return {
        "knowledge_area": <span>physics</span>,
        "use": <span>spatial movement, exploring other universes</span>,
        "text": <>
            <h4 className="title-bg">Wormhole Brief by Tiberius de Rohadi, 135 <TextInlineLink
                name={"bs_timeline"} text={"AT"} /></h4>
            <p className="card-text text-justify">
                ...and the study of other worlds is done by <span className="strong">metadimensional mechanics</span>, the science that underlies the things we're used to, or even need to just survive: interplanetary communication and space navigation. Without it, it is impossible to call another world (in a reasonable amount of time) or to fly there without chance of disappearing forever into the black void.
            </p>
            <p className="card-text text-justify">
                What is meta? It means that science works not only with our space, but also with others. In m. mechanics, there is a set of terms that are worth defining in advance, so that we don't have to make a lot of footnotes in the future. That is why I will cite them below.
            </p>
            <p className="card-text text-justify">
                <span className="strong">The universe</span> is the same as the cosmos. But what is the universe? We
                are so used to using that
                word
                in reference to the cosmos that we don't understand its true meaning. The universe is a collection of
                units of energy
                that have the same signature and form a single wave function that obeys the same
                physical and mathematical laws. And if you remember from your high school
                physics course that energy is also in matter, then it will become clear: you and I refer to
                to these units of energy. So the universe is not just stars and planets. The universe is all of us.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Space</span> is a finite set of coordinate axes along which it is possible to move energy in a single universe. This includes time.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Brane</span> is the difference between the energy signatures of the universes, which makes interaction impossible. Brane can also be thought of as a physically dense wall, which can be broken through by applying enough energy to a certain point in a certain way.
            </p>
            <p className="card-text text-justify">
                Now we can safely talk about how m. mechanics works and what it studies. Its first interest is the brane itself: what it is, how it functions, and how to interact with it. Thanks to m. mechanics we have been able to overcome the branes of our own and the neighboring universe (<TextInlineLink name={"bs_tech_abspace"} text={"The Overspace"} />), by creating a <TextInlineLink name={"bs_tech_grn"} text={"Relay network"} />.
            </p>
            <p className="card-text text-justify">
                The second interest is the laws of physics. The difference between our laws and the rules of behavior of the neighboring universe can be devastating. Different values of the fundamental physical constants lead to different forms of matter's existence. Or to its complete annihilation in the case of transition to another universe.
            </p>
            <p className="card-text text-justify">
                The third is coordinate space. Relying on the knowledge of the nature of the boundaries of the universe
                and the difference between
                the laws of physics, we can figure out how adjacent spacetime is arranged and where the tunnel will lead
                to. It's the precise understanding of the coordinates of Overspace that allows us to use the tunnels
                into it as a way to deliver radio waves faster than light: to send them through the more complex
                geometry of the other universe, so that they come back to ours, taking a physically shorter route.
            </p>
        </>
    }
}
