import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function Javet_bejsenaer() {
    return {
        "name": "Beisarion Yaveth",
        "fullname": <span>Beisarion Yaveth</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_ajliraen"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_ajliree"} /></span>,
        "born": <span>211 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>210</span>,
        "children": <span><TextInlineLink name={"bs_char_javet_eijenora"} />, <TextInlineLink
            name={"bs_char_javet_erbees"} /></span>,
        "niece": <span><TextInlineLink name={"bs_char_javet_pauline"} /></span>,
        "work": <span>The Emperor of <TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,
        "imgs": [
            { "short": "bs_char_javet_bejsenaer_1_jpg_500", "big": "bs_char_javet_bejsenaer_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify">
                    <span className="strong">Beisarion</span> was a typical conservative and a champion of traditional values. During his reign in Domination, the situation of the semi-free Quirites worsened noticeably, and many truly talented people were unable to obtain high positions because of the property and social census.
                </p>
                <p className="card-text text-justify">
                    Beisarion is not the most popular and beloved emperor, not only because of these questionable decisions, but also because of Domination's participation in the Galactic war. It was his decision that the Eilireé got involved in the war and suffered enormous losses while maintaining the interests, in fact, of the <TextInlineLink name={"bs_planet_earth"} text={"Earth's"} /> <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Great Monarch"} />. Eiliren had a chance to avoid conflict by taking a neutral position, but Beisarion knew that without his help the Earthlings would not hold the throne of <TextInlineLink name={"bs_space_the_old_space"} text={"Old Space"} /> and the <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Monarchy"} /> would simply collapse under strikes from the <TextInlineLink name={"bs_people_agathonians"} text={"Agathonians"} />. The Emperor feared that after that he would have no strong allies left (Priority doesn't count, it's always neutral). Toward the end of the war he even managed to convince the <TextInlineLink name={"bs_people_cydonians"} text={"Cydonians"} />, represented by <TextPopup name={"bs_char_ivetta_sheridan"} text={"Queen Yvette Sheridan"} />, to help the <TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} /> and the effect was achieved - the war did not end with the complete defeat of the Monarchy.
                </p>
                <p className="card-text text-justify">
                    Beisarion felt he had done the duty of his life: tightening the strings of traditionalism in his own country and helping to keep a neighboring major monarchical power from disintegrating. He wanted to retire to the arts in his old age, as had been the custom of past generations of Yaveth family. But the Crown Princess Elsinora disappointed him: during the Galactic war, she launched many liberal reforms, which the emperor had to roll back demonstrably to punish his daughter. He even tried to find another successor, albeit in secret, but there were simply no worthy candidates. Even the youngest son, Erbesar, proved utterly unfit for the throne.
                </p>
                <p className="card-text text-justify">
                    So Beisarion forgave what he saw as betrayal on the part of his daughter. However, she did not learn her lesson, and she failed to act during the <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Mihjelm rebellion"} />, which led to eventual destruction of the Great Monarchy. Beisarion gave a clear order: to listen to the Monarch and help whatever he needed. However, the princess found allies in the navy and purposely stalled for time, letting the rebels strengthen their forces and find friends. The princess's plan worked. It was over in a matter of weeks and the earthly Monarch signed his resignation from the throne.
                </p>
                <p className="card-text text-justify">
                    The fall of the greatest dynasty, Alcanarra, knocked Yaveth Senior down. He realized he had made a mistake in choosing a successor and began his search for a replacement. Beisarion tried to hide this from the princess, for he knew she had the support of quite a few liberal officials and officers. The emperor planned to make the succession sudden so that the princess would have no legal opportunity to take the throne, since the laws of the Domination require the current emperor and his declared successor to be in place at all times. He simply could not excommunicate Elsinora without nominating a new candidate, and he feared making the wrong choice again.
                </p>
            </>
    }
}
