import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function The_cyllopus() {
    return {
        "homeworld": <span>unknown</span>,
        "size": <span>unknown</span>,
        "type": <span>mythological</span>,
        "pantheon": <span><TextInlineLink name={"bs_universe_religion_arthurians"} /></span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">

                    <span className="strong">Cyllopus</span> is a demon – or, for some, a god – of early <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /> lore, with the first mentions dating back to around 5200 years <TextInlineLink name={"bs_timeline"} text={"before Tamanrassett"} />. It is typically depicted as a colossal fish, reminiscent of <TextInlineLink name={"bs_planet_earth"} text={"Earth's"} /> deep-sea anglerfish, but far more terrifying. Instead of a bioluminescent lure, Cyllopus is said to have an entire sun on its rod. Its light blinds unsuspecting prey, making the creature invisible until its jaws snap shut on an oblivious ship. Some Alamarsie believed Cyllopus devoured entire inhabited worlds, which made them wary of settling on planets. For the same reason, they avoided flying too close to stars—although, it’s debatable whether that fear stemmed from the myth or the worry of melting the hulls of their ancient spacecraft. Who can say for sure now?
                </p>
            </>
    }
}
