import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Morihei() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"cosmographic unit"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "capital": <span>-</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} /></span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Moriseo Sector</span> is the outskirts of the Priority, part of the <TextInlineLink name={"bs_space_free_fire_zone"} text={"Free fire zone"} />. There are few planets with permanent population here, more industrial and mining worlds.
                </p>
                <p className='card-text text-justify'>
                    Earth-speaking people usually call this sector Morihei, butchering the original name.
                </p>
            </>
    }
}

