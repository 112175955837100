import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Karuon() {

    return {
        "date": <span>145</span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,
        "habitats": <span>343 million (217 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>2</span>,
        "g": <span>0.87 terrestrial</span>,
        "atmosphere": <span className="text-danger">none</span>,
        "biosphere": <span>none</span>,
        "system": <span>-</span>,
        "sector": <span>-</span>,
        "type": <span><span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"industrial world"} /></span></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "imgs": [{ "short": "bs_planet_karuon_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">About the world</h3>
                <p className="card-text text-justify">
                    <span className="strong">Caruon</span> is a small industrial planet on the outskirts of Domination in the <TextInlineLink name={"bs_popups_scutum_centaurus_arm"} text={"Scutum-Centaurus Arm"} />. It mines and produces medium-quality steel and other materials used to build ships and space structures.
                </p>
                <p className="card-text text-justify">
                    Caruon has never had an atmosphere or biosphere, and much of its surface has long been covered by sinkholes and giant rock dumps. The population is Quirite shift workers, working no more than a year at a time.
                </p>
                <p className="card-text text-justify">
                    A few years before the <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />, <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar"} />, <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Gary"} />, and <TextInlineLink name={"bs_char_browdy_adam"} text={"Adam"} /> had a skirmish with a pirate gang here and narrowly escaped pursuit in an out-of-control lifeboat. Gary later confessed that he was sweating his underpants off in the process.
                </p>
            </>
    }
}
