import { CrumbsBlackSunTimeline } from "../../../../../components/Bread_Crumbs";

import { GetQuotes } from "../../../../../components/Quotes";
import { GetConnections, TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Light_travel_times() {
    document.title = "Light Age || Cydonian girl & Cydonian sun"
    return (
        <>
            <CrumbsBlackSunTimeline name={"Light Age"} />

            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>Light Age</h2></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={"bs_timeline_light_travel_times"} />
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Beginning: </strong>
                                            <span>~5500 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Dawn:</strong>
                                            <span>~5500 – ~2800 <
                                                TextInlineLink name={"bs_timeline"} text={"BT"} /> (First & Late colonial times)</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>High light age:</strong>
                                            <span>~2800 - 0 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <p><strong>Related
                                articles:</strong> <>{GetConnections("bs_timeline_light_travel_times").map(conn => (conn))}</>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    <h3 className="title-bg">Story</h3>
                    <p className="card-text text-justify">
                        <span className="strong">Light Age</span> or <
                            span className="strong">Light travel times</span> or <span
                                className="strong">FTL times</span> is the approximate date of the beginning of modern chronology. First light engines were invented as early as five or six thousand years ago or so, but their power was enough only to <span className="strike">die very quickly</span> go a little ahead of the photons in vacuum. Scientists are still trying to calculate the exact date of the invention of the very, very first engine, but they probably won't succeed. The technology was calculated, invented and reinvented many times in all parts of the human oecumene, independently of each other. Often the prototypes killed their creators, but the fact of overcoming the light speed was still there.
                    </p>
                </div>
            </div>
        </>
    )
}
