import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Galactic_security() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Type: </strong> <span>law enforcement</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Headquarters: </strong> <TextInlineLink name={"bs_planet_agathon"} />
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Activities:</strong>
                        <span>tracking down international criminals</span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Galactic Security</span> - the galaxy’s ultimate police force. These interstellar enforcers scour the stars, hunting down fugitive criminals and dragging them back to their home planets, whether they like it or not. When high-ranking officials need protection beyond what standard security can handle, Galactic Security steps in. Unlike bounty hunters, they claim to use more “civilized” methods - though, let’s be honest, who really knows what happens when the cameras aren’t rolling?
                </p>
                <p className='card-text text-justify'>
                    Priority has not signed all the clauses of the treaty establishing this organization and for centuries, GS agents have been forced to work in <TextInlineLink name={"bs_state_cydonian_priority"} text={"Cydonian"} /> territory with great restrictions. In addition, the Cydonian police do not extradite criminals with Cydonian citizenship to GS. The only exception is a court order.
                </p>
            </>
    }
}
