import React from 'react';
import { BooksBlacksunPart1 } from "../components/BooksBlacksunPart1";
import wiki from '../components/cms/wiki.json';
import imgs from "../components/cms/images_repo.json";
import { Carousel } from 'antd';

export const Home = () => {
    return (
        <>
            <div key="mainRow" className="row">
                <div key="news" className="col-lg-7">
                    <Carousel>
                        <div>
                            <a href={wiki["bs_char_ursula_adams"].link}>
                                <img alt="Banner 20" src={imgs["banner_776_400_20"].link} fetchpriority="low" />
                            </a>
                        </div>
                        <div>
                            <a href={wiki["bs_char_de_karma_ivar"].link}>
                                <img alt="Banner 18" src={imgs["banner_776_400_18"].link} fetchpriority="low" />
                            </a>
                        </div>
                        <div>
                            <a href={wiki["bs_char_de_levine_rosalie"].link}>
                                <img alt="Banner 21" src={imgs["banner_776_400_21"].link} fetchpriority="low" />
                            </a>
                        </div>
                        <div>
                            <a href={wiki["bs_char_esora_eve"].link}>
                                <img alt="Banner 14" src={imgs["banner_776_400_14"].link} fetchpriority="low" />
                            </a>
                        </div>
                        <div>
                            <a href={wiki["bs_char_the_whip"].link}>
                                <img alt="Banner 16" src={imgs["banner_776_400_16"].link} fetchpriority="low" />
                            </a>
                        </div>
                        <div>
                            <a href={wiki["bs_char_javet_eijenora"].link}>
                                <img alt="Banner 13" src={imgs["banner_776_400_13"].link} fetchpriority="low" />
                            </a>
                        </div>
                        <div>
                            <a href={wiki["bs_char_colbert_simone"].link}>
                                <img alt="Banner 15" src={imgs["banner_776_400_15"].link} fetchpriority="low" />
                            </a>
                        </div>
                        <div>
                            <a href={wiki["bs_char_shimmering_devil"].link}>
                                <img alt="Banner 17" src={imgs["banner_776_400_17"].link} fetchpriority="low" />
                            </a>
                        </div>
                        <div>
                            <a href={wiki["bs_char_browdy_adam"].link}>
                                <img alt="Banner 19" src={imgs["banner_776_400_19"].link} fetchpriority="low" />
                            </a>
                        </div>
                    </Carousel>
                </div>
                <div key="news" className="col-lg-5">
                    <h2>Welcome!</h2>
                    <p className="lead text-justify">The site is dedicated to the universe of the Cydonian Sun books and in particular the first collection called Black Sun.</p>
                    <p className="text-justify">First of all, we advise to look at the <a href={wiki["bs_glossary"].link}>Cydonian Sun world</a> wiki. Then you can watch <a href={wiki["bs_gallery"].link}>gallery</a> with illustrations, check full <a href={wiki["all_books"].link}>book list</a> and a little bit of <a href={wiki["bs_unenclosed"].link}>unenclosed materials</a>.</p>
                    <div className='row'>
                        <ul className="characters-bar">
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row row-alt">
                <h3 className="title-bg margin-bottom-0">Books</h3>
            </div>
            <BooksBlacksunPart1 />

            <div className="row row-alt">
                <h3 className="title-bg margin-bottom-0">Characters</h3>
            </div>

            <div className="row row-alt">
                <div className="text-center col-lg-3 book-item">
                    <a href={wiki["bs_char_the_whip"].link}>
                        <div className="img-thumbnail-v2" style={{ width: "210px", height: "210px", margin: "auto" }}>
                            <div className="bg-image" style={{ maxWidth: "200px", backgroundImage: `url(${imgs["background_stars_sm"].link})` }}>
                                <img src={imgs["no_back_whip_5_200"].link} alt="The Whip" />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <a href={wiki["bs_char_esora_eve"].link}>
                        <div className="img-thumbnail-v2" style={{ width: "210px", height: "210px", margin: "auto" }}>
                            <div className="bg-image" style={{ maxWidth: "200px", backgroundImage: `url(${imgs["background_stars_sm"].link})` }}>
                                <img src={imgs["bs_char_esora_eve_2_png_200_transparent"].link} alt="Eve Esora" />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <a href={wiki["bs_char_de_levine_rosalie"].link}>
                        <div className="img-thumbnail-v2" style={{ width: "210px", height: "210px", margin: "auto" }}>
                            <div className="bg-image" style={{ maxWidth: "200px", backgroundImage: `url(${imgs["background_stars_sm"].link})` }}>
                                <img src={imgs["bs_char_de_levine_rosalie_1_png_200_t"].link} alt="Rosalie De Levine" />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <a href={wiki["bs_char_skarsgard_oliver"].link}>
                        <div className="img-thumbnail-v2" style={{ width: "210px", height: "210px", margin: "auto" }}>
                            <div className="bg-image" style={{ maxWidth: "200px", backgroundImage: `url(${imgs["background_stars_sm"].link})` }}>
                                <img src={imgs["bs_char_skarsgard_oliver_1_png_200_transparent"].link} alt="Oliver Skarsgard" />
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div className="row row-alt">
                <div className="text-center col-lg-3 book-item">
                    <a href={wiki["bs_char_de_karma_ivar"].link}>
                        <div className="img-thumbnail-v2" style={{ width: "210px", height: "210px", margin: "auto" }}>
                            <div className="bg-image" style={{ maxWidth: "200px", backgroundImage: `url(${imgs["background_stars_sm"].link})` }}>
                                <img src={imgs["bs_char_de_karma_ivar_1_png_200_t"].link} alt="Ivar De Karma" />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <a href={wiki["bs_char_browdy_adam"].link}>
                        <div className="img-thumbnail-v2" style={{ width: "210px", height: "210px", margin: "auto" }}>
                            <div className="bg-image" style={{ maxWidth: "200px", backgroundImage: `url(${imgs["background_stars_sm"].link})` }}>
                                <img src={imgs["bs_char_browdy_adam_1_png_200"].link} alt="Adam Browdy" />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <a href={wiki["bs_char_can_beucan_gaereas"].link}>
                        <div className="img-thumbnail-v2" style={{ width: "210px", height: "210px", margin: "auto" }}>
                            <div className="bg-image" style={{ maxWidth: "200px", backgroundImage: `url(${imgs["background_stars_sm"].link})` }}>
                                <img src={imgs["bs_char_can_beucan_gaereas_1_png_200_t"].link} alt="Gary" />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <a href={wiki["bs_char_hartrey_kellays"].link}>
                        <div className="img-thumbnail-v2" style={{ width: "210px", height: "210px", margin: "auto" }}>
                            <div className="bg-image" style={{ maxWidth: "200px", backgroundImage: `url(${imgs["background_stars_sm"].link})` }}>
                                <img src={imgs["bs_char_hartrey_kellays_1_png_200_t"].link} alt="Kellays Hartrey" />
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div className="row row-alt">
                <h3 className="title-bg margin-bottom-0">Places</h3>
            </div>

            <div className="row row-alt">
                <div className="text-center col-lg-3 book-item">
                    <a href={wiki["bs_maps_oecumene"].link}>
                        <div className="img-thumbnail-v2" style={{ width: "210px", height: "210px", margin: "auto" }}>
                            <img style={{ width: "200px", height: "200px" }} src={imgs["bs_wiki_galaxy_v2_250x250_map_en"].link} alt="Galaxy map" />
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <a href={wiki["bs_planet_valoria"].link}>
                        <div className="img-thumbnail-v2" style={{ width: "210px", height: "210px", margin: "auto" }}>
                            <div className="bg-image" style={{ maxWidth: "200px", backgroundImage: `url(${imgs["background_stars_sm"].link})` }}>
                                <img style={{ width: "200px", height: "200px" }} src={imgs["valoria_500"].link} alt="Valoria" />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <a href={wiki["bs_planet_new_havana"].link}>
                        <div className="img-thumbnail-v2" style={{ width: "210px", height: "210px", margin: "auto" }}>
                            <div className="bg-image" style={{ maxWidth: "200px", backgroundImage: `url(${imgs["background_stars_sm"].link})` }}>
                                <img style={{ width: "200px", height: "200px" }} src={imgs["new_havana_500"].link} alt="New Havana" />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <a href={wiki["bs_planet_avvaline"].link}>
                        <div className="img-thumbnail-v2" style={{ width: "210px", height: "210px", margin: "auto" }}>
                            <div className="bg-image" style={{ maxWidth: "200px", backgroundImage: `url(${imgs["background_stars_sm"].link})` }}>
                                <img style={{ width: "200px", height: "200px" }} src={imgs["avvaline_500"].link} alt="Avvaline" />
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div className="row row-alt">
                <div className="text-center col-lg-3 book-item">
                    <a href={wiki["bs_planet_arville"].link}>
                        <div className="img-thumbnail-v2" style={{ width: "210px", height: "210px", margin: "auto" }}>
                            <div className="bg-image" style={{ maxWidth: "200px", backgroundImage: `url(${imgs["background_stars_sm"].link})` }}>
                                <img style={{ width: "200px", height: "200px" }} src={imgs["arville_500"].link} alt="Arville" />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <a href={wiki["bs_planet_tamanrasett"].link}>
                        <div className="img-thumbnail-v2" style={{ width: "210px", height: "210px", margin: "auto" }}>
                            <div className="bg-image" style={{ maxWidth: "200px", backgroundImage: `url(${imgs["background_stars_sm"].link})` }}>
                                <img style={{ width: "200px", height: "200px" }} src={imgs["tamanrasett_500"].link} alt="Tamanrasett" />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <a href={wiki["bs_planet_cydonia"].link}>
                        <div className="img-thumbnail-v2" style={{ width: "210px", height: "210px", margin: "auto" }}>
                            <div className="bg-image" style={{ maxWidth: "200px", backgroundImage: `url(${imgs["background_stars_sm"].link})` }}>
                                <img style={{ width: "200px", height: "200px" }} src={imgs["cydonia_500"].link} alt="Cydonia" />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <a href={wiki["bs_space_tiara_minor_nebulae"].link}>
                        <div className="img-thumbnail-v2" style={{ width: "210px", height: "210px", margin: "auto" }}>
                            <div className="bg-image" style={{ maxWidth: "200px", backgroundImage: `url(${imgs["background_stars_sm"].link})` }}>
                                <img style={{ width: "200px", height: "200px" }} src={imgs["tiara_minor_500"].link} alt="Tiara Minor nebula" />
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </>
    );
};
