import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function AdamsUrsula() {
    return {

        "name": "Ursula Adams",
        "fullname": <span>Ursula Lorellin Juno Adams</span>,
        "born": <span>68 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>67</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_tribalon_at_alamein"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"Agathonian"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "parents": <>Lorel Sorlie и Juno Adams</>,
        "relatives": <span>none</span>,
        "work": <span>writer, businessman, <strike><TextInlineLink
            name={"bs_comp_bonecollectors"} /></strike></span>,
        "imgs": [
            { "short": "bs_char_ursula_adams_1_jpg_500", "big": "bs_char_ursula_adams_1_jpg" },
            { "short": "bs_char_ursula_adams_2_jpg_500", "big": "bs_char_ursula_adams_2_jpg_full" }
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                    <p>(mentioned)</p>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_bonecollectors"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_the_free_traders"} />
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className={"text-justify"}>
                    <span className="strong">Ursula</span> is the protagonist of the <TextInlineLink name={"book_bonecollectors"} text={"Bonecollectors"} />, <TextInlineLink name={"book_the_free_traders"} text={"Free traders"} />, and (possibly) several works yet to be written. She is the author of the famous <TextInlineLink name={"bs_universe_bird_of_passage_guide_to_milky_way"} text={"Birds of passage guide to Milky way"} />, in which she describes her adventures throughout the galaxy in part documentary style and part fiction. She was nicknamed 'Birdie' and 'Migratory' for her inability to stay in one place for more than a couple of months.
                </p>
                <p className="card-text text-justify">
                    The girl hails from <TextInlineLink name={"bs_planet_tribalon_at_alamein"} text={"Tribalon"} />, in the Tribalon Republic, in the vicinity of the <TextInlineLink name={"bs_space_tiara_minor_nebulae"} text={"Tiara Minor nebula"} /> (<TextInlineLink name={"bs_space_vikoveera"} text={"Vikoveera sector"} />). Her parents were <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> mining engineers and geological explorers. The family traveled extensively through the sector, searching for mineral deposits, and since childhood Ursula was infused with a love of travel, exploring, searching for all sorts of curiosities.
                </p>
                <p className="card-text text-justify">
                    She did not receive normal schooling and socialization. She studied basic subjects remotely, spending most of her childhood and teenage years near her parents, on expeditions of the galactic <TextInlineLink name={"bs_organizations_union_of_terrformers"} text={"Union of terrformers"} /> and the Agathon mining concern <TextInlineLink name={"bs_comp_vikoveera_mineralworks"} text={"Vikoveera MineralWorks"} />. Her grades were low and Ursula changed tutors on regulary basis, hitting the family budget harder the older she got.
                </p>
                <p className="card-text text-justify">
                    However, her parents encouraged her curiosity and rewarded her out-of-the-box thinking in anything beyond the standard school course. As early as age 10, she began to master the equipment used by the Agathon geologists and quickly moved on from using the instruments to learning how they worked. Pocket money is hard to spend in the conditions of the space fleet, so the girl used the accumulated (and quite considerable) sums to buy and understand the principles of operation of various kinds of scanners, detectors and radars.
                </p>
                <p className="card-text text-justify">
                    Ursula found quite a few deposits of rare metals and valuable ores, seeing it as entertainment rather than work. Over time, she became more serious and even developed a portfolio of some sort, which then helped her get into university with a simplified program.
                </p>
                <p className="card-text text-justify">
                    The reconfleet of which Ursula's parents' ship was a part included not only engineering ships but also war machines. It had to be regularly defended from pirates who wanted to get their hands on valuable specimens found by scouts in the vastness of the galaxy. There were few of Adams' peers in the fleet, so most of the girl's friends consisted of the Agathon space marines who guarded her. Adams learned many lessons for survival from these friendships, from <TextInlineLink name={"bs_weaponry_railgun"} text={"railgun"} /> techniques to knowledge of pain points on the human body.
                </p>
                <p className="card-text text-justify">
                    Among the samples found by geologists were not only precious ores, but also outlandish artifacts of bygone eras. Geologists' powerful scanners work differently from military scanners and are tuned to chemical compounds often ignored by military vessels. This is why Ursula's parents have repeatedly spotted ancient works of art buried on forgotten planets, all sorts of <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /> caches, and even entire nomadic ships that collapsed on unexplored worlds thousands of years ago.
                </p>
                <p className="card-text text-justify">
                    Such finds sparked Ursula's keen interest and helped her choose her path in life. She tried to understand the history of each such object and, over time, realized that it was archaeology and the ancient history of mankind that attracted her most of all in life.
                </p>
                <p className="card-text text-justify">
                    That's why Ursula first applied to the Department of Archaeology at Agathon Humanities University (48 BT), whose campus was her first permanent home in many years. Life on the planet was depressing for Adams, who was used to the ship's cramped corridors and filtered air, and her studies took up literally all her time. Nevertheless, Ursula earned a degree in archaeology with honors (38 BT). And even before graduation, she managed to visit many expeditions, where she was taken because of the impressive list of equipment, with which the girl worked since her childhood.
                </p>
                <p className="card-text text-justify">
                    A year after beginning her studies, she also enrolled as a part-time student at the Alamein Institute of Reconnaissance Engineering (47-33 BT) and periodically visited her native Tribalon to hone the skills she already had. Reconnaissance engineering is a specialty close to the military, since the basic skills acquired by students are often needed on warships as well. After all, working with scanners differs little depending on the type of equipment: civilian, police, military or scientific instruments are almost identical in principle of operation. Therefore, in order to prepare students to work in any field, the program included military disciplines related to weapons of all kinds, defense systems, and field medicine.
                </p>
                <p className="card-text text-justify">
                    By the age of 30 (38 BT), Ursula got her first official job: she joined a team of Agathon archaeologists working mostly in <TextInlineLink name={"bs_space_calliara_space"} /> (most often in Breeze and the <TextInlineLink name={"bs_space_ternerian_end"} text={"Ternerian End"} />). That's when the <TextInlineLink name={"bs_timeline_galactic_war"} text={"Galactic War"} /> began, the scope of which in the early years was so frightening that Adams almost enlisted as a volunteer in the war fleet.
                </p>
                <p className="card-text text-justify">
                    When the fighting shifted to <TextInlineLink name={"bs_space_the_old_space"} text={"The Old space"} />, Ursula repeatedly participated in the reconstruction of worlds destroyed by <TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} />. She volunteered to help examine buildings for hidden damage and search for unexploded shells on planet orbits and <TextInlineLink name={"bs_tech_hyperrun"} text={"hyperruns"} />.
                </p>
                <p className="card-text text-justify">
                    Since about 36 BT (since age 32), Juno has blogged on the <TextInlineLink name={"bs_tech_grn"} text={"InterNetwork"} /> about the archaeological sites she has visited and the post-war reconstruction process of Calliara Space. She carefully documented almost everything she witnessed, from discovering ancient Alamarsie burials, to studying suspicious signals on deep space scanners around the Tiara Minor Nebula.
                </p>
                <p className="card-text text-justify">
                    After 15 years of blogging and 17 years as an archaeologist, she suddenly quit her job and disappeared from the public eye (21 BT).
                </p>
                <p className="card-text text-justify">
                    She spent the next 10 years on unofficial expeditions to interesting corners of the galaxy and searching for lost cultural treasures. Along with this, working for the <TextInlineLink name={"bs_comp_bonecollectors"} text={"Bonecollectors"} />, meeting with the <TextInlineLink name={"bs_comp_free_trade_league"} text={"Barons of Sambora"} />, the <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Feathered Serpent"} />, and other suspicious characters. (21-11 BT).
                </p>
                <p className="card-text text-justify">
                    Her famous <TextInlineLink name={"bs_universe_bird_of_passage_guide_to_milky_way"} text={"Birds of passage guide to Milky way"} /> came out in 11 before <TextInlineLink name={"bs_planet_tamanrasett"} text={"Tamanrasett"} />, but Ursula didn't stop at the first edition and regularly released new ones in the following years.
                </p>
                <h3 className="title-bg">Evolution of the character</h3>
                <p className="card-text text-justify">
                    Ursula went from the abstract idea of a virtual character to a real character. By virtual here we should mean a character who is only mentioned everywhere, but who does not appear anywhere.
                </p>
                <p className="card-text text-justify">
                    She was just supposed to be the author of a famous book, whose quotes would be used in the real <TextInlineLink name={"bs_series"} text={"Cydonian Sun books"} /> and in this little encyclopedia. Then it became clear: she is so well-developed that she is worthy of more, especially in an expanding universe that you can't show through the prism of <TextInlineLink name={"bs_char_de_levine_rosalie"} text={"Rosalie"} /> and her companions alone.
                </p>
            </>
    }
}
