import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ellania() {
    return {
        "date": <span>~3800 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <TextInlineLink name={"bs_state_republic_of_tiara_minor"} />,
        "habitats": <span>288 billion (23 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "mother": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "g": <span>0.12 terrestrial</span>,
        "atmosphere": <span className="text-danger">none</span>,
        "biosphere": <span>none</span>,
        "system": <TextInlineLink name={"bs_star_horus"} />,
        "sector": <TextInlineLink name={"bs_space_vikoveera"} text={"Vikoveera"} />,
        "region": <span><TextInlineLink name={"bs_space_calliara_space"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_tiara_minor_nebulae"} /></span>,
        "type": <span>moon-<TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "imgs": [{ "short": "bs_wiki_agathon_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">About the moon</h3>
                <p className="card-text text-justify">
                    <span className="strong">Ellania</span> is perceived
                    by the people of Agathon as a bedroom community. It is simply a small planetoid in Agathon's orbit, built up with
                    with high-rise buildings and park alleys. Gravity and atmosphere are maintained in each quarter
                    separately. There is a system of walkways between all the major residential areas: if you arm yourself with
                    persistence and plenty of free time, most of the moon can be traveled on foot.
                </p>
                <p className="card-text text-justify">
                    There are many open spaces on Ellania: parks, squares, and boulevards, over which there is no roof -
                    only an atmospheric shield keeps the air and the people inside. In case of a power failure, there are
                    emergency short-term shields that will keep the atmosphere in place until rescuers arrive.
                </p>
                <p className="card-text text-justify">
                    There are also people here who have chosen to build some kind of country house, but because of the lack of
                    biosphere, you can admire from the window only stars and flat gray landscape.
                </p>
            </>
    }
}
