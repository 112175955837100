import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Bird_of_passage_guide_to_milky_way() {
    return {
        "top": <>
            <div className="row">
                <div className="col-lg-6">
                    <ul>
                        <li><i className="fas fa-chevron-right"></i> <strong>Author: </strong> <TextInlineLink
                            name={"bs_char_ursula_adams"} /></li>
                        <li><i className="fas fa-chevron-right"></i> <strong>Editions: </strong> 4</li>
                    </ul>
                </div>
                <div className="col-lg-6">
                    <ul>
                        <li><i className="fas fa-chevron-right"></i> <strong>Date of writing:</strong>
                            <span>years 21 - 11 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span></li>
                        <li><i className="fas fa-chevron-right"></i> <strong>Alternative titles:</strong>Guide
                            for hitchhikers, A galactic guide for the bums
                            (when Ursula heard last title, she even wanted to rename the book, but the publisher
                            refused)
                        </li>
                    </ul>
                </div>
            </div>
        </>,
        "text": <>
            <h5 className="title-bg">Preface to the Collector's Edition of the book, written by Julian Harari in 223 <TextInlineLink
                name={"bs_timeline"} text={"AT"} /></h5>
            <p className="card-text text-justify">
                Even before the release of her famous Guide <span className="strong">Ursula Adams</span> was a respected explorer of our galaxy. Like many associates, she began with a regular blog on the <TextInlineLink name={"bs_tech_grn"} text={"InterNetwork"} />, where she talked about nuances of certain cultures and found interesting places often ignored by tourists. Along with this she worked for several <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> institutes, participated in archaeological expeditions and even rescued people from under the rubble during the war between the <TextInlineLink name={"bs_people_agathonians"} text={"Agathonians"} /> and the <TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} />.
            </p>
            <p className="card-text text-justify">
                Her biography is littered with dangerous twists and deceitful <TextInlineLink name={"bs_tech_hyperrun"} text={"hyperruns"} />. Since her youth, she has been drawn into a whirlwind of crazy adventures: <TextInlineLink name={"bs_comp_bonecollectors"} text={"Bonecollectors"} />, <TextInlineLink name={"bs_comp_free_trade_league"} text={"Free traders"} />, Samborian cartels, and even the <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Feathered Serpent"} /> himself are a partial list of the people Ursula has crossed paths with.
            </p>
            <p className="card-text text-justify">
                After years of wandering around <TextInlineLink name={"bs_space_calliara_space"} />, giving up her career as a blogger and high-paying job back home, she rushed headlong into spacefighting and disappeared from the public eye for years. She came back with the first edition of The Guide and blasted the InterNetwork with notes about her travels to places that even an armed researcher did not always dare to fly into.
            </p>
            <p className="card-text text-justify">
                Many refuse to believe the authenticity of the events described there (as do I, to be honest). Nevertheless, if we set aside the insinuations surrounding the plot and focus purely on the useful details, we get an incredibly comprehensive guide to what the Milky Way was like just a few years before the terrible events at <TextInlineLink name={"book_tamanrasett_contact"} text={"Tamanrassett's."} />. Thanks to <TextInlineLink name={"bs_char_ursula_adams"} text={"Ursula"} />, descriptions of cultures and worlds that disappeared without a trace in the vortex of a galactic catastrophe have survived.
            </p>
            <p className="card-text text-justify">
                You may not believe in this girl's crazy adventures and think that she purposely put pathos and mystery into her life... but you must find out what was going on in the mysterious world of our ancestors before the War!
            </p>
        </>
    }
}










