import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';


export function De_levine_clara() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "is_not_ready": false,
        "name": "Clara De Levine",
        "fullname": <span>Clara Alicia De Levine</span>,
        "born": <span>79 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>78</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_cydonia"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"Cydonian"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "parents": <span>Florent de Montier and Jessica De Levine</span>,
        "daughter": <span><TextInlineLink name={"bs_char_de_levine_rosalie"} /></span>,
        "imgs": [
            { "short": "bs_char_de_levine_clara_1_jpg_500", "big": "bs_char_de_levine_clara_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify">
                    <span className="strong">Clara's</span> biography is not exactly eventful. If De Karma's research is to be believed, Clara arrived on <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> from Cydonia about 30 years before the <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />, at the height of the <TextInlineLink name={"bs_timeline_galactic_war"} text={"War"} />. Initially the girl was sent on a business trip for a few months, as an auditor in one of the <TextInlineLink name={"bs_comp_briglioni_leushoffer"} text={"Briglioni-Leushoffer"} /> offices. During her first days on the Motherland of Humankind, she met an <TextInlineLink name={"bs_char_the_whip"} text={"unusual young man"} /> and decided to stay. Clara was not even disturbed by the fear that fighting might come close to Earth.
                </p>
                <p className="card-text text-justify">
                    A few years later, still in the midst of War, they decided to have a child, but then something happened that put Clara and The Whip at odds. It is unknown whether the Cydonian knew she was dealing with an alien using a human shell, or whether she only guessed when it was too late. One thing is certain: in the last months of pregnancy, when Rosalie was ready to appear in this galaxy, Clara was not picked up by any Earth surveillance camera. The girl moved from her rented apartment to the Briglioni-Leushoffer employee dormitory and did not leave the building a single step until the day Rosalie was born. In spite of her position, she continued to work in the office, which was several floors above. And when little de Levin was born, Clara left her at the hospital and flew off Earth the same day on a private evacuation transport for Briglioni-Leushoffer employees.
                </p>
                <p className="card-text text-justify">
                    Since then, the girl has lived in her parents' house on Cydonia, which she had planned to sell before Rosalie was born. She has no romantic connections or friends and continues to work as an auditor for Briglioni-Leushoffer. Clara has spent the 25 years since her daughter's birth as if life had stopped: no new hobbies, no vacation trips, or other things that wealthy employees of one of the largest corporations in the galaxy usually have.
                </p>
                <p className="card-text text-justify">
                    Nevertheless, despite her work-home-work mode, there is a remarkable detail in her behavior: Clara regularly changes her travel route, doesn't go to the same places more than 2 times in a row, and doesn't order deliveries from the same place more than once a month in a random day.
                </p>
            </>
    }
}
