import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Free_trade_league() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Type: </strong>
                        <span>criminal organization</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Members: </strong>
                        <span>
                            <ul className="characters-bar">
                                <li><ImgRound3EM img={"bs_char_lenisaad_victor_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_hector_salinger_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_werner_rita_1_jpg_200"} /></li>
                            </ul>
                        </span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Activities:</strong>
                        <span>smuggling, piracy, black market</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Headquarters:</strong>
                        <span><TextInlineLink name={"bs_planet_samborae"} />, <TextInlineLink
                            name={"bs_planet_khal_vernon"} />, <TextInlineLink
                                name={"bs_planet_new_havana"} /></span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Free Trade League</span> is a criminal syndicate that traces its origins back to Sambora. What began as a loose, disorganized group of pirates and smugglers has since evolved into a galaxy-wide power, with allies in governments and navies. Over time, the League established shadowy trade routes that cut across borders and built outposts throughout the human oekumene. Today, the League operates on almost every planet in the Free Worlds. It boasts its own regular army, a taxation system, healthcare, education, courts, and all the other hallmarks of a legitimate state—despite its criminal foundations.
                </p>
                <p className="card-text text-justify">
                    After <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />:
                </p>
                <p className="card-text text-justify">
                    Because of <TextInlineLink name={"bs_char_lenisaad_victor"} text={"Victor Lenisaad's"} /> mess on <TextInlineLink name={"bs_planet_earth"} text={"Earth"} />, the <TextInlineLink name={"bs_comp_free_trade_league"} text={"Free Trade League"} /> lost many valuable connections on
                    the planet and then across the country. This caused enormous losses.
                </p>
            </>
    }
}
