import imgs from '../../../../../components/cms/images_repo.json';

import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Esquirete() {
    return {
        "top": <div className="row">
            <ul>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>State: </strong> <span><TextInlineLink
                        name={"bs_state_cydonian_priority"} /></span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Commander in Chief: </strong>
                    <span>Her Majesty <TextInlineLink name={"bs_char_van_gloria_adel"} /></span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Numbers: </strong> <span>unknown</span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Head of Scientific Projects (Scientific
                        Wing): </strong> <span>esquire <TextInlineLink name={"bs_char_colbert_simone"} /></span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Head of defense projects (Strike Wing): </strong>
                    <span>esquire <TextInlineLink name={"bs_char_skarsgard_oliver"} /></span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Scientific and operational headquarters:</strong>
                    <span><TextInlineLink name={"bs_star_mirage"} /> system, Object #33 Palladas under the direction of Her Majesty's Corps of Engineering and Research, aka 3rd Research Institute of the Ministry of Defense</span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Training Center:</strong>
                    <span><TextInlineLink name={"bs_star_hela_gemini"} />, Object №79 Halkidah, located on the territory of Brianne De Estrada Hellesian Academy of Commercial and Military Navy</span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Tactical Reserve Disposition: </strong>
                    <span><TextInlineLink name={"bs_space_free_fire_zone"} />, <TextInlineLink
                        name={"bs_star_schiaparelli"} /> system, aka Object #111 Polygon<br /><span
                            className="badge bg-danger">REGIME AREA - FIRE WITHOUT WARNING</span></span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Expeditionary Corps Operations Center: </strong>
                    <span><TextInlineLink name={"bs_space_samborian_traverse"} />, <TextInlineLink
                        name={"bs_space_castanaro_ledge"} />, <TextInlineLink
                            name={"bs_star_mu_holothuria"} /> system, aka Samborian Gate <br /><span
                                className="badge bg-danger">REGIME AREA - FIRE WITHOUT WARNING</span></span>
                </li>
            </ul>
        </div>,
        "text":
            <>
                <h3 className="title-bg">From the Agathon Army Encyclopedia</h3>
                <img style={{ "maxWidth": "300px", "float": "left" }} alt="Esquirete's emblem"
                    src={imgs["bs_creature_kalmeria"].link}
                    fetchpriority="low" />
                <p className="card-text text-justify">
                    <span className="strong">Her Majesty's Esquirete</span> is a military formation reporting directly to the monarch of the Priority.
                    The crest is a grinning kalmeria, a small predator endemic to Cydonia. This beast,
                    seemingly harmless, is known for its ferocity and extreme greediness of appetite.
                </p>
                <p className="card-text text-justify">
                    Esquirete recruits the best officers and soldiers: as distinguished in battle, and showed various
                    kinds of outstanding abilities. Once promoted, the prefix esquire is added to the ranks of
                    soldiers and junior officers, and the rank of the highest officer is completely replaced by this
                    term. Thus, a lieutenant becomes an esquire-lieutenant, while a major, colonel, or even an admiral
                    becomes simply an esquire.
                </p>
                <p className="card-text text-justify">
                    A junior esquire is considered senior relative to a serviceman or policeman of the same rank without
                    the esquire- prefix, and a senior esquire is senior in rank to any other Priority serviceman,
                    regardless of rank. The internal chain of command of the Senior Esquire is known only to members of
                    the Esquirete and is generally not disclosed.
                </p>
                <p className="card-text text-justify">
                    Senior Esquires have the right to take control of any military unit in the country and have very
                    broad authority in the running of the troops. As a rule, they exercise this right in times of war or
                    when defending remote colonies from pirates. The lack of bureaucratic hurdles, combined with the
                    Esquirete's experience and training, make it an incredibly flexible and efficient mechanism.
                </p>
                <p className="card-text text-justify">
                    In addition, Esquirete has its own separate war fleet and tactical reserve for emergencies. It is used for special operations inside and outside the country: usually against the Barons of <TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /> or the Feathered Serpent Syndicate. The size of the fleet is classified, but it is known that it consists of several formations, including the 77th Borderfleet Wellington (which participated in the <TextInlineLink name={"bs_timeline_tajbennae_incident"} />), 1st Separate Hellesian Fleet Guardians of Hel, infamous for the <TextInlineLink name={"bs_planet_tiora"} text={"Tiorian incident"} />, and 588th Space Assault Regiment Gothenburg Witches, aka the Asteroid Wing of Priority's SOF.
                </p>
                <p className="card-text text-justify">
                    In open sources the most information can be found about the Witches. Their specialization - storming
                    of fortified bases in the asteroid belts and on small planetoids, such as those that are often used
                    by pirates. The unit is known in military circles of the galaxy primarily for its ability to
                    stealthily move aircraft carriers around <TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /> without being detected by any scanner, and to
                    destroy entire pirate strongholds without resorting to heavy fire support. Despite the tensions
                    between <TextInlineLink name={"bs_planet_cydonia"} /> and <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} />, the Esquirete have always had a warm relationship with the TMS. They
                    have repeatedly covered Agathon commandos, including <TextInlineLink name={"bs_char_esora_eve"} text={"Eve Esora"} />, during operations in the heavily
                    populated regions of the <TextInlineLink name={"bs_state_free_worlds"} text={"Free worlds"} /> (metropolitan area of <TextInlineLink name={"bs_planet_new_havana"} text={"New Havana"} />, <TextInlineLink name={"bs_planet_samborae"} text={"Hnaasor Metropolia"} /> and [REDACTED]).
                </p>
                <blockquote>
                    <p className="card-text text-justify">
                        I would also like to acknowledge the exceptional professionalism and heroism of our Cydonian
                        colleagues from the 588th Regiment of Esquirete. Their bravery and unsurpassed level of piloting
                        saved the lives of <TextInlineLink name={"bs_char_esora_eve"} text={"Lieutenant Esora"} /> and the personnel of the Velimore garrison
                    </p>
                    <small>From <TextInlineLink name={"bs_comp_cms"} text={"TMS"} /> reports to Chancellor <TextInlineLink name={"bs_char_callisto_kindrace"} text={"Kindrace"} /></small>
                </blockquote>
                <div className="bg-image"
                    style={{ "padding": "1em", "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                </div>
                <p className="card-text text-justify">
                    Generous funding also allows the Esquirete to conduct scientific research not always related to
                    national defense. For example, it was within this organization that many of the technologies that
                    now protect hyperlink beacons from unauthorized access were developed. Self-destruction, followed by
                    a nuclear explosion and the release of antimatter, was the idea of the Esquires. Over time, such
                    freedom was reflected in the principles of officer recruitment as well: the Esquirete's current
                    scientific director, Simone Colbert, never served in the military and began her career in defense
                    specifically with the Esquirete. She, like many other talented young men, was recruited because of
                    her knowledge of science, and her military training came after she joined the service. For such
                    purposes, the Hellesian Academy of Commercial and Military Navy, the elite training facility of
                    Priority, has a separate Halkidah training center.
                </p>
                <p className="card-text text-justify">
                    Of course, this state of affairs leads to internal discrimination, with combat officers sabotaging
                    the orders of science wing esquires.
                </p>
                <p className="card-text text-justify">
                    The most important feature of the Esquirete is the right to conduct military operations on any scale
                    without the approval of the Priumvirate (the parliament of the Priority). The place and purpose of
                    operations are not regulated and depend on orders from the monarch. Martial law or other
                    bureaucratic procedures are not required for such operations. The only condition: the Parliamentary
                    Commission of Defense Operations must be notified of all use of military weapons by Esquirete, even
                    for self-defense.
                </p>
                <p className="card-text text-justify">
                    Such broad powers have caused many disagreements between the monarchs and Parliament. The Commission
                    repeatedly imposed sanctions against individual officers, and once even initiated the impeachment of
                    a monarch (<TextInlineLink name={"bs_char_ivetta_sheridan"} text={"Queen Yvette Sheridan"} />, 19 years before Tamanrassett).
                </p>

                <h6>Excerpts from the historical guidebook The Galaxy Before Tamanrasett by Semion Dalastar,
                    422 <TextInlineLink name={"bs_timeline_light_travel_times"} text={"AT"} />:</h6>
                <blockquote>
                    <p className="card-text text-justify">
                        ...thus it was Esquirete who took on all the burdens of preparing to fight the Vitruvians and in
                        the hard battles gathered intelligence that saved many lives after Tamanrassett...
                    </p>
                    <small>Chapter 2: The Calm Before the Storm</small></blockquote>
                <blockquote>
                    <p className="card-text text-justify">
                        ...Adele Van Gloria did not trust lower-ranking military officers and kept most of the information about the impending invasion within the narrow circles of the Esquirete. One can take various views on such a controversial decision: the queen certainly had more experience in dealing with these people than we, her descendants, do. But I still think it was a catastrophic mistake.
                    </p>
                    <small>Chapter 3: Harbingers</small>
                </blockquote>

            </>
    }
}
