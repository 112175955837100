import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Daydaris() {

    return {
        "date": <span>~543 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,
        "habitats": <span>117 million</span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>1</span>,
        "g": <span>1.02 terrestrial</span>,
        "atmosphere": <span>polluted, <span className="text-danger">dangerous to humans</span></span>,
        "biosphere": <span>nearly destroyed</span>,
        "system": <span><TextInlineLink name={"bs_star_leeroy_jenkins"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_megasthenes"} text={"Megasthenes"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"}
            text={"industrial world"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_vataary_vector"} /></span>,
        "imgs": [{ "short": "bs_wiki_daydaris_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Daydaris</span> –
                    a planet where the snow smells like old socks, but otherwise it's nothing special. Located in
                    Domination, the fourth world in the Leeroy-Jenkins system.
                </p>
                <p className="card-text text-justify">
                    <TextInlineLink name={"bs_char_de_karma_ivar"}
                        text={"Ivar"} />, <TextInlineLink name={"bs_char_browdy_adam"} text={"Adam"} /> and <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Gary"} /> were doing work there for Princess <TextInlineLink name={"bs_char_javet_eijenora"} />, shortly before <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} /> began. Since then, the poor <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /> has occasionally dreamed that he is walking through an endless snow-covered field and convulsively looking for a respirator in his backpack.
                </p>
                <p className="card-text text-justify">
                    It is believed that the infamous <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Feathered Serpent"} /> started here.
                </p>

                <div className="alert alert-danger">
                    <p className='card-text text-justify'>
                        <strong>
                            More information will be after the next parts of <TextInlineLink name={"bs_series"} text={"Cydonian sun"} /> are released
                        </strong>
                    </p>
                </div>
            </>
    }
}
