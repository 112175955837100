import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Inter_branal_positioning_beacon() {
    return {
        "science": <span><TextInlineLink name={"bs_science_metadimensional_mechanics"}
            text={"metadimensional mechanics"} /></span>,
        "use": <span>spatial movement</span>,
        "text": <>
            <h4 className="title-bg">
                Wormhole Brief by Tiberius de Rohadi, 135 <TextInlineLink name={"bs_timeline"} text={"AT"} />
            </h4>
            <p className="card-text text-justify">
                <span className="strong">Positioning beacon</span> – a class of devices that can create wormholes between different parts of the universe and thus transport energy or matter faster than light. Such devices include, for example, beacons of <TextInlineLink name={"bs_tech_grn"} text={"Galactic relay network"} />.
            </p>
            <p className="card-text text-justify">
                Humans have not yet succeeded in inventing a position beacon in its purest form. The laws of physics
                make
                its creation extremely energy-consuming and the side effects unpredictable. Therefore, the cheaper and
                easier to operate version of the beacon - the inter-branal position beacon - is used instead of the
                "pure" version of the beacon.
            </p>
            <p className="card-text text-justify">
                Brane is a potential barrier of the universe. It can be thought of as an invisible wall separating
                the adjacent universes
                or you can think of it as a difference in energy signatures. It's a mathematical parameter that prevents
                matter
                from different universes from touching each other (normally).
            </p>
            <p className="card-text text-justify">
                The idea of brane is good because it can be overcome, and if there is a universe behind it with more
                loyal laws, then
                its space can be used to accelerate movement in the current one. The inter-branal beacon does
                pushing through space-time in one place and creating a wormhole not within one universe,
                but in two at once. Essentially, it makes a hole in the brane and keeps it stable. The most advanced
                devices
                can not only create a tunnel into another universe, but extend it to the right coordinates to then open
                a way out again in our universe. Of all the devices known to mankind
                only <TextInlineLink name={"bs_tech_northern_star_of_niradanhae"} /> is
                capable of that.
            </p>
            <p className="card-text text-justify">
                Inter-branal beacons have a simple classification: directional beacons and shifting anchors.
            </p>
            <p className="card-text text-justify">
                The <span className="strong">Directional beacon</span> can only calculate coordinates and create
                wormhole, which must then be extended by other means. The reason it's called a directional beacon
                because it only takes on the task of laying a path through space-time and is incapable of carrying out
                transport
                of matter on its own. It is as if it "pushes" space in the right places, making it easy to detect
                a rift and create a tunnel. After its activation, an anomaly appears in the neighboring universe, which
                can be detected and expanded into a stable tunnel (with the right technology). Externally, it can
                look
                like a black/white hole, an area of distorted time, or just a place with anomalous electromagnetic
                activity - it all depends on the local laws of physics.
            </p>
            <p className="card-text text-justify">
                The point of using such devices is that they do the most dirty work - they break the integrity
                of the continuum. The one who will use the created anomaly only needs to direct into it a stream of
                of energy or particles with the desired properties. No coordinate calculations or corrections for the
                laws of the other universe will be required.
            </p>
            <p className="card-text text-justify">
                The <span className="strong">Shifting Anchor</span> is far more advanced in design and is capable of
                not only locating the desired physical location in the universe, but also creating a stable wormhole.
                It disrupts the continuum and expands the anomaly to the size of a tunnel through which you can cross.
                The anchor clings to two points
                in the multidimensional plane and creates a full-fledged gateway between them. It essentially destroys
                the difference
                coordinates and for the duration of operation of the device the two points of the multiverse are in one
                physical
                place.
            </p>
            <p className="card-text text-justify">
                Both types of devices can have unpredictable effects on the universe, both our own and our neighbor's.
                Therefore, their use is strictly regulated. Although... let's be honest, you can't build one of these
                in your garage.
            </p>
        </>
    }
}
