import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Biome() {
    return {
        "top": "",
        "text":
            <>

                <h4 className="title-bg"><i>Biospheres of earth-like worlds</i>, Chele Soleung, 12
                    years <TextInlineLink
                        name={"bs_timeline"} text={"BT"} /></h4>
                <p className="card-text text-justify">
                    <span className="strong">Biome</span> is a biosystem of several connected ecosystems. Each planet has its own biome, and even if creatures from different worlds are similar, it doesn't mean that they have the same structure.
                </p>
                <p className="card-text text-justify">
                    Most biomes on terraformed planets are <span className="strong">compatible with Earth's</span>, because before terraforming these worlds did not support life at all. It was human intervention that made such planets suitable for organic beings, and this intervention consists of inserting modified Earth species and creating conditions for their further reproduction. Thus, a lifeless stone ball becomes similar to <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> in a few centuries, though with minor differences due to mutations and the influence of unique local environmental conditions.
                </p>
                <p className="card-text text-justify">
                    There are biomes compatible with Earth's biome, which became such in the course of natural
                    convergent evolution. For example, the <TextInlineLink name={"bs_planet_cydonia"} text={"Cydonian"} /> biosphere is very similar to Earth's and in many regions of Cydonia it is even possible to grow native Earth plants. Its atmosphere almost 100% corresponds to the ancestral home of mankind, although the climate is markedly different due to lower average temperatures and the lack of a change of seasons. This is also how the biome of <TextInlineLink name={"bs_planet_the_night"} text={"Coal Njorun"} /> was before it became the Coal One.
                </p>
                <p className="card-text text-justify">
                    However, most worlds with a native biosphere are partially or completely <span className="strong">incompatible</span> with species we are accustomed to. For example, <TextInlineLink name={"bs_planet_scellurae"} /> in the <TextInlineLink name={"bs_space_shark_sector"} />. When humans discovered it, the planet was a blooming garden with unique conditions and ecological balance. But it was absolutely impossible to stay there without protection: rains, plants and even the air itself were saturated with caustic compounds - just an hour on the surface without a spacesuit caused irreparable injuries. So humans gradually, in several long stages, changed everything that made Scellurae unique: the atmosphere, composition of the soil and water, and the genome of almost all species. Now its biome is compatible with Earth's, and they grow fruit trees that are perfectly safe for us to eat. Was such a catastrophe worth it? Everyone decides for himself.
                </p>
            </>
    }
}











