import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";


export function Kato_aemilius() {
    return {
        "is_not_ready": false,
        "name": "Aemilius Kato",
        "fullname": <span>Aemilius Kato</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_tamanrasett"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"Agathonian"} /></span>,
        "born": <span>122 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>121</span>,
        "work": <span>Admiral of the 12th Special Agathon Fleet Aquetanica</span>,
        "imgs": [
            { "short": "bs_char_kato_aemilius_1_jpg_500", "big": "bs_char_kato_aemilius_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify">
                    <span className="strong">Aemilius</span> is a native of Tamanrasett, in the <TextInlineLink name={"bs_space_ternerian_end"} text={"Ternerian End"} />. He is an ambitious officer in the Agathon fleet, commander of an elite anti-piracy force, the <span className="strong">Aquetanica fleet</span>. Under his command there have been many successful operations both in <TextInlineLink name={"bs_space_calliara_space"} /> and in the <TextInlineLink name={"bs_state_free_worlds"} text={"Free Worlds"} />.
                </p>
                <p className="card-text text-justify">
                    Kato has many acquaintances not only in the <TextInlineLink name={"bs_state_republic_of_tiara_minor"} /> army, but also in the <TextInlineLink name={"bs_comp_cms"} text={"TMS"} />. Among them, he is primarily known as a good tactician who despises dumbasses and relies on subtle, well-calculated moves.

                </p>
                <p className="card-text text-justify">
                    The first appearance of Aemilius occurred at the end of <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />: he persuaded his old acquaintance, <TextInlineLink name={"bs_char_xavier_kardenos"} text={"General Cardenos"} />, to participate in the interrogation of <TextInlineLink name={"bs_char_esora_eve"} text={"Esora"} /> together. As it turned out later, Kato was the only officer in the TMS who believed Eve - he himself confessed it at the end of the novel.
                </p>
                <div className="alert alert-danger">
                    <p className='card-text text-justify'>
                        <strong>
                            More information will be available after the next part of <TextInlineLink name={"bs_series"} text={"Cydonian Sun"} /> is released.
                        </strong>
                    </p>
                </div>
            </>
    }

}