import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Cassini() {
    return {
        "date": <span>2211 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>55 billion (32 on the surface)</span>,
        "people": <span>Cassians (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>Aemilia</span>,
        "g": <span>1.09 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span>Cassini</span>,
        "sector": <span><TextInlineLink name={"bs_space_grace_sector"} text={"Grazia"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "imgs": [{ "short": "bs_wiki_cassini_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">Brief</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Cassini</span>, the capital of the remote Grazia sector, is a testament to the elegance and resilience of the Cydonian spirit. Named after an ancient Earth astronomer, the planet reflects the careful balance between human ambition and environmental stewardship that defines the Priority. Settled in 2211 BT, <span className='strong'>Cassini</span> is a terraformed world with a mild climate, dense forests, and rolling meadows crisscrossed by rivers fed by glacial reservoirs. The lone moon, Aemilia, orbits serenely above, illuminating the planet’s long, gentle nights.
                </p>
                <p className='card-text text-justify'>
                    While 32 billion inhabitants call the surface home, almost half of the 55 billion citizens live in orbital cities suspended around the planet, connected by space elevators. Life on Cassini moves at a slower pace compared to the bustling core worlds. Its orbital metropolises, however, serve as key hubs for trade, exploration, and diplomacy along the outer edges of the Priority.
                </p>
                <p className='card-text text-justify'>
                    As the farthest sector capital, Cassini plays a unique role in the nation’s politics. Its distance from the heart of the Priority fosters a spirit of self-reliance, yet its loyalty to Cydonia has never wavered. The sparse population of the Grazia sector and the slower pace of life allow <span className='strong'>Cassini</span> to focus on art, research, and experimental technologies. While it may not rival the grandeur of Cydonia itself, <span className='strong'>Cassini</span> offers an undeniable charm: a world on the edge of civilization, where the vastness of the galaxy seems just a little closer.
                </p>
                <p className='card-text text-justify'>
                    <span className='strong'>Cassini</span> is also renowned as the home of the Cassian opera, a genre that blends classical performance with futuristic elements, captivating audiences across the galaxy. The planet boasts the prestigious Cydonian Ballet, whose mastery of both traditional and experimental choreography has earned it a global following. This cultural epicenter once hosted the bohemian wave of the early last century, an era marked by vibrant creativity and artistic rebellion that shaped the broader identity of the Priority.
                </p>
                <p className='card-text text-justify'>
                    Today, <span className='strong'>Cassini</span> remains a magnet for those drawn to the energy of pop culture, from the production of high-budget Cydonian blockbusters to avant-garde theater. The famed Tricentennial War theater school, inspired by key moments in Cydonia’s history, has flourished here, attracting playwrights, actors, and directors eager to reinterpret war dramas through innovative storytelling techniques. The streets of <span className='strong'>Cassini</span> hum with life, populated by artists, entertainers, and cultural pioneers, all contributing to its reputation as a playground for creative minds across the Brianne Cluster.
                </p>
            </>
    }
}

