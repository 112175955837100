import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function TheWhip() {
    return {

        "has_spoilers": true,
        "spoilers_list": <><TextInlineLink name={"book_cydonian_girl"} />, <TextInlineLink
            name={"book_hyperbeacons"} /></>,
        "name": "The Whip",
        "fullname": <span>The Whip <small>Last of the firstborns, The One</small></span>,
        "born": <span>???</span>,
        "age": ">6000",
        "homeworld": <span>???</span>,
        "species": <span><TextInlineLink name={"bs_spec_firstborns"} text={"firstborns"} /> (?)</span>,
        "daughter": <TextInlineLink name={"bs_char_de_levine_rosalie"} />,
        "img_trans": true,
        "imgs": [
            { "short": "bs_char_the_whip_1_png_500", "big": "bs_char_the_whip_1_jpg_full" },
            { "short": "bs_char_the_whip_4_jpg_200", "big": "bs_char_the_whip_4_jpg_full" },
            { "short": "bs_char_the_whip_2_jpg_200", "big": "bs_char_the_whip_2_jpg_full" },
            { "short": "bs_char_the_whip_3_jpg_200", "big": "bs_char_the_whip_3_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_bonecollectors"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"} />
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className={"text-justify"}>
                    <span className="strong">The Whip</span> is a being of unknown nature who came from the distant depths of space with
                    an unclear purpose. He looks like a young human male. He has a taste for dangerous
                    adventures and uses technologies so advanced that they seem more like magic. Despite
                    his
                    outwardly harmless appearance, Whip has superhuman abilities: he can break space
                    steel
                    mounts like origami and survive even after being hit by a <TextInlineLink name={"bs_weaponry_railgun"} text={"railgun"} />. He got his name
                    precisely for such tricks, and not at all for what one might think.
                </p>
                <p className={"text-justify"}>
                    For centuries, Whip lived among humans, mimicking them, and even had shared
                    offspring. He decided to tell his secret only to a select few who could help him in
                    his search for the <TextInlineLink name={"bs_tech_the_key"} text={"Key"} /> to the <TextInlineLink name={"bs_weaponry_the_black_sun"} text={"Black Sun"} />.
                </p>
                <p className={"text-justify"}>
                    He was friends with the <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /> when it was not yet mainstream.
                </p>
                <p className={"text-justify"}>
                    Character of <TextInlineLink name={"book_cydonian_girl"} />, <TextInlineLink
                        name={"book_hyperbeacons"} />, <TextInlineLink
                        name={"book_cydonian_autumn"} />, <TextInlineLink
                        name={"book_bonecollectors"} /> and several subsequent novels.
                </p>
                <p className="card-text text-justify">
                    Whip's exact age is unknown, but he was born at least 6,000 years <TextInlineLink name={"bs_timeline"} text={"before Tamanrasett"} />. After an unknown amount of time, his civilization was destroyed by a being named <TextInlineLink name={"bs_char_shimmering_devil"} text={"Shimmering Devil"} />, who used the Black Sun to do so. Whip managed to imprison the Sun in a singularity and close it with a certain Key, but he lost the battle with the Devil.
                </p>
                <p className="card-text text-justify">
                    He then spent many centuries in endless wanderings and apparently left many traces all over the observable universe. What he was doing and what he was looking for is unclear. What is known for certain, however, is that around 2800 BT Whip arrived in the Milky Way and made contact with humans. He gave the Alamarsie the Key, as this device, he said, had the will and the ability to drive living beings insane. Whip believed that humans would not have time to be influenced by it, since they lived too little and simply would not have time to lose their minds - the influence of the Key is extremely weak and does not manifest itself until many decades later.
                </p>
                <p className="card-text text-justify">
                    Having solved the problem of storing such a dangerous device, Whip gained another: humans lost it. It is unknown how or when, but the Alamarsie simply either forgot about the true value of the device, or the ship carrying it was raided by pirates. The trace of the Key was lost centuries ago.

                </p>
                <p className="card-text text-justify">
                    When the time came to use the Key to open the singularity and unleash the Sun to destroy the <TextInlineLink name={"bs_spec_vitruvianus"} text={"Vitruvians"} />, Whip was unable to locate it. It took him years to figure it out and at least narrow it down a bit. At the time of Cydonian girl, the Key had not been found, and the Vitruvians themselves figured out that Whip had lost it and opened a hunt for the device as well.
                </p>
                <p className="card-text text-justify">
                    In <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />, Whip first met the youngest of his daughters, Rosalie De Levine, who was only 25 at the time. Conversation didn't work out and there was unfortunately no time to reunite daughter and father.
                </p>
                <p className="card-text text-justify">
                    In the <TextInlineLink name={"book_hyperbeacons"} text={"Beacon thieves"} />, Whip rescued <TextInlineLink name={"bs_char_skarsgard_oliver"} text={"Oliver Skarsgård"} /> from the superior Vitruvian forces and warned of the impending alien threat. There, on <TextInlineLink name={"bs_timeline_tajbennae_incident"} text={"Taibennae"} />, he discovered the <TextInlineLink name={"bs_tech_northern_star_of_niradanhae"} text={"Northern Star of Niradanha"} />, though he did not quite understand the nature of the device. He expected that the beacon would help him bypass the singularity and free the Sun without the Key, but research showed that this was impossible.
                </p>
                <p className="card-text text-justify">
                    So he gave the beacon to Arthur, a mysterious creature who also mimicked Homo sapiens and knew how to create wormholes to travel through space. In return, Arthur promised that he would help find and destroy the Shimmering Devil, who turned out to be their common enemy.
                </p>
            </>
    }
}
