import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Sun_zero() {
    return {
        "date": <span>~206 000 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "type": <span>yellow dwarf</span>,
        "stars": <span>1</span>,
        "planets": <span>8</span>,
        "habitable": <span>2</span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_sector_zero"} text={"Zero"} /></span>,
        "owned": <span><s><TextInlineLink name={"bs_state_the_great_monarchy"} /></s>, unknown</span>,
        "people": <span><TextInlineLink name={"bs_people_earthlings"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "population": <span>281 billion</span>,
        "imgs": [{ "short": "bs_wiki_sun_zero_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Sun Zero</span> or <span className="strong">Terrestrial Sun</span> – the native star of mankind. From it, it's traditionally counted galactic distances in all directions: a hundred light years to the core / to the arm of so-and-so / to the cluster so-and-so, and so on. Although, when humanity has already spread out many thousands of years from home, such a system is damned inefficient. You could say it's a tribute to tradition.
                </p>
                <p className="card-text text-justify">
                    They say that after what happened in <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />, Mars is also thinking about independence... there you go.
                </p>
            </>
    }
}

