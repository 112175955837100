export function Railgun() {
    return {
        "type": <span>kinetic weapon</span>,
        "use": <span>any targets</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Rail (railgun, rail cannon)</span> – two conductive rails and a steel bolt (screw) between them. Fires a piece of metal at supersonic speed, so the killing power of the projectile is enormous. Manual and ship rails work on the same principle and if you shoot a big enough gun in the atmosphere you can boil the air around or evaporate a piece of an ocean. And the shock wave can turn a medium-sized town into a broken cardboard pyramid.
                </p>
                <p>
                    Railgun caliber usually refers to the radius of a projectile in centimeters at its widest part.
                </p>
            </>
    }
}
