import { CrumbsBlackSunTimeline } from "../../../../../components/Bread_Crumbs";

import { GetQuotes } from "../../../../../components/Quotes";
import { GetConnections, TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function The_shark_revolt() {
    document.title = "Rise of the Shark || Cydonian girl & Cydonian sun"
    return (
        <>
            <CrumbsBlackSunTimeline name={"Rise of the Shark"} />

            <div className="alert alert-danger">
                <p className="card-text text-justify">
                    <strong>There WILL be spoilers below:</strong> <TextInlineLink name={"book_cydonian_girl"} />
                </p>
            </div>
            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>Rise of the Shark</h2></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={"bs_timeline_the_shark_revolt"} />
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i>
                                            <strong>Dates: </strong> <span>~14-11.5 month <TextInlineLink
                                                name={"bs_timeline"} text={"BT"} /></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Place:</strong>
                                            <span><TextInlineLink name={"bs_state_the_great_monarchy"} /></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ul>
                                <li>
                                    <i className="fas fa-chevron-right"></i> <strong>Participants:</strong>
                                    <span><TextPopup name={"bs_popups_state_shark_republic"}
                                        text={"Mihjelmian Republic of Shark"} />, <TextInlineLink
                                            name={"bs_state_republic_of_tiara_minor"} />, <TextInlineLink
                                            name={"bs_state_the_great_monarchy"} />, <TextInlineLink
                                            name={"bs_char_alkanarra_siel"} />, <TextInlineLink
                                            name={"bs_char_de_karma_ivar"} />, <TextInlineLink
                                            name={"bs_char_de_levine_rosalie"} />, <TextInlineLink
                                            name={"bs_char_esora_eve"} />, <TextInlineLink
                                            name={"bs_char_browdy_adam"} />, <TextInlineLink
                                            name={"bs_char_can_beucan_gaereas"} />, <TextInlineLink
                                            name={"bs_char_lenisaad_victor"} />, <TextInlineLink
                                            name={"bs_char_callisto_kindrace"} />, <TextInlineLink
                                            name={"bs_char_otton_bjorne"} />, <TextInlineLink
                                            name={"bs_char_kaory_nazil"} />, <TextInlineLink
                                            name={"bs_char_montgomery_lorena"} />, <TextInlineLink
                                            name={"bs_char_tom_torvalds"} />, <TextInlineLink
                                            name={"bs_char_salibar_kardenos"} />, <TextInlineLink
                                            name={"bs_char_mereglis_sonya"} /></span>
                                </li>
                            </ul>
                            <p><strong>Related
                                articles:</strong> <>{GetConnections("bs_timeline_the_shark_revolt").map(conn => (conn))}</>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    <h3 className="title-bg">Story</h3>
                    <p className='card-text text-justify'>
                        <span className="strong">The Shark Rising</span> or <span className="strong">Second Spider Démarche</span> was
                        the forcible overthrow of <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Ciel Alcanarra"} />, the
                        last Monarch of the dynasty of same name. It began with an armed rebellion on <TextInlineLink name={"bs_planet_michelm"} text={"Mihjelm"} />, organized
                        by Shark Sector Governor Tomas Torvalds and the commanders of the Mihjelm fleet (<TextInlineLink name={"bs_char_otton_bjorne"} text={"Otto Bjerne"} /> and <TextInlineLink name={"bs_char_montgomery_lorena"} text={"Lorena Montgomery"} />). And it continued thanks to the secret support of the <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> enforcement units and the inaction of the <TextInlineLink name={"bs_people_cydonians"} text={"Cydonians"} /> (who had originally promised to help the <TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} /> suppress the rebellion).
                    </p>
                    <p className='card-text text-justify'>
                        The main fighting force that sided with the Mihelmians was the Spider Echelon, commanded by Bjerne. In addition, Torvalds managed to enlist the help of many high-ranking officers in other ectors of the Monarchy: the rebels' military capacity was strengthened by numerous groups of and loss of morale among the rank-and-file soldiers (most fighters were from regions similar to Mihjelm, and over time became more and more sympathetic to the rebellion)
                    </p>
                </div>
            </div>

            <section className="resume">
                <div className="container">
                    <h3 className="title-bg">Chronology</h3>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="timeline-item"><h4>1. Preparations</h4>
                                <p className={"text-justify"}><em><TextInlineLink name={"bs_planet_michelm"} /></em></p>
                                <p className={"text-justify"}>
                                    Torvalds learns of the warrant for his arrest and hires Ivar De Karma for his
                                    planned
                                    escape from Earth
                                </p>
                            </div>
                            <div className="timeline-item"><h4>2. Rise begins</h4>
                                <p className={"text-justify"}><em><TextInlineLink
                                    name={"bs_planet_michelm"} />, <TextInlineLink name={"bs_planet_earth"} /></em></p>
                                <p className={"text-justify"}>
                                    Thomas Torvalds arrested on Earth
                                </p>
                                <p className={"text-justify"}>
                                    A prison break that resulted in many innocent victims and much destruction in the
                                    capital (<TextInlineLink name={"bs_comp_galactic_security"} text={"Galactic Security"} /> believes <TextInlineLink name={"bs_char_lenisaad_victor"} text={"Victor Lenisaad"} />, <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar De Karma"} /> and several
                                    associates were involved in the violent method of escape)
                                </p>
                                <p className={"text-justify"}>
                                    Grand Admiral <TextInlineLink name={"bs_char_mereglis_sonya"} text={"Sonya Mergelis"} /> authorizes mass arrests of officers and officials
                                    across the country, as well as illegal executions, called the Hunt for Sharks
                                </p>
                                <p className={"text-justify"}>
                                    Shark Sector rebels gather on Mihjelm to meet with Torvalds
                                </p>
                                <p className={"text-justify"}>
                                    Joint statement by <TextInlineLink name={"bs_char_tom_torvalds"} text={"Torvalds"} /> and Otto Bjerne on the separation of Shark Sector from the Monarchy
                                </p>
                                <p className={"text-justify"}>
                                    Many military fleets in other sectors support Mihjelm, but the rebellion has lost
                                    its leaders due to Mergelis' measures and cannot act as a unified force. Instead of
                                    a bloodless change of power under people's pressure, the Monarchy quickly descends
                                    into the chaos of civil war: conflicts begin between the armies of individual
                                    planets, pirate captains, criminal gangs and rebels trying to maintain some control.
                                    The government forces of the Echelon of The Fives, commanded by Mergelis, intervene
                                    in the chaos and one by one suppress the pockets of rebellion. Within days, the
                                    Earth Loyalist army has taken control of the Night Sector and much of the Sunrise
                                    Sector. Realizing that the Mihjelms have retained control of nearly all of Shark
                                    Sector, the Fifths begin preparing to march on Mihjelm, without waiting for smaller
                                    factions to be defeated.
                                </p>
                            </div>

                        </div>
                        <div className="col-lg-6">
                            <div className="timeline-item"><h4>3. CONFLICT OVER ANTIMATTER</h4>
                                <p className={"text-justify"}><em><TextInlineLink
                                    name={"bs_planet_vitvatersand"} />, <TextInlineLink
                                        name={"bs_planet_naskarae"} /></em></p>
                                <p className={"text-justify"}>
                                    The Mihjelms steal an antimatter arsenal on <TextInlineLink name={"bs_planet_vitvatersand"} text={"Vitwatersand"} />
                                </p>
                                <p className={"text-justify"}>
                                    Rebels from the Sunrise Echelon attempt a similar operation on <TextInlineLink name={"bs_planet_naskarae"} text={"Nascar"} />, but a tragic
                                    accident leads to complete destruction of the planet
                                </p>
                            </div>
                            <div className="timeline-item"><h4>4. UNEXPECTED DEFEAT OF THE EARTHLINGS</h4>
                                <p className={"text-justify"}><em><TextInlineLink name={"bs_planet_scellurae"} />, <TextInlineLink name={"bs_planet_the_night"} /></em>
                                </p>
                                <p className={"text-justify"}>
                                    Mergelis gathers fleets off <TextInlineLink name={"bs_planet_scellurae"} text={"Scellurae"} /> to attack Mihjelm, the total number of
                                    Earth-loyal troops is already several times greater than anything the Mihjelmians
                                    can put up. But the rebels don't wait for the attack and go on the offensive
                                    themselves, seeking to inflict maximum damage before the rest of the Mergellis'
                                    ships arrive in the system. The Mihjelmians conduct a tactically complex operation
                                    on the surface of Scellurae and use a vulnerable InterNetwork node to gain access to
                                    the internal communications of the entire Earth fleet
                                </p>
                                <p className={"text-justify"}>
                                    They then put the data they receive into public domain and hackers from across the
                                    galaxy take out most of the Monarch's government ships across the country in a
                                    matter of hours. Most of the machines at Scellurae are controlled by opponents of
                                    the regime and the fleet is forced to stop resisting
                                </p>
                                <p className={"text-justify"}>
                                    Taking advantage of the fact that the Earth fleet is paralyzed, the Mihjelms capture
                                    Coal one and Sonya Mergelis herself, finishing off the rest of the <TextInlineLink name={"bs_popups_army_echelon_of_the_fives"} text={"Echelon of The Fives"} /> ships that managed to withstand the hackers' attack.
                                </p>
                                <p className={"text-justify"}>
                                    The Mihjelmians, led by Otto Bjerne, lay siege to Earth and very quickly gain full control of the already defenseless capital world
                                </p>
                                <p className={"text-justify"}>
                                    Monarch Ciel Alcanarra orders his troops to lay down their arms - the civil war is over
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
