import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Hyperrun() {
    return {
        "science": <span>cosmography</span>,
        "use": <span>navigation</span>,
        "text":
            <>
                <p className='card-text text-justify'>
                    <strong>Hyperrun</strong> – a track for faster-than-light travel, guaranteed free of any large obstacles to smash into.
                </p>
                <p className='card-text text-justify'>
                    <strong>Major hyperruns:</strong>
                </p>
                <p
                    className='card-text text-justify'>
                    <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> - <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> - <TextInlineLink name={"bs_space_ternerian_end"} text={"Ternerian End"} />: the main trade route
                    between <TextInlineLink name={"bs_space_the_old_space"} text={"Old"} /> and <TextInlineLink
                        name={"bs_space_calliara_space"} />.
                </p>
                <p
                    className='card-text text-justify'>
                    <TextInlineLink name={"bs_space_hellesian_run"} text={"Hellesian run"} /> - <TextInlineLink name={"bs_space_ackron_nebulae"} text={"Akron nebula"} />: the busiest route
                    in the <TextInlineLink name={"bs_space_brienne_cluster"} text={"Brianne"} /> cluster, it carries
                    most
                    of the traffic between <TextInlineLink name={"bs_state_cydonian_priority"}
                        text={"Priority"} /> and <TextInlineLink
                        name={"bs_state_ajliraenian_domination"} text={"Domination"} />.
                </p>
                <p
                    className='card-text text-justify'>
                    <TextInlineLink name={"bs_planet_khal_vernon"} text={"Khal-Vernon"} /> - <TextInlineLink name={"bs_planet_new_havana"} text={"New Havana"} />: the largest highway in <TextInlineLink
                        name={"bs_state_free_worlds"} text={"Free Worlds"} /> linking the two most
                    populated and economically developed regions of <TextInlineLink name={"bs_space_samborae_sector"}
                        text={"Sambora"} />.
                </p>
                <p className='card-text text-justify'>
                    Cyrenaic way: several connected hyperruns between the Brianne and Calliara Space. Starts at <TextInlineLink name={"bs_planet_cyrenaic"} text={"Cyrenaic"} /> in <TextInlineLink name={"bs_space_arthuri_belt"} text={"Arthuri Belt"} /> and passes through worlds of <TextInlineLink name={"bs_space_shark_sector"} text={"Shark"} /> & <TextInlineLink name={"bs_space_the_night_sector"} text={"Night"} /> sectors, <TextInlineLink name={"bs_state_aethurian_republic"} text={"Aethurian"} /> territory and ends at <TextInlineLink name={"bs_planet_tribalon_at_alamein"} text={"Tribalon-at-Alamein"} />.
                </p>
            </>
    }
}
