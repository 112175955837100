import { ImgRound3EM } from "../../../../../components/imgs/imgRound";
import imgs from "../../../../../components/cms/images_repo.json";

export function Bs_org_Bonecollectors() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Type: </strong>
                        <span>private organization</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Members: </strong>
                        <span>
                            <ul className="characters-bar">
                                <li><ImgRound3EM img={"bs_char_can_beucan_gaereas_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_werner_rita_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_ursula_adams_1_jpg_200"} /></li>
                            </ul>
                        </span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Activities:</strong>
                        <span>saving people for a pretty penny</span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <img style={{ "maxWidth": "300px", "float": "left" }} alt="Bonecollectors Emblem"
                    src={imgs["bs_wiki_bonecollectors"].link}
                    fetchpriority="low" />
                <p className="card-text text-justify">
                    <span className="strong">Bonecollectors</span> is an international commercial organization that helps search for people in neutral territories and empty worlds. The services of the Bonecollectors are costly: searching for one person in an unexplored world requires a lot of resources and experience. In addition, rescuers often run into pirates or aggressive local creatures - on uninhabited planets anything can be waiting for them.
                </p>
                <p className="card-text text-justify">
                    Bones in the name because in most cases missing people are found already dead: poorly prepared wilderness tourists often find themselves alone with dangers they did not expect and challenges that even an experienced adventurer is not prepared for.
                </p>
            </>
    }
}
