import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from "../../../../../components/cms/images_repo.json";

export function Plato() {
    return {
        "is_a_ship": true,
        "is_a_class": false,
        "is_an_article": false,
        "class": <span>civilian <TextInlineLink name={"bs_ships_ship_classes"} text={"engineering vessel"} /></span>,
        "type": <span><TextInlineLink name={"bs_ships_redeyed"} /></span>,
        "size": <>800x140x60m</>,
        "defence": <span>anti-asteroid armor made of <TextInlineLink name={"bs_tech_space_steel"} text={"Space steel"} />, interference suppressors, Faraday cage</span>,
        "text":
            <>
                <h3 className="title-bg">About the ship</h3>
                <div style={{ "maxWidth": "320px", "padding": "0.25rem", marginRight: "0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "left" }}>
                    <img style={{ "maxWidth": "300px" }} alt="Plato" src={imgs["bs_ship_plato"].link} fetchpriority="low" />
                </div>
                <p className="card-text text-justify">
                    <span className="strong">ERF-1121.07 Plato</span> was the ship on which <TextInlineLink name={"bs_char_ursula_adams"} text={"Ursula Adams"} /> spent most of her childhood. It belonged to the private firm where her parents worked. The machine was leased along with a crew to explore planetoids in <TextInlineLink name={"bs_space_vikoveera"} text={"Vikoveera"} />, <TextInlineLink name={"bs_space_breeze_sector"} text={"Breeze"} />, and neighboring sectors.
                </p>
            </>
    }
}
