import { GetConnections, TextInlineLink } from "../../../components/cms/wiki_functions";
import { ImgRound3EM } from "../../../components/imgs/imgRound";
import { CrumbsBlackSunBook } from "../../../components/Bread_Crumbs";
import wiki from "../../../components/cms/wiki.json";
import imgs from "../../../components/cms/images_repo.json";

export function BookBonecollectors() {
    let repo = "book_bonecollectors"
    let name = wiki[repo].name
    document.title = "Cydonian Sun - " + name
    return (
        <>
            <div>
                <div className="row" key="crumbs">
                    <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book"></i>  Bonecollectors</span>} />
                </div>
                <div className="row row-alt">
                    <div className="col-lg-6">
                        <a data-fancybox href={imgs["book_bonecollectors_jpg_en"].link}>
                            <img className="img-max-full-width img-thumbnail-v2" alt="Cover of Bonecollectors"
                                src={imgs["book_bonecollectors_jpg_en"].link} />
                        </a>
                    </div>
                    <div className="col-lg-6">
                        <div className="row"><h1>Bonecollectors<small><br />or Birds of passage guide to Milky way (Part I)</small></h1></div>
                        <div className="row">
                            <blockquote>
                                <p>Closer to publication there will be quotes here, but for now only the distant howl of a beast breaks the
                                    the silence...</p>
                                <small><TextInlineLink name={"bs_char_skarsgard_oliver"} /></small>
                            </blockquote>
                        </div>
                        <div className="row">
                            <div className="alert alert-danger text-justify" role="alert">Not ready yet
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="21"
                                        aria-valuemin="0" aria-valuemax="100" style={{ "width": "27%;" }}>Progress: 27%
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <ul className="characters-bar">
                                <li><ImgRound3EM img={"bs_char_the_whip_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_ursula_adams_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_can_beucan_gaereas_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_werner_rita_1_jpg_200"} /></li>
                            </ul>
                        </div>
                        <div className="row">
                            <ul className="book-info">
                                <li><i className="fas fa-feather-alt"></i> <h6>Status:</h6> <strong><span
                                    className="text-info">in progress</span></strong></li>
                                <li><i className="far fa-calendar-alt"></i> <h6>Release date:</h6> ~2023</li>
                                <li><i className="far fa-list-alt"></i> <h6>Series:</h6> <TextInlineLink
                                    name={"bs_series"} text={"Cydonian sun #4"} /></li>
                                <li><i className="fas fa-angle-double-left"></i> <h6>Previous:</h6> <TextInlineLink
                                    name={"book_cydonian_autumn"} text={"Cydonian autumn"} /></li>
                                <li><i className="fas fa-angle-double-right"></i> <h6>Next:</h6> <TextInlineLink
                                    name={"book_helleon_cold"} text={"Helleon cold"} /></li>
                                <li><h6>Related pages:</h6><>{GetConnections(repo).map(conn => (conn))}</>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row row-alt">
                    <div className="col-lg-8">
                        <h3 className="title-bg">Annotation</h3>
                        <div className=" well">
                            <p className="card-text text-justify">
                                The story is a reformatted part of a larger work by <TextInlineLink name={"bs_char_ursula_adams"} text={"Ursula Adams"} /> (<TextInlineLink name={"bs_universe_bird_of_passage_guide_to_milky_way"} text={"Birds of passage guide to Milky way"} />). The story will consist of several tales, one of which is presented in the form of voluminous quotations from the Guide. To spare the reader, much of Ursula's text will be cropped out, especially the part where she describes with frightening sensitivity the amount of oxygen in the atmosphere of planets nobody cares about.
                            </p>

                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <h3 className="title-bg">Gallery</h3>
                        <ul className="characters-bar">
                            <li>Empty for now</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
