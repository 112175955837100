import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Surghanne() {
    return {
        "date": <span>~4000 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><span class="strike"><TextInlineLink name={"bs_state_the_great_monarchy"} /></span>, unknown</span>,
        "habitats": <span>190 million</span>,
        "people": <span>Surgannians, <TextInlineLink name={"bs_people_earthlings"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>none</span>,
        "g": <span>1.16 terrestrial</span>,
        "atmosphere": <span><span className='badge bg-success'>suitable for humans</span></span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span>Surganna</span>,
        "sector": <span><TextInlineLink name={"bs_space_sunrise_sector"} text={"Sunrise"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"agrarian world"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "imgs": [{ "short": "bs_wiki_surghanne_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Surganna</span> is the most populous and versatile agrarian planet in Sunrise Sector, known throughout the Monarchy for its orchards and chocolate factories. Surganna would’ve been the birthplace of Aaron Thorn... if he hadn’t been unceremoniously cut from the plot of <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">After <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />:</span>
                </p>
                <p className="card-text text-justify">
                    Surganna refused to supply its food to Earthlings and went international. Exporting food to the Elat Empire and the <TextInlineLink name={"bs_state_aethurian_republic"} text={"Aethurian republic"} /> proved surprisingly profitable.
                </p>
            </>
    }

}

