import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Anubis_gate_alpha() {
    return {
        "date": <span>1200 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "type": <span>yellow dwarf</span>,
        "stars": <span>1</span>,
        "planets": <span>22</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_ardimare_sector"} text={"Ardimare"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "population": <span>288 billion</span>,
        "imgs": [{ "short": "bs_wiki_anubis_gate_alpha_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Alpha</span> was discovered more than two thousand years ago, but it never got a real name. It's the brightest star in the constellation Gates of Anubis, visible only from the southern hemispheres of <TextInlineLink name={"bs_planet_cydonia"} /> and <TextInlineLink name={"bs_planet_hela"} />.
                </p>
                <p className='card-text text-justify'>
                    The star is on the edge of the country and the space around it is almost unpopulated, with the exception of <TextInlineLink name={"bs_planet_avvaline"} text={"Avvaline"} />, which is home to 99% of the system's population.
                </p>
            </>
    }
}

