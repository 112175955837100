import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Earth() {

    return {
        "date": <span>~206 000 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><s><TextInlineLink name={"bs_state_the_great_monarchy"} /></s>, unknown</span>,
        "habitats": <span>281 billion (79 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_earthlings"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>Luna, The Moon</span>,
        "g": <span>1.0 terrestrial *sarcastic smile*</span>,
        "atmosphere": <span><span className='badge bg-success'>suitable for humans</span>, polluted</span>,
        "biosphere": <span>dead</span>,
        "system": <span><TextInlineLink name={"bs_star_sun_zero"} text={"Sun Zero"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_sector_zero"} text={"Sector Zero"} /></span>,
        "type": <span>earth-like (ironic) <TextInlineLink name={"bs_universe_planet_types"}
            text={"metropolis"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "imgs": [{ "short": "bs_wiki_earth_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears": [
            <div className="col-lg-2 col-md-6 text-center">
                <Appearance name={"book_cydonian_girl"} />
            </div>
        ],
        "text": <>
            <h3 className="title-bg">History</h3>
            <p className='card-text text-justify'>
                <span className='strong'>Earth</span> – the ancestral home of humans as a
                species. Over the last few thousand years, it has become overgrown with huge megalopolises and deprived of all sources of fresh water - you have to drink filtered waste.
                The polar caps melted long ago, the once endless
                Amazonian and Asian forests have become parklands, and the mountain ranges of the Himalayas look like mouse-eaten
                cheese. If only mice left behind concrete tunnels and glass facades.
            </p>
            <p className='card-text text-justify'>
                <span className='strong'>After <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />:</span>
            </p>
            <p className='card-text text-justify'>
                After a power grid disruption in the Milan area, the government of
                Earth has invested enormous sums in backup power units around the planet.
            </p>
            <p className='card-text text-justify'>
                Rebellion, the devastating civil war that followed, and the siege
                of the capital led to a shortage of basic necessities on Earth and a sharp rise in the
                the prices of almost everything. At the same time, billions of people lost their jobs. The economy of the metropolis
                was on the brink of collapse already, but the conflict and the fall in the value of Earth's currency have finally destroyed it.
                Most likely, the earthlings will not be able to retain even part of the past territories. They'll be lucky if there's
                at least a couple of agrarian planets left to grow food.
            </p>
        </>
    }
}

