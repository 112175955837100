let quotes = {
    /*"bs_space_tiara_minor_nebulae": [
        {
            "text": <p>SOME FORMATTED TEXT</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],*/
    /*"bs_tech_abspace": [
        {
            "text": <p>SOME FORMATTED TEXT</p>,
            "author": <>Тошико, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>– SOME FORMATTED TEXT </p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_colbert_simone"}/> and <TextInlineLink popup={""}
                                                                                                       name={"bs_char_hartrey_kellays"}/>, <TextInlineLink
                name={"book_hyperbeacons"} popup={""}/></>,
        }
    ],*/
}

function GetQuotes(props) {
    let quo = quotes[props.name]
    let ret = []
    let menu = []
    for (let currentElement in quo) {
        let elem = quo[currentElement]
        let cl = "carousel-item"
        if (currentElement === "0") {
            cl = "carousel-item active"
            menu.push(<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0"
                className="active" aria-current="true" aria-label="Slide 1"></button>)
        } else {
            menu.push(<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={currentElement}
                aria-label={"Slide " + currentElement}></button>)
        }
        ret.push(<div className={cl}>
            <blockquote>
                {elem.text}
                <small>{elem.author}</small>
            </blockquote>
        </div>)
    }

    return <div id="carouselExampleCaptions" data-bs-interval="false" style={{ "paddingBottom": "1em" }}
        className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators ca-blue">
            {menu}
        </div>
        <div className="carousel-inner">
            {ret}
        </div>
    </div>
}

export { GetQuotes }