import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function The_sunrise_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"cosmographic unit"} /></span>,
        "owned": <span>questionable</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "capital": <span>Sunrise</span>,
        "people": <span><TextInlineLink name={"bs_people_earthlings"} /></span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Sunrise Sector</span> is an area on the other side of the <TextInlineLink name={"bs_space_sector_zero"} text={"Sector Zero"} /> from the Night. The Sunrise has suffered greatly from the <TextInlineLink name={"bs_timeline_galactic_war"} text={"Galactic War"} />. It was once the nesting ground for industrial agglomerations of entire star systems, but due to its unfortunate location it was hit when the <TextInlineLink name={"bs_people_agathonians"} text={"Agathonians"} />, the <TextInlineLink name={"bs_people_ajliree"} text={"Eilireé"} />, and the <TextInlineLink name={"bs_people_cydonians"} text={"Cydonians"} /> went head-to-head at the end of the War. The front line crawled back and forth and burned planet after planet until there was simply nothing left to fight over.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">
                        After <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />:
                    </span>
                </p>
                <p className="card-text text-justify">
                    The Rebellion of the Spiders and the Sunrise Echelon has left the sector in utter turmoil. Most of
                    the planets are demanding independence, but some plan to form a single republic with the old Sunrise
                    boundaries. This issue will not be resolved for many years to come.
                </p>
            </>
    }
}

