import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Hartrhon() {
    return {
        "homeworld": <span>unknown</span>,
        "size": <span>unknown</span>,
        "type": <span>mythological</span>,
        "pantheon": <span><TextInlineLink name={"bs_universe_religion_arthurians"} /></span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Harthron</span> - a creature shrouded in mystery, legend, and just the right amount of nightmare fuel. Was it a giant spider, an octopus, or maybe something like a cosmic jellyfish? No one really knows, because no “official” records of Harthron have survived – only whispers in <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /> folklore. Whether it ever existed (or still does) somewhere in the vast depths of space is anyone’s guess. According to legend, it could travel between planets, devouring both organic life and electronics. Some mythology enthusiasts remain convinced that Harthron is out there, stalking lonely ships on hypertrails. Additionally, it is said that this space terror was created by an ancient, powerful civilization, which it later consumed.
                </p>
            </>
    }
}
