import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Lenisaad_victor() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "name": "Victor Lenisaad",
        "born": <span>56 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>55</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_esghariot_en_nuvnish"} /></span>,
        "fullname": <span>Victor <small>‘Bold beard’, ‘Lennie’</small> Lenisaad</span>,
        "nation": <span><TextInlineLink name={"bs_people_breezeans"} text={"Breezean"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "relatives": <span>perished along with their home planet</span>,
        "work": <span><s><TextInlineLink name={"bs_comp_free_trade_league"} /> representative in <TextInlineLink
            name={"bs_space_the_old_space"} /></s></span>,
        "img_trans": false,
        "imgs": [
            { "short": "bs_char_lenisaad_victor_1_jpg_500", "big": "bs_char_lenisaad_victor_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Victor</span> was born 18 years before Galactic war, on the planet <TextInlineLink name={"bs_planet_esghariot_en_nuvnish"} text={"Esghariot-en-Nouvnish"} />, on the outskirts of the <TextInlineLink name={"bs_space_breeze_sector"} text={"Breeze Sector"} />. Esghariot was a filthy industrial center, with smoky skies, acid rains, and almost dead biosphere. It was predicted to last another couple of decades, after which the planet would become completely uninhabitable. But the <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> industrialists did not stop it: there are no unified laws in the Breeze, and they had every right to do with the planet whatever they wanted.
                </p>
                <p className='card-text text-justify'>
                    Victor quickly realized that the galaxy did not care about his home region and the unfortunate <TextInlineLink name={"bs_people_breezeans"} text={"Breezeans"} />. So he was determined by all means to get rich and at least free his planet from the oppression of the corporatocrats. Then the <TextInlineLink name={"bs_timeline_galactic_war"} text={"Galactic War"} /> broke out and he volunteered, realizing that the Earthlings were behaving even worse than the <TextInlineLink name={"bs_people_agathonians"} text={"Agathonians"} />. And when the war was over, he was horrified to find his homeworld scorched by orbital strikes of the <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> fleet in a desperate attempt to postpone the inevitable defeat even slightly. Since then, <TextInlineLink name={"bs_char_lenisaad_victor"} text={"Victor"} /> has hated the <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Monarchy"} />, <TextInlineLink name={"bs_planet_earth"} text={"Earth"} />, and everything associated with it. He became a pirate because he'd never learned anything in his life. And to feel no remorse for his occupation, he went exactly to Earth, where he felt no pity for anyone.
                </p>
                <p className="card-text text-justify">
                    About 13 years <TextInlineLink name={"bs_timeline"} text={"before Tamanrasett"} />, he met <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar De Karma"} />, who then moved on to freelance work, but often gave Victor unusual orders.
                </p>
                <p className="card-text text-justify">
                    After the events of <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} /> he wanted to set up a business of trafficking in smuggled cultural treasures, but a sudden contact with unknown creatures disrupted his plans.
                </p>
                <p className="card-text text-justify">
                    In addition, the poor man is also wanted by the <TextInlineLink name={"bs_comp_free_trade_league"} text={"Free Trade League"} /> for what he did on Earth: Victor drew attention to local smugglers when he helped a fugitive official fly off the planet, and thereby put the supply chain of illegal goods at risk. In the face of this, the League incurred insane losses and put an even higher reward for his capture than the Agathon government.
                </p>
                <h4 className="title-bg">Evolution of the character</h4>
                <p className='card-text text-justify'>
                    Victor has the saddest evolution in the story. He started out as a powerful superhuman living for a thousand years and ruling an entire planet with an army of genetically modified superhumans.
                </p>
                <p className='card-text text-justify'>
                    Then he got relegated: the plot became overloaded, all these stories about supermen and other mutants seemed dull and monotonous and too reminiscent of the storyline of Rosalie. Victor was demoted first to the leader of a criminal syndicate called the International Mercenary Army (I love pathos), and then to an ordinary pirate, which is how he appears to the reader in <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />.
                </p>
                <p className='card-text text-justify'>
                    That said, the Army itself was already cut out simply because there was no room left in the galaxy for such powerful organizations: according to the legend, it was supposed to have connections in almost every government and send mercenary thugs anywhere in space. But that would have made achieving the heroes' goals too easy, and Victor himself would have turned out to be a bearded Mr. Sue, which is not good for the image.
                </p>
            </>
    }
}
