import { TextInlineLink } from "../../../../../components/cms/wiki_functions";

export function Arthur_the_founder() {
    return {
        "name": "Arthur Caliburn",
        "fullname": <span>Arthur <small>The Founder</small> Caliburn</span>,
        "born": <span>~4900 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>unknown</span>,
        "homeworld": <span>unknown <TextInlineLink name={"bs_tech_hiveship"} text={"hiveship"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_alamarsie"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "status": <span className={"text-danger"}>missing/dead</span>,
        "imgs": [
            { "short": "bs_char_arthur_the_founder_1_jpg_500", "big": "bs_char_arthur_the_founder_1_jpg_full" },
        ],
        "pint": "",
        "text":
            <>
                <h3 className="title-bg">Historical persona</h3>
                <p className="card-text text-justify">
                    <span className="strong">Arthur</span> was a semi-legendary <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /> captain who lived approximately 4,900 years before the events of the <TextInlineLink name={"bs_series"} text={"Cydonian Sun"} />. Arthur's existence is confirmed by many independent sources: the journals of <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> colonies at the time and captains' diaries found in the wreckage of lost ships. So many have accumulated that even the most skeptical scholar cannot deny at least the Founder's reality as a historical figure.
                </p>
                <p className="card-text text-justify">
                    However, the extensive list of deeds attributed to him is still up for debate. If the diaries of his contemporaries are to be believed, Arthur disappeared under very mysterious circumstances near the Hedwig star system. This happened shortly after the unification of the Alamarsie into a single fleet and made his cult even more popular: people love mysterious stories.
                </p>

            </>
    }

}