import { TextInlineLink } from "../../../../../components/cms/wiki_functions";
import { Appearance } from "../../../../../components/appearance";
import imgs from '../../../../../components/cms/images_repo.json';

export function Cydonia() {

    return {
        "date": <span>~4800 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>192 billion (23 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>planetary ring</span>,
        "g": <span>1.04 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed minimally, almost no fauna, very lush flora</span>,
        "system": <span><TextInlineLink name={"bs_star_mirage"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_ariadne_sector"} text={"Ariadne"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_hellesian_run"} /></span>,
        "imgs": [{ "short": "cydonia_500", "big": "cydonia" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Brief</h3>
                <a data-fancybox="true" href={imgs["bs_wiki_oecumene_map_en"].link}>
                    <img className="img-thumbnail-v2" style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }}
                        alt="Cydonia" src={imgs["bs_wiki_cydonia_map_en"].link} fetchpriority="low" />
                </a>

                <p className="card-text text-justify">
                    <span className="strong">Cydonia</span> – is not just the capital of the Cydonian Priority. It is the beating heart of the galaxy’s cultural and political life. A planet of unmatched beauty, Cydonia’s shimmering rings, lush gardens, and serene landscapes reflect the delicate balance between nature and civilization that defines the Priority’s ethos. From orbit, the planet looks like a perfect jewel: green fields streaked with rivers and lakes, veiled by clouds, and surrounded by icy rings that gleam in the light of its sun, Mirage.
                </p>
                <p className="card-text text-justify">
                    Cydonia’s surface is a tapestry of botanical wonders: rolling meadows of alien grasses, intricate forests of ferns, and expanses of flowering groves that shift colors with the cycles of the month. This makes it both a paradise for horticulturists and a place of pilgrimage for those seeking tranquility. While modern technology exists on the surface, much of it is concealed, giving visitors the impression that nature reigns supreme.
                </p>
                <p className="card-text text-justify">
                    The capital planet is a symbol of unity and restraint, where progress never outpaces harmony with the environment. Real estate laws are strict, limiting the size of cities to maintain the world’s garden-like appearance. The largest cities blend seamlessly into forests and parks, with green roofs, hidden infrastructure, and pathways winding between towering trees. Even the most powerful officials must abide by ancient zoning regulations, leaving most of the planet’s surface untouched.
                </p>
                <h4 className="title-bg">Mount Panmore and the Dawn of Civilization</h4>
                <p className="card-text text-justify">
                    The first settlers of Cydonia made landfall atop the rocky formation known today as Mount Panmore. It remains one of the most revered places on the planet: a monument to exploration, perseverance, and humility. The jungle surrounding the peak is cold, mist-shrouded, and damp, with trails leading to a modest memorial at its summit. Here, visitors can find monuments carved into the stone, honoring the early pioneers who made Cydonia their home. It is tradition for new visitors to leave a small flower or branch at the memorial, an offering that represents the growth and renewal the planet symbolizes.
                </p>
                <p className="card-text text-justify">
                    Though Cydonia’s history stretches back thousands of years, it is still connected to Earth by heritage. The planet’s name draws inspiration from an ancient Cretan polis, an echo of the human past that remains embedded in Cydonian identity. However, the planet’s story is also one of isolation and independence: for centuries, travel between Cydonia and Earth was slow and arduous, forcing the colonists to build a society that could stand on its own.
                </p>
                <h4 className="title-bg">Climate and Ecology</h4>
                <p className="card-text text-justify">
                    Cydonia’s minimal axial tilt gives it a perpetual spring—a world without the ebb and flow of seasons. Each region maintains a relatively stable temperature, ranging from cool temperate zones to warm, subtropical areas. Rainfall is frequent, draping the planet in lush greenery. While flowers bloom year-round, the lack of traditional seasons creates a strange timelessness—a feature that is both enchanting and disorienting to those accustomed to Earth’s seasonal cycles.
                </p>
                <p className="card-text text-justify">
                    The absence of large animal life has shaped an ecosystem dominated by plants, insects, and bats. In place of birds, bat-like creatures evolved to pollinate flowers and control insect populations. Some of these bats are native species, while others were imported and adapted to Cydonia’s ecosystems. Insects thrive in staggering diversity, filling every ecological niche and driving the pollination cycle. The buzzing of these insects is a constant background hum, giving Cydonia’s wilderness a unique, dreamlike atmosphere.
                </p>
                <p className="card-text text-justify">
                    Because of the planet’s rich flora and damp climate, agriculture here is unique. Rather than large-scale farming, most food production is integrated into orchards and botanical gardens scattered across the planet. Fresh produce, herbal medicines, and flowers are abundant, and gardeners are highly esteemed professionals, responsible not just for food but for the preservation of Cydonia’s environment.
                </p>
                <h4 className="title-bg">Identity</h4>
                <p className="card-text text-justify">
                    Life on Cydonia reflects a deep philosophy of balance—a principle that permeates everything from architecture to governance. Unlike the sprawling megacities found on other worlds, Cydonian cities are small but densely packed, designed to blend seamlessly into their surroundings. Bridges made of living vines cross rivers, and libraries sit under canopies of ancient trees, while theaters and galleries are tucked into hillside groves. Inhabitants move on foot or by electric boats, gliding through canals that snake through the forests.
                </p>
                <p className="card-text text-justify">
                    This commitment to restraint extends to social and political life. Those born on Cydonia are expected to learn multiple languages and develop diplomatic skills, making them ideal representatives of the Priority on the galactic stage. Opulence is frowned upon, with personal prestige measured not in wealth but in knowledge, kindness, and contributions to the community. Festivals celebrating art, philosophy, and nature are held regularly, drawing dignitaries and visitors from across the galaxy.
                </p>
                <h4 className="title-bg">Place in the Galaxy</h4>
                <p className="card-text text-justify">
                    Despite its idyllic appearance, Cydonia is not just a paradise; it is also a political powerhouse. The planet serves as the seat of the Priority’s central government, where the highest councils and assemblies meet to shape the future of the galaxy. While its surface population is kept small at 23 billion, megacities exist on several levels of orbit, housing the rest of the system's 192 billion residents. These networks designed with the same care as the surface and from the inside look like vast, glittering gardens in the midst of the void.
                </p>
                <p className="card-text text-justify">
                    Though Cydonia does not project military power directly, its diplomatic influence is unmatched. Leaders from across the galaxy recognize the planet’s importance as a center of culture and policy. Treaties and trade agreements are often sealed in Cydonian gardens, where the calm surroundings are said to encourage peace and understanding. The planet’s rings of dust and ice, visible from anywhere on the surface, serve as a constant reminder of both the beauty and fragility of the galaxy.
                </p>
                <p className="card-text text-justify">
                    Cydonia is a planet of contradictions: ancient and futuristic, natural yet meticulously maintained, serene but quietly powerful. Its citizens carry a profound sense of responsibility—to their home, their history, and the galaxy at large. As the heart of the Cydonian Priority, the planet stands as a symbol of unity, beauty, and diplomacy, offering both a glimpse into the past and a vision of a balanced future. For those lucky enough to walk its gardens or gaze upon its shimmering rings, Cydonia is not just a capital—it is a reminder that greatness is not always found in size or strength, but in the ability to live in harmony with the world around you.
                </p>
            </>
    }
}
