import { TextInlineLink } from "../../../../../components/cms/wiki_functions";

export function Doab() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">All Bombs Day</span> is the most anticipated and beloved holiday across the worlds of <TextInlineLink name={"bs_state_cydonian_priority"} text={"Priority"} />, a festive blowout that makes <TextInlineLink name={"bs_planet_earth"} text={"Earth's"} /> New Year look like a nap. Centuries ago, the rebellious inhabitants of Priority broke free from the clutches of their <TextInlineLink name={"bs_people_earthlings"} text={"Earthling"} /> overlords, and in a triumphant display of unity, raised their own flag high across the galaxy. Now, every year, they celebrate with a bang – literally – honoring their independence and the unification of their planets under one banner. It’s not just a holiday, it’s a galactic-level celebration of freedom, unity, and, of course, a bit of defiance.
                </p>
            </>
    }
}