import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Laser() {
    return {
        "type": <span>energy weapon</span>,
        "use": <span>all target types</span>,
        "text":
            <>
                <h4 className="title-bg">Lasers in times of <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />: 1 year <TextInlineLink name={"bs_timeline"} text={"BT"} /></h4>
                <p className="card-text text-justify">
                    <span className="strong">Laser </span> or <span className="strong">emissionary</span> –
                    a weapon that emits coherent radiant energy. The word laser is an acronym for –
                    light amplification by stimulated emission of radiation. Basically, a laser gun transfers energy
                    from a power source into some working substance (e.g. gas) and turns it into a stream of radiation.
                    At the time of the <TextInlineLink name={"book_cydonian_girl"} text={"Cydonian girl"} />, the technical development of mankind did not allow to make a laser capable of penetrating a ship's armor in a reasonable time: <TextInlineLink name={"bs_tech_space_steel"} text={"space steel"} /> is too strong and it would take too much energy to cut it. In addition, the maneuvering engines allowed to change the position of the ship fast enough for the armor to have time to cool down with the help of internal cooling systems. The same was true for the infantry: armor protected soldiers well enough against modern lasers, which, moreover, required more delicate handling than other weapon systems.
                </p>
                <p className="card-text text-justify">
                    So while lasers were actively used in Underlight age and at the <TextInlineLink name={"bs_timeline_light_era_dawn"} text={"Dawn of the Light Age"} />, their use quickly waned after the invention of more advanced protection systems. Because of this, the vast majority of combat vehicles use rail guns for offense, and laser guns for defense against missile weapons, space mines, and asteroids.
                </p>
                <p className="card-text text-justify">
                    On the other hand, advances in <TextInlineLink name={"bs_tech_shield_kinetic"} text={"kinetic shields"} /> capable of stopping projectiles threatened to bring lasers back to their former glory - a regression field can only stop a solid object and is ineffective against radiation.
                </p>
            </>
    }
}
