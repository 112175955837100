import { TextInlineLink } from "../../../../../components/cms/wiki_functions";

export function Alkanarra_tristan() {
    return {
        "name": "Tristan Alcanarra",
        "fullname": <span>Tristan <small>The Conqueror</small> Alcanarra</span>,
        "born": <span>~4200 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>~200</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_earth"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_earthlings"} text={"Earthling"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "imgs": [
            { "short": "bs_char_alkanarra_tristan_1_jpg_500", "big": "bs_char_alkanarra_tristan_1_jpg_full" },
        ],
        "pint": "",
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify">
                    <span className="strong">Tristan</span> is the first of the Alcanarra dynasty that ruled Earth's territories for about 4,200 yeas ago. Contrary to popular myth, he was not born in the filthy slums of <TextInlineLink name={"bs_planet_earth"} text={"Earth"} />, but in an elite metropolis on the surface of the Luna, Earth's moon. His parents were a businessman and a high society lady, though in time he tried to hide this fact of his biography: the people should have thought that their ruler was as simple a guy as they were.
                </p>
                <p className="card-text text-justify">
                    Closer to 16, Tristan moved with his parents to the surface of Mother Earth, where life was markedly different from the well-fed cities of the satellite. Most of humanity's ancestral homeland was already slums or wastelands with traces of resource extraction. The inhabitants of these places often worked for a small fortune in the enterprises of businessmen like Alcanarra Senior. However, this did not prevent Tristan from finding common ground with them.
                </p>
                <p className="card-text text-justify">
                    Contrary to the rest of the boys of his social status, he preferred to socialize not with his classmates from the prestigious school, but with the boys from the slums. There he realized that his expensive clothes, manners, and education not only arouse envy and desire to rob. They were empowering. Tristan's charisma allowed him to blend into local circles and acquire the most real gang. The guy charmed the local guys by convincing them that he was one of them at heart. He just had better luck in this life. He promised his new acquaintances that he would help them out when he gets his own business. And the street took him in, teaching him all the dirty tricks that the average citizen of the earthly slums of the early Light Age learned.
                </p>
                <p className="card-text text-justify">
                    So Earth's alleys gave him many useful friendships, most of which were future racketeers, burglars, hit men, and simply hired thugs. As a young man, Tristan had learned how to find such people, gain their trust, and use their services.
                </p>
                <p className="card-text text-justify">
                    It was with their help that he did business. In the future, after receiving money from his father for his own enterprise, Tristan immediately began by intimidating the competitors and anyone who could cause the slightest problems. He created for himself a local monopoly, first on the normal legitimate trade in auto parts, and then on the illegal arms trade. By the time he was 30, the founder of the "greatest dynasty", as some call it, was known as Tristan the Gunslinger. And by 40, the guy controlled most of the shady gun trafficking in the solar system.
                </p>
                <p className="card-text text-justify">
                    But he did not want to stop there. Tristan's dream was power not only over street thugs, but also over the earthly bohemia. Alcanarra saw how weak the governments of humanity's ancestral homeland were and understood how easy it would be for a man with resources to take his share.
                </p>
                <p className="card-text text-justify">
                    Numerous wars had weakened the Earth leaders, and the peoples were tired of hearing promises of eternal prosperity that would come with the next established colony in N years. So Tristan decided to transfer the principle he had subjugated the street to an entire galaxy.
                </p>
                <p className="card-text text-justify">
                    He used the colossal resources available to be elected leader of one of the flimsy Earth states, whose name is not preserved in history. In that position, Tristan offered his colleagues from other influential powers a simple and reliable plan: how to reduce the peoples' discontent and get rich. All that was needed was to equip a small war fleet, of which many had already been sent into the black void, and instead of completely conquering the straggling colonies, start simply plundering them.
                </p>
                <p className="card-text text-justify">
                    Earthmen were obsessed with controlling other worlds, but it was more profitable to force those worlds to send resources to Earth not by force, but by fear. With the most advanced light engines and weapons at the time, the soldiers of the combined Earth fleet were able to reach the developed colonies relatively quickly and give them a choice: either you send a ship with food and other useful materials to us once a year, or we devastate your planet and just take this stuff away.
                </p>
                <p className="card-text text-justify">
                    The colonies, as a rule, had neither the resources, population, nor even the trivial technology to build a full-fledged army: for a hundred thousand fighters sent from Earth, they were able to put up no more than a thousand. That used to be enough, since the war was mostly guerrilla warfare. Earthlings spent resources on one distant world without being able to attack others. They had to build up their forces for decades to attack again. But Tristan's scorched-world tactics changed the game: it was now more profitable to pay tribute than to resist.
                </p>
                <p className="card-text text-justify">
                    In less than 30 years, Tristan was able to enslave dozens of worlds while remaining in power. Resources flowed to Earth, enough to lift billions of people out of poverty, even with apocalyptic levels of corruption. This gave Alcanarra the support of the peoples and complete freedom of action: de jure remaining the leader of one of the many countries, he became the de facto ruler of all Earth's foreign policy. And he was not interested in internal one.
                </p>
                <p className="card-text text-justify">
                    Tristan lived a very long life, never stopping for a day on his way to conquer the Old Space. He was one of the first Homo sapiens to live to the age of 200, an insane figure for those days. Even ultra-rich people lived no more than 120.
                </p>
                <p className="card-text text-justify">
                    He died believing that he had created the first interstellar empire in history. However, a full-fledged state was still a long way off: Tristan's descendants, who seized power after him, took centuries to place their administrations on the "conquered" planets. And even after that, communication problems and slowness of connections led to the fact that after one or two generations, the inhabitants of the colonies forgot that they were part of an empire. They simply paid tribute to the ancestral homeland of mankind, considering it a kind of tax on the absence of problems, and built such a society as they wanted.
                </p>
                <p className="card-text text-justify">
                    Nevertheless, Tristan's contribution to history (however grim) should not be diminished. He accomplished the impossible: he got one planet, trillions of kilometers away from another, to take control of that very other planet and "convince" it to share its resources. Alcanarra showed: interstellar empires can exist, although before him many considered the war for planets only as a stupid and unprofitable idea.
                </p>
                <p className="card-text text-justify">
                    Empires, as is always the case, were followed by the first interstellar republics. After his crusades, people went to distant worlds, deliberately planning to settle several planets and immediately unite them into one state: for profitable trade and, of course, defense. Colonists took with them blueprints and equipment to create technologies of orbital defense, entire research institutes secretly moved from Earth to <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} />! People began to think globally, considering the planets only as a source of resources, rather than an anchor to which the civilization is attached.
                </p>
            </>
    }
}