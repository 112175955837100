import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function EsghariotEnNuvnish() {

    return {
        "date": <span>~5100 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span>none</span>,
        "habitats": <span>0</span>,
        "moons": <span>none</span>,
        "g": <span>0.96 terrestrial</span>,
        "atmosphere": <span className="badge bg-danger">life-threatening</span>,
        "biosphere": <span className="badge bg-danger">destroyed</span>,
        "system": <span>Esghariot</span>,
        "sector": <span><TextInlineLink name={"bs_space_breeze_sector"} text={"Breeze"} /></span>,
        "type": <span>earth-like</span>,
        "region": <span><TextInlineLink name={"bs_space_calliara_space"} /></span>,
        "imgs": [{ "short": "bs_wiki_esghariot_en_nuvnish_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">About the world</h3>
                <p className="card-text text-justify">
                    <span className="strong">Esghariot-en-Nouvnish (Dead Esghariot)</span> – industrial world on the outskirts Breeze sector. The name translates from <TextInlineLink name={"bs_people_alamarsie"} /> as stone with useful ore. It was discovered by the nomads it a huge number of years ago, the first traces of mining here date back only a couple of centuries after the spread of light flight. Apparently, Alamarsie stationed here to repair ships and synthesize fuel. There are even ancient navigational beacons. In times of <TextInlineLink name={"bs_timeline_galactic_war"} text={"Galactic War"} /> <TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} /> tried to capture Esghariot to weaken <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> industry, but failed before the arrival of Agathon reinforcements.
                </p>
                <p className="card-text text-justify">
                    As a result, <TextInlineLink name={"bs_char_mereglis_sonya"} text={"Sonya Mergelis"} />, who commanded the operation, gave the order to discharge the entire arsenal of <TextInlineLink name={"bs_ships_nyorun_dreams"} /> into the planet. Within a few hours
                    ninety-eight percent of the population there died, and the planet became uninhabitable for millions
                    years to come.
                </p>
            </>
    }
}

