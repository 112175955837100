import { CrumbsBlackSunTechnologies } from "../../../../../components/Bread_Crumbs";
import { GetQuotes } from "../../../../../components/Quotes";
import { GetConnections } from "../../../../../components/cms/wiki_functions";
import { Abspace } from "./abspace";
import { Amnesiac } from "./amnesiac";
import { Antimatter } from "./antimatter";
import { Engine_atmospheric } from "./engine_atmospheric";
import { Engine_main } from "./engine_main";
import { Engine_overlight } from "./engine_overlight";
import { Engine_shunting } from "./engine_shunting";
import { Grn } from "./grn";
import { Hiveship } from "./hiveship";
import { Hyperrun } from "./hyperrun";
import { Ils } from "./ils";
import { Lazarus } from "./lazarus";
import { Mpg } from "./mpg";
import { Northern_star_of_niradanhae } from "./northern_star_of_niradanhae";
import { Shield_atmospheric } from "./shield_atmospheric";
import { Shield_kinetic } from "./shield_kinetic";
import { Shield_sound } from "./shield_sound";
import { Shield_static } from "./shield_static";
import { Somnambule } from "./somnambule";
import { The_key } from "./the_key";
import { Uns } from "./uns";
import cats from "../../../../../components/cms/wiki_cats.json";
import wiki from "../../../../../components/cms/wiki.json";
import { Inter_branal_positioning_beacon } from "./bs_tech_inter_branal_positioning_beacon";
import { Bs_science_metadimensional_mechanics } from "./bs_science_metadimensional_mechanics";
import { Bs_tech_space_steel } from "./bs_tech_space_steel";
import { HasSpoilers } from "../../../../../components/spoilers";
import { Bs_science_astromechanics } from "./bs_science_astromechanics";
import { Bs_science_cosmography } from "./bs_science_cosmography";
import { Bs_science_planet_engineering } from "./bs_science_planet_engineering";

let list = {
    "overspace": Abspace,
    "amnesiac": Amnesiac,
    "antimatter": Antimatter,
    "engine-atmospheric": Engine_atmospheric,
    "engine-main": Engine_main,
    "engine-overlight": Engine_overlight,
    "engine-shunting": Engine_shunting,
    "galactic-relay-network": Grn,
    "hiveship": Hiveship,
    "hyperrun": Hyperrun,
    "individual-lifesupport-suite": Ils,
    "lazarus": Lazarus,
    "inertial-compensator": Mpg,
    "northern-star-of-niradanhae": Northern_star_of_niradanhae,
    "atmospheric-shield": Shield_atmospheric,
    "kinetic-shield": Shield_kinetic,
    "sound-shield": Shield_sound,
    "static-shield": Shield_static,
    "somnambulist": Somnambule,
    "the-key": The_key,
    "universal-signatures": Uns,
    "positioning-beacon": Inter_branal_positioning_beacon,
    "metadimensional-mechanics": Bs_science_metadimensional_mechanics,
    "space-steel": Bs_tech_space_steel,
    "astromechanics": Bs_science_astromechanics,
    "cosmography": Bs_science_cosmography,
    "planetary-engineering": Bs_science_planet_engineering,
}

/*
return {
        "science": ,
        "use": ,
        "text":
            <>
            </>
    }
 */

export function BSTech(repo) {
    if (!(repo in list)) {
        return null
    }
    let repos = cats["bs_tech"]
    if (!(repos[repo] in wiki)) {
        return null
    }
    let name = wiki[repos[repo]].name
    document.title = name + " || Cydonian girl & Cydonian sun"

    let conns = GetConnections(repos[repo])
    let data = list[repo]()

    return (
        <>
            <CrumbsBlackSunTechnologies name={name} />

            {data["has_spoilers"] ?
                <HasSpoilers list={data["spoilers_list"]} />
                :
                <></>
            }
            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h1>{name}</h1></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={repos[repo]} />
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        {data["science"] ? <li><i className="fas fa-chevron-right"></i> <strong>Area of
                                            Knowledge: </strong> {data["science"]}</li> : <></>}
                                        {data["knowledge_area"] ?
                                            <li><i className="fas fa-chevron-right"></i> <strong>Area of
                                                Knowledge: </strong> {data["knowledge_area"]}</li> : <></>}
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        {data["use"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Application:</strong>{data["use"]}</li> : <></>}
                                    </ul>
                                </div>
                            </div>
                            {conns.length > 0 ? <p><strong>Related articles:</strong> <>{conns.map(conn => (conn))}</>
                            </p> : <></>}
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    {data["text"]}
                </div>
            </div>
        </>
    )
}