import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Tag } from 'antd';

export function Alkiviad() {

    return {
        "date": <span>320 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"} /></span>,
        "habitats": <span>classified</span>,
        "moons": <span>none</span>,
        "g": <span>1.15 terrestrial</span>,
        "atmosphere": <Tag color="red">harmful to humans</Tag>,
        "biosphere": <span><Tag color="volcano">none</Tag></span>,
        "system": <span>Alcibiades</span>,
        "sector": <span><TextInlineLink name={"bs_space_vikoveera"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"industrial"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_calliara_space"} /></span>,
        "imgs": [{ "short": "bs_wiki_alkiviad_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Alcibiades-4</span> – the fourth planet from the star of the same name, a small industrial world in the Calliara Space. For several centuries it had been
                    mined by Agathon ore companies, but when the resources were noticeably depleted and the atmosphere became
                    unbreathable, mining became unprofitable. The state helped corporations get rid of the
                    unprofitable asset: it bought the planet and built several thousand labor camps on the surface.
                </p>
                <p className='card-text text-justify'>
                    Here criminals whose misdeeds were bad enough for society to accept the harshest of the punishments allowed in the Republic: deadly hard labor. Unlike other prisons, where inmates engaged in safe and relatively clean industries, Alcibiade's colonies provide only
                    the opportunity to spend decades in dark, dirty mines. If you're lucky, you can get into a quarry in the
                    fresh air (though only a life form with no lungs would call it <span className="strong">fresh</span>), but your existence
                    won't get any better.
                </p>
            </>
    }
}

