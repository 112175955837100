import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Cydonians() {
    return {
        "homeworld": <TextInlineLink name={"bs_planet_cydonia"} />,
        "state": <TextInlineLink name={"bs_state_cydonian_priority"} />,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_de_levine_rosalie_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_de_karma_ivar_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_van_gloria_adel_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_skarsgard_oliver_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_skarsgard_vivien_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_hartrey_kellays_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_colbert_simone_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_jasmine_haervany_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_werner_rita_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_de_vaal_leon_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_de_levine_clara_1_jpg_200"} /></li>
        </ul>,
        "langs": <span><TextInlineLink name={"bs_lang_cydonian"} text={"Cydonian"} />, <TextInlineLink
            name={"bs_lang_earth"} text={"Earthlin"} /></span>,
        "text":
            <>
                <h3 className="title-bg">Brief</h3>
                <p className="card-text text-justify">
                    <span className="strong">Cydonians</span> is the collective name for the inhabitants of the
                    Cydonian territories in the Brianne Cluster. Initially it was only used to refer to the settlers on
                    Cydonia, but over time, as the Commonwealth and later the Priority were formed, the name began to be
                    applied to all allies.
                </p>
                <h4 className="title-bg">Cydonian State of Mind</h4>
                <p className="card-text text-justify">
                    <span className="strong">Cydonians</span> are pragmatic visionaries. While they dream of a better world and pursue lofty goals, they ground their aspirations in reason, data, and the lessons of history. They firmly believe that success is not guaranteed by good intentions alone but by talent, effort, and verified results.
                </p>
                <p className="card-text text-justify">
                    This mindset breeds an intriguing mix of optimism and skepticism. <span className="strong">Cydonians</span> can be deeply hopeful about the future, yet distrustful of simplistic solutions and hollow rhetoric. For them, progress comes not from individual heroics but from constant refinement of ideas—an endless pursuit of “what works better.”
                </p>
                <p className="card-text text-justify">
                    While outwardly they seem calm and composed, <span className="strong">Cydonians</span> have a competitive streak woven into the fabric of their lives. Everything from government decisions to personal achievements is measured and ranked. Success is celebrated, and failure—while not shamed—is quickly discarded. They have little tolerance for mediocrity, yet they remain open-minded, always on the lookout for the next breakthrough, no matter where it comes from.
                </p>
                <h4 className="title-bg">Culture: Subtle Complexity and Cosmopolitanism</h4>
                <p className="card-text text-justify">
                    Cydonian culture is a mosaic of influences. From the ancient Greek origins of their names to the subtle borrowings from the Free Worlds and neighboring civilizations, <span className="strong">Cydonians</span> take pride in adapting foreign elements without losing their identity. They do not obsess over tradition but instead cultivate a sense of continuity through ideas and practices that have stood the test of time. This openness has allowed them to integrate hundreds of different peoples, including <span className="strong"><TextInlineLink name={"bs_planet_cyrenaic"} text={"Cyrenians"} /></span>, <span className="strong"><TextInlineLink name={"bs_planet_bassian"} text={"Bassians"} /></span>, <span className="strong"><TextInlineLink name={"bs_planet_cassini"} text={"Cassians"} /></span> and <span className="strong"><TextInlineLink name={"bs_planet_hela"} text={"Hellesians"} /></span>, into a shared cultural identity that still honors local diversity.
                </p>
                <p className="card-text text-justify">
                    Art, science, and philosophy flourish side by side. <span className="strong">Cydonians</span> are deeply engaged in the arts—music, poetry, and theater are treated as essential parts of life—but they despise flamboyant or wasteful displays. Instead, beauty is found in elegance, precision, and function. A Cydonian sculpture, for example, is less likely to be a grand monument and more likely to be a public fountain designed to harmonize with its surroundings.
                </p>
                <p className="card-text text-justify">
                    Interpersonal relationships reflect this subtle complexity. <span className="strong">Cydonians</span> are polite and respectful, but rarely emotionally expressive in public. They value personal space and intellectual conversation over emotional outbursts. Trust is earned gradually and can be lost just as easily if one’s actions do not align with their words. Yet once a friendship or alliance is forged, it is solid and enduring.
                </p>
                <h4 className="title-bg">Mindset: Meritocracy and Competition</h4>
                <p className="card-text text-justify">
                    <span className="strong">Cydonians</span> are driven by the idea that society must be led by the most capable individuals, not through inherited privilege or ideology, but through demonstrable achievements. Their highly competitive society ensures that only those who prove their worth in practice rise to positions of power. However, this competition is framed not as cutthroat rivalry but as a constructive process where the most effective solutions prevail.
                </p>
                <p className="card-text text-justify">
                    The tension between parliament and the monarch reflects this belief in competition. Conflict is not only accepted but encouraged, as <span className="strong">Cydonians</span> see it as a necessary part of progress. The Trust System, a powerful AI overseeing governance, ensures transparency and prevents corruption by continuously evaluating the effectiveness of officials, from local administrators to the monarch. Leaders are expected to work with relentless focus, balancing personal ambition with public accountability.
                </p>
                <p className="card-text text-justify">
                    The Cydonian mindset encourages humility in the face of data. Public figures are expected to be flexible and change their minds when new information emerges, and the people take pride in this adaptability. Dogmatism and rigid ideologies have no place in their worldview, as the ultimate goal is not to be right but to find what works best.
                </p>
                <h4 className="title-bg">Harmony with Nature</h4>
                <p className="card-text text-justify">
                    Living among orbital cities and lush planetary surfaces shapes the Cydonian way of life. While they revel in technology, they place high value on harmony with nature. Their homeworlds are rich with vegetation and open spaces, yet they lack the chaos of untamed wilderness. Parks, gardens, and meticulously cultivated forests stretch as far as the eye can see. Even in space, their orbital habitats are green with hydroponic plants and artificial rivers, mimicking the tranquility of a terrestrial environment.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Cydonians</span> cherish the concept of balance—between nature and technology, individual achievement and communal responsibility, freedom and order. They view civilization as a delicate system that requires constant care and adjustment. As such, they see themselves as custodians, not just of their own worlds, but of the future.
                </p>
                <h4 className="title-bg">Identity and Stereotypes</h4>
                <p className="card-text text-justify">
                    Despite their openness and adaptability, <span className="strong">Cydonians</span> have cultivated a certain mystique. Their understated demeanor, emphasis on merit, and complex government structure create an aura of intrigue and, sometimes, envy. Outsiders often view them as cold or aloof, mistakenly assuming that their politeness masks indifference. The nickname “greenfaced” is sometimes used to mock their environmental focus and measured behavior. But the <span className="strong">Cydonians</span> rarely engage with such taunts, preferring to let their accomplishments speak for themselves.
                </p>
                <p className="card-text text-justify">
                    Their cosmopolitan nature has also given rise to stereotypes about their identity. Many <span className="strong">Cydonians</span>, especially those in the Free Worlds, play along with these preconceptions, finding them useful in diplomatic and personal dealings. In some places, simply being known as a Cydonian can open doors. Yet, beneath the surface, each Cydonian knows that identity is more than a label—it is the product of continuous self-improvement and contribution to the greater whole.
                </p>
                <p className="card-text text-justify">
                    In summary, the <span className="strong">Cydonians</span> are a people who live by the principle that everything must evolve, improve, and harmonize. They thrive on competition, yet they temper it with respect and accountability. Their culture is a delicate dance between tradition and innovation, individuality and unity. In the Cydonian view, life is not about chasing perfection—it is about becoming better, together.
                </p>
            </>
    }
}
