import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Bs_science_astromechanics() {
    return {
        "knowledge_area": <span>physics, mechanics</span>,
        "use": <span>movement in space</span>,
        "text": <>
            <h4 className="title-bg">Excerpt from a speech given by the chancellor of the Hellesian Academy of Commercial and Military Navy to the freshmen at 12 <TextInlineLink name={"bs_timeline"} text={"BT"} /></h4>
            <p className="card-text text-justify">
                ...I would like to mention separately such a discipline as <span className="strong">astromechanics</span>. It does not matter what specialty you plan to get: if it is related to space - you will study astromechanics. There can be no exceptions.
            </p>
            <p className="card-text text-justify">
                Of course, you will say, he has a doctorate in celestial sciences - surely he will tell us about this boring mechanics! But no, friends, it's not that simple. Once you leave the outer orbit of your world, the old rules don't work anymore. Intuitive notions about how objects move, how much force you need to apply, and in which direction - it all loses meaning. The pilot, the inset engineer who has never seen a helm, even the sailor on a military frigate, everyone! Everyone needs to know how planets, asteroids, stars, and of course the metal cans we proudly call ships move. Because it all generates debris, affects other objects, and can suddenly appear in your path. Yes, planets can appear suddenly - any pilot who has completed braking a second later than necessary will attest to that.
            </p>
            <p className="card-text text-justify">
                Sooner or later you will find yourself in a situation where it is knowledge of astromechanics that will save your life. Understanding inertia, overload, relativity... will come in handy sooner or later. Because space is not a human environment. We're not even well adapted to planets: what about our eternal back problems, which arise even if you live on Earth. And it would seem that what world would be more suitable for our backs if not the home of our species? Sadly, even there our bodies have problems.
            </p>
            <p className="card-text text-justify">
                And when you get to space: a place without air, gravity, resistance... even if you're a first-rate atmospheric machine pilot, you're still confused. Our intuition, senses and bodies fail us outside the planet. Therefore study astromechanics, try to learn how this black emptiness around stone balls differs from the usual environment, and you will definitely succeed. Even if you choose a different profession later, mechanics will still come in handy. Even after the first semester, you'll have a billion logical puzzles to impress people at parties...
            </p>
        </>
    }
}
